import React from "react";
import {
  AudioType,
  InterestType,
  TooltipDirection,
} from "../../../data/dataMaps";
import { BodyLink } from "../../HundredYearsWar/years/BodyLink";

export const Header1942 = "Japanese victories";

export const DataUrl1942 = "/maps/SupernovaInTheEast/1942/1942.json";

export const Legends1942 = [
  {
    party: "japan",
    label: "Empire of Japan",
  },
  {
    party: "japan-exp",
    label: "Japanese expansion",
  },
  {
    party: "japan-ally",
    label: "Japanese ally",
  },
  {
    party: "us",
    label: "United States",
  },
  {
    party: "uk",
    label: "United Kingdom",
  },
  {
    party: "guinea",
    label: "Australia",
  },
];

export const Highlights1942 = {};

export const Highlights1942Dec = {
  kota: {
    coords: [102.25, 6.143333],
    type: InterestType.AREA,
    id: "kota",
    link: "https://en.wikipedia.org/wiki/Japanese_invasion_of_Malaya",
    info: "2",
    direction: TooltipDirection.ON_BOTTOM,
    mapAreas: [],
    mapIds: ["kota"],
  },
  thailand: {
    coords: [102.81035005057912, 13.789042171709355],
    type: InterestType.AREA,
    id: "thailand",
    link: "https://en.wikipedia.org/wiki/Japanese_invasion_of_Thailand",
    info: "3",
    direction: TooltipDirection.ON_TOP,
    mapAreas: [],
    mapIds: ["thailand"],
  },
  philippines: {
    coords: [121.21365853923382, 17.779948616341294],
    type: InterestType.AREA,
    id: "philippines",
    link:
      "https://en.wikipedia.org/wiki/Philippines_campaign_(1941%E2%80%9342)",
    info: "4",
    direction: TooltipDirection.ON_LEFT,
    mapAreas: [],
    mapIds: ["philippines"],
  },
  guam: {
    coords: [144.7756871703471, 13.4600734917315],
    type: InterestType.AREA,
    id: "guam",
    link: "https://en.wikipedia.org/wiki/Japanese_occupation_of_Guam",
    info: "5",
    direction: TooltipDirection.ON_LEFT,
    mapAreas: [],
    mapIds: ["guam"],
  },
  burma_jpn: {
    coords: [98.55, 9.983333],
    type: InterestType.AREA,
    id: "burma_jpn",
    link: "https://en.wikipedia.org/wiki/Japanese_invasion_of_Burma",
    info: "6",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: [],
    mapIds: ["burma_jpn"],
  },
  miri: {
    coords: [113.986111, 4.3925],
    type: InterestType.AREA,
    id: "miri",
    link: "https://en.wikipedia.org/wiki/Japanese_occupation_of_British_Borneo",
    info: "7",
    direction: TooltipDirection.ON_BOTTOM,
    mapAreas: [],
    mapIds: ["miri"],
  },
  kong: {
    coords: [114.188, 22.267],
    type: InterestType.POINT,
    id: "kong",
    link: "https://en.wikipedia.org/wiki/Battle_of_Hong_Kong",
    info: "8",
    direction: TooltipDirection.ON_LEFT,
    mapAreas: [],
    mapIds: ["kong"],
  },
  all: {
    id: "all",
    type: InterestType.ALL,
    mapAreas: [],
    mapIds: [
      "kota",
      "thailand",
      "philippines",
      "guam",
      "burma_jpn",
      "miri",
      "kong",
    ],
  },
};

export const Highlights1942Jan = {
  bataan: {
    coords: [120.33693898366384, 14.693866672715249],
    type: InterestType.AREA,
    id: "bataan",
    link: "https://en.wikipedia.org/wiki/Battle_of_Bataan",
    info: "10",
    direction: TooltipDirection.ON_LEFT,
    mapAreas: [],
    mapIds: ["bataan"],
  },
  celebes: {
    coords: [124.857222, 1.163333],
    type: InterestType.AREA,
    id: "celebes",
    link: "https://en.wikipedia.org/wiki/Battle_of_Manado",
    info: "11",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: [],
    mapIds: ["celebes"],
  },
  rabaul: {
    coords: [152.183333, -4.2],
    type: InterestType.AREA,
    id: "rabaul",
    link: "https://en.wikipedia.org/wiki/New_Guinea_campaign",
    info: "12",
    direction: TooltipDirection.ON_LEFT,
    mapAreas: [],
    mapIds: ["rabaul"],
  },
  all: {
    id: "all",
    type: InterestType.ALL,
    mapAreas: [],
    mapIds: ["bataan", "celebes", "rabaul"],
  },
};

export const Highlights1942Feb = {
  sumatra: {
    coords: [101.74155657888457, -0.4288732643420906],
    type: InterestType.AREA,
    id: "sumatra",
    link: "https://en.wikipedia.org/wiki/Invasion_of_Sumatra",
    info: "13",
    direction: TooltipDirection.ON_BOTTOM,
    mapAreas: [],
    mapIds: ["sumatra"],
  },
  singapore: {
    coords: [103.86566749359604, 1.3652788651236938],
    type: InterestType.POINT,
    id: "singapore",
    link: "https://en.wikipedia.org/wiki/Battle_of_Singapore",
    info: "14",
    direction: TooltipDirection.ON_TOP,
    mapAreas: [],
    mapIds: ["singapore"],
  },
  java: {
    coords: [110.56639566301831, -7.533780447131589],
    type: InterestType.AREA,
    id: "java",
    link: "https://en.wikipedia.org/wiki/Battle_of_Java_(1942)",
    info: "15",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: [],
    mapIds: ["java"],
  },
  all: {
    id: "all",
    type: InterestType.ALL,
    mapAreas: [],
    mapIds: ["sumatra", "singapore", "java"],
  },
};

export const Highlights1942March = {
  salamaua: {
    coords: [147.0360233528939, -7.059187210832632],
    type: InterestType.AREA,
    id: "salamaua",
    link: "https://en.wikipedia.org/wiki/Invasion_of_Salamaua%E2%80%93Lae",
    info: "16",
    direction: TooltipDirection.ON_LEFT,
    mapAreas: [],
    mapIds: ["salamaua"],
  },
  corregidor: {
    coords: [120.573056, 14.385556],
    type: InterestType.AREA,
    id: "corregidor",
    link:
      "https://en.wikipedia.org/wiki/Douglas_MacArthur%27s_escape_from_the_Philippines",
    info: "17",
    direction: TooltipDirection.ON_LEFT,
    mapAreas: [],
    mapIds: ["corregidor"],
  },
  andaman: {
    coords: [93.46127164485235, 12.437565572834467],
    type: InterestType.AREA,
    id: "andaman",
    link:
      "https://en.wikipedia.org/wiki/Japanese_occupation_of_the_Andaman_Islands",
    info: "18",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: [],
    mapIds: ["andaman"],
  },
  all: {
    id: "all",
    type: InterestType.ALL,
    mapAreas: [],
    mapIds: ["salamaua", "corregidor", "andaman"],
  },
};

export const Highlights1942April = {
  bataan: {
    coords: [120.4319, 14.685],
    type: InterestType.AREA,
    id: "bataan",
    link: "https://en.wikipedia.org/wiki/Battle_of_Bataan",
    info: "19",
    direction: TooltipDirection.ON_TOP,
    mapAreas: [],
    mapIds: ["bataan"],
  },
  deathmarch: {
    coords: [120.48, 14.43],
    type: InterestType.AREA,
    id: "deathmarch",
    link: "https://en.wikipedia.org/wiki/Bataan_Death_March",
    info: "20",
    direction: TooltipDirection.ON_BOTTOM,
    mapAreas: [],
    mapIds: ["deathmarch"],
  },
  doolittle: {
    coords: [139.76302845066965, 35.68865781509915],
    type: InterestType.AREA,
    id: "doolittle",
    link: "https://en.wikipedia.org/wiki/Doolittle_Raid",
    info: "21",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: [],
    mapIds: ["doolittle"],
  },
  all: {
    id: "all",
    type: InterestType.ALL,
    mapAreas: [],
    mapIds: ["bataan", "deathmarch", "doolittle"],
  },
};

export const Highlights1942May = {
  tulagi: {
    coords: [160.1711463740934, -9.095089872267659],
    type: InterestType.AREA,
    id: "tulagi",
    link: "https://en.wikipedia.org/wiki/Invasion_of_Tulagi_(May_1942)",
    info: "22",
    direction: TooltipDirection.ON_TOP,
    mapAreas: [],
    mapIds: ["tulagi"],
  },
  coral: {
    coords: [155.89979064238756, -14.884914642018393],
    // coords: [166.59545534022286, 19.304333705015775],
    type: InterestType.AREA,
    id: "coral",
    link: "https://en.wikipedia.org/wiki/Battle_of_the_Coral_Sea",
    info: "23",
    direction: TooltipDirection.ON_LEFT,
    mapAreas: [],
    mapIds: ["coral"],
  },
  all: {
    id: "all",
    type: InterestType.ALL,
    mapAreas: [],
    mapIds: ["tulagi", "coral"],
  },
};

export const Additional1942 = [];

export const Audio1942 = [
  {
    type: AudioType.SPOTIFY,
    src:
      "https://open.spotify.com/embed-podcast/episode/0EfH5ab5tffoTI8GIUyWSa",
    title: "Dan Carlin, Supernova in the East III",
  },
  {
    type: AudioType.SPOTIFY,
    src:
      "https://open.spotify.com/embed-podcast/episode/26vJ74LfG4fpeYJOsp3nKT",
    title: "Dan Carlin, Supernova in the East IV",
  },
];

export const Sources1942 = [
  {
    link: "https://en.wikipedia.org/wiki/Attack_on_Pearl_Harbor",
    label: "Wikipedia, Attack on Pearl Harbor",
  },
  {
    link:
      "https://en.wikipedia.org/wiki/Sinking_of_Prince_of_Wales_and_Repulse",
    label: "Wikipedia, Sinking of Prince of Wales and Repulse",
  },
  {
    link: "https://en.wikipedia.org/wiki/Japanese_invasion_of_Thailand",
    label: "Wikipedia, Invasion of Thailand",
  },
  {
    link: "https://www.youtube.com/watch?v=ivQ9O-yB0dw",
    label: "The Pacific War, Armchair historian (video)",
  },
  {
    link: "https://www.youtube.com/watch?v=tS-BWXfFkVY",
    label: "World War II - Pacific War (video)",
  },
  {
    link: "https://www.youtube.com/watch?v=2PAJ9m4uGRY&t=28s",
    label: "History of the Japanese Empire (video)",
  },
  {
    link: "https://www.historyplace.com/unitedstates/pacificwar/timeline.htm",
    label: "History Place (timeline)",
  },
  {
    link: "http://www.wtj.com/articles/pacific_summary/timeline.htm",
    label: "War Times Journal (timeline)",
  },
  {
    link:
      "https://en.wikipedia.org/wiki/Dutch_East_Indies_campaign#/media/File:Pacific_War_-_Dutch_East_Indies_1941-42_-_Map.jpg",
    label: "Wikipedia, Dutch East Indies campaign (map)",
  },
];

interface Props {
  onHover?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  offHover?: () => void;
}

export const Year1942: React.FunctionComponent<Props> = () => (
  <div className="supernova-1942-body">
    <strong>Japanese advantages</strong>
    <ol>
      <li>
        <span className="number">1</span>
        <i>Moral</i> - there have never been a modern army with higher moral.
        They would not break, they would not surrender, regardless the
        situation.
      </li>
      <li>
        <span className="number">2</span>
        <i>Air supremacy</i> - Japanese pilots were well trained and the{" "}
        <BodyLink link="https://en.wikipedia.org/wiki/Mitsubishi_A6M_Zero">
          A6M Zero fighter
        </BodyLink>{" "}
        plane performed excellently. Japan, however, was unable to upgrade their
        planes and kept{" "}
        <BodyLink link="https://www.airforcemag.com/article/1209bullet/">
          experienced pilots in combat
        </BodyLink>{" "}
        instead of using them to train other pilots. Japanese prowess therefore
        was much reduced later on in the war.
      </li>
      <li>
        <span className="number">3</span>
        <i>A veteran fighting force</i> - Japan had been fighting in China for
        years while the allied forces in this theatre were mostly unexperienced.
      </li>
      <li>
        <span className="number">4</span>
        <i>The element of suprise</i>
      </li>
    </ol>

    <strong>Japanese disadvantages</strong>
    <ol>
      <li>
        <span className="number">1</span>
        <i>Production</i> - Even though Japan had gone through a rapid
        industrialization they could never match the output of the United
        States. It seems improbable that Japan could ever win a protracted war.
      </li>
      <li>
        <span className="number">2</span>
        <i>Technology</i> - With a few early exceptions (the A6M Zero fighter,{" "}
        <BodyLink link="https://en.wikipedia.org/wiki/Type_93_torpedo">
          the Long Lance Torpedo
        </BodyLink>
        , etc), Japan fell behind the Allies regarding sophisticated equipment.
        The United States was able to{" "}
        <BodyLink link="https://en.wikipedia.org/wiki/Magic_(cryptography)">
          decipher Japanese messages
        </BodyLink>
        .
      </li>
      <li>
        <span className="number">3</span>
        <i>Cooperation</i> - The rivalry between the army and the navy of Japan
        was intense,{" "}
        <BodyLink link="https://www.quora.com/How-bad-was-the-inter-service-rivalry-between-the-Imperial-Japanese-Army-and-the-Imperial-Japanese-Navy-in-World-War-2">
          to the point of being dysfunctional
        </BodyLink>
        . Also, unlike the Allies, corporation among the axis powers was rare.
      </li>
    </ol>
  </div>
);
