import React from "react";
import cx from "classnames";
import "./LevelTag.scss";
import { Level } from "../../components/Quiz/Question";

interface Props {
  includeText?: boolean;
  level: Level;
}

export const LevelTag: React.FunctionComponent<Props> = ({
  includeText = true,
  level,
}) => {
  const levelText = level.toLocaleLowerCase();
  return (
    <div
      className={cx("level-tag", `level-${levelText}`, {
        "with-text": includeText,
      })}
    >
      {level === Level.EASY && <div>🙂</div>}
      {level === Level.MEDIUM && <div>🧐</div>}
      {level === Level.HARD && <div>🤯</div>}
      <div className="level-tag-text">{includeText ? levelText : null}</div>
    </div>
  );

  // return (
  //   <div
  //     className={cx("level-tag", `level-${levelText}`, {
  //       "with-text": includeText,
  //     })}
  //   >
  //     <svg viewBox="0 0 774 246">
  //       <path
  //         d="M129 10 L158 96 L248,96 L176,150 L202,236 L129,185 L56,236 L82,150 L10,96 L100,96"
  //         className="easy"
  //       />
  //       <path
  //         transform="translate(258, 0)"
  //         d="M129 10 L158 96 L248,96 L176,150 L202,236 L129,185 L56,236 L82,150 L10,96 L100,96"
  //         className="medium"
  //       />
  //       <path
  //         transform="translate(516, 0)"
  //         d="M129 10 L158 96 L248,96 L176,150 L202,236 L129,185 L56,236 L82,150 L10,96 L100,96"
  //         className="hard"
  //       />
  //     </svg>
  //     <div className="level-tag-text">{includeText ? levelText : null}</div>
  //   </div>
  // );
};
