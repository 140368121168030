import React, { useState, ReactNode, useEffect, useCallback } from "react";
import { AnimateHeight } from "../AnimateHeight";
import { Pending } from "./Pending";
import { Error } from "./Error";
import "./Lazy.scss";

interface Props {
  load: any;
  name: string;
}

export const Lazy: React.FC<Props> = ({ load, name }) => {
  const [elem, setElem] = useState<ReactNode>(window.maps.loaded[name]);

  const initialLoad = useCallback(() => {
    load()
      .then((data: any) => {
        const C = data[name];
        window.maps.loaded[name] = <C />;
        setElem(<C />);
      })
      .catch((err: any) => {
        console.log("err", err);
        const retry = () => {
          setElem(<Pending />);
          setTimeout(() => {
            initialLoad();
          }, 1800);
        };
        setElem(<Error retry={retry} />);
      });
  }, [load, name]);

  useEffect(() => {
    if (window.maps.loaded[name]) {
      setElem(window.maps.loaded[name]);
      return;
    }

    initialLoad();
  }, [initialLoad, name]);

  return (
    <AnimateHeight name={elem ? name : "pending"}>
      {elem || <Pending />}
    </AnimateHeight>
  );
};
