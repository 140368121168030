import { ReactNode } from "react";
import { Option } from "../Interactions/Choice/Choice";

export enum Level {
  EASY = "EASY",
  MEDIUM = "MEDIUM",
  HARD = "HARD",
}

export enum Era {
  MODERN = "MODERN",
  MEDIEVIAL = "MEDIEVIAL",
  ANCIENT = "ANCIENT",
}

export enum Geography {
  EUROPE = "EUROPE",
}

export interface Filter {
  levels: Level[];
  eras: Era[];
  geographies: Geography[];
}

export enum AlternativeStatus {
  CORRECT,
  INCORRECT,
}

export enum QuestionType {
  RADIO,
  CHECKBOX,
}

export interface QuestionInterface {
  header?: string;
  type: QuestionType;
  question: string;
  options: Option[];
  questionAnswers: string[];
  level: Level;
  geography: Geography;
  era: Era;
  explanation: string;
}

export const NO_FILTER: Filter = {
  levels: [Level.EASY, Level.MEDIUM, Level.HARD],
  geographies: [Geography.EUROPE],
  eras: [Era.ANCIENT, Era.MEDIEVIAL, Era.MODERN],
};

export class Question {
  header: string | null = null;
  question: string;
  type: QuestionType;
  options: Option[];
  questionAnswers: string[];
  hasBeenAnswered: boolean = false;
  customerAnswers: string[] = [];
  level: Level;
  geography: Geography;
  era: Era;
  explanation: string;

  constructor(question: QuestionInterface) {
    this.type = question.type;
    this.question = question.question;
    this.options = question.options;
    this.questionAnswers = question.questionAnswers;
    this.options.sort(() => (Math.random() > 0.5 ? 1 : -1));
    this.level = question.level;
    this.geography = question.geography;
    this.era = question.era;
    this.explanation = question.explanation;
    this.header = question.header ?? null;
  }

  isAnswered(): boolean {
    return this.hasBeenAnswered;
  }

  getCorrectAnswers(): (string | ReactNode)[] {
    const data: (string | ReactNode)[] = [];
    this.questionAnswers.forEach((answer) => {
      const item = this.options.find((item) => item.value === answer);
      if (item) {
        data.push(item.label);
      }
    });
    return data;
  }

  getCustomerAnswers(): (string | ReactNode)[] {
    const data: (string | ReactNode)[] = [];
    this.customerAnswers.forEach((answer) => {
      const item = this.options.find((item) => item.value === answer);
      if (item) {
        data.push(item.label);
      }
    });
    return data;
  }

  isCorrect(): boolean {
    if (this.questionAnswers.length !== this.customerAnswers.length) {
      return false;
    }

    let correct = true;
    this.questionAnswers.forEach((answer) => {
      if (this.customerAnswers.indexOf(answer) === -1) {
        correct = false;
      }
    });

    return correct;
  }

  isAlternativeChecked(id: string): boolean {
    return !!this.customerAnswers.find((item) => item === id);
  }

  setAnswer(id: string): void {
    this.hasBeenAnswered = true;

    if (this.type === QuestionType.CHECKBOX) {
      if (this.isAlternativeChecked(id)) {
        this.customerAnswers = this.customerAnswers.filter(
          (item) => item !== id
        );
      } else {
        this.customerAnswers.push(id);
      }
    } else {
      this.customerAnswers = [id];
    }
  }
}
