import React, { useCallback, useEffect, useRef, useState } from "react";
import cx from "classnames";
import "./Slider.scss";
import { useWidth } from "../../../hooks/useWidth";

interface Props {
  className?: string;
  min: number;
  max: number;
  value?: number;
  onChange: (value: number) => void;
}

const HANDLE_WIDTH = 34;

export const Slider: React.FunctionComponent<Props> = ({
  className,
  min,
  max,
  value,
  onChange,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [boxWidth, setBoxWidth] = useState<number>(0);
  const width = useWidth();

  const changeFunc = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(parseInt(event.target.value, 10));
    },
    [onChange]
  );

  useEffect(() => {
    const calculatedWidth =
      inputRef.current?.getBoundingClientRect().width ?? 0;
    setBoxWidth(calculatedWidth);
  }, [width]);

  const range = max - min;
  const trimmedValue = (value || min) - min;
  const offset = trimmedValue / range;
  const trackWidth = boxWidth - HANDLE_WIDTH;

  const style = {
    transform: `translateX(${offset * trackWidth}px)`,
  };

  return (
    <label className={cx("slider", className)}>
      <input
        type="range"
        min={min}
        max={max}
        value={value || min}
        onChange={changeFunc}
        ref={inputRef}
      />

      <div className="slider-track-wrapper">
        <div className="slider-track">
          <div className="slider-track-inner" style={style} />
        </div>
        <div className="relative">
          <div className="slider-handle" style={style}>
            <div className="slider-handle-focus" />
          </div>
        </div>
      </div>
    </label>
  );
};
