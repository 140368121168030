import React, { useCallback, useEffect, useRef, useState } from "react";
import cx from "classnames";
import { useHistory } from "react-router-dom";
import { Button } from "../../components/Buttons/Button";
import { Batch } from "../../components/Quiz/Batch";
import { QuizQuestion } from "./QuizQuestion";
import { ROUTE } from "./Quiz";
import "./QuizBatch.scss";

interface Props {
  batch?: Batch;
  index: number;
  isLast: boolean;
  isFirst: boolean;
  numberOfQuestions: number;
}

export const QuizBatch: React.FunctionComponent<Props> = ({
  batch,
  isLast,
  isFirst,
  index,
  numberOfQuestions,
}) => {
  const history = useHistory();

  const [error, setError] = useState<boolean>(false);
  const questions = batch?.getQuestions() ?? [];
  const isMounted = useRef<boolean>(true);

  const onNext = useCallback(
    (isDone?: boolean) => {
      if (batch?.hasUnansweredQuestions()) {
        setError(true);
        setTimeout(() => {
          if (isMounted.current) {
            setError(false);
          }
        }, 3000);
        return;
      }

      if (isDone) {
        history.push(`${ROUTE}/done`);
      } else {
        history.push(`${ROUTE}/${index + 2}`);
      }
    },
    [index, history, batch]
  );

  const onDone = useCallback(() => {
    onNext(true);
  }, [onNext]);

  const onPrev = useCallback(() => {
    history.push(`${ROUTE}/${index}`);
  }, [index, history]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <div
      className={cx("quiz-batch", {
        "has-error": error,
      })}
    >
      <div className="quiz-batch-top">
        <span>{questions[0]?.header}</span>
        <span>
          {index + 1} of {numberOfQuestions}
        </span>
      </div>

      <div>
        {questions.map((question) => (
          <QuizQuestion key={question.question} question={question} />
        ))}
      </div>

      <p className="unanswered-error">
        Seems like there are unanswered questions?
      </p>

      {isLast && (
        <Button block onClick={onDone}>
          Done
        </Button>
      )}
      {!isLast && (
        <Button block onClick={onNext}>
          <span data-id="ok">Next</span>
        </Button>
      )}
      {!isFirst && (
        <Button block onClick={onPrev} className="ghost">
          Previous
        </Button>
      )}
    </div>
  );
};
