import React, { useCallback, useMemo } from "react";
import { useSetRecoilState } from "recoil";
import {
  PointOfInterest,
  SimpleStory,
  Story,
  YearMap,
} from "../../data/dataMaps";
import { mapActiveState } from "../../state/mapState";
import "./Body.scss";

interface Props {
  year: string;
  story: Story | SimpleStory;
  yearMap: YearMap;
}

export const Body: React.FunctionComponent<Props> = ({
  year,
  story,
  yearMap,
}) => {
  const setActive = useSetRecoilState(mapActiveState);

  const onHover = useCallback(
    (ev) => {
      const { id } = ev.currentTarget.dataset;
      const mq = window.matchMedia("(pointer: fine)");
      if (id && mq.matches) {
        const pointOfInterest = story.years[year].highlights[id];
        setActive(pointOfInterest as PointOfInterest);
      }
    },
    [setActive, story, year]
  );

  const offHover = useCallback(() => {
    const mq = window.matchMedia("(pointer: fine)");
    if (mq.matches) {
      setActive(null);
    }
  }, [setActive]);

  const elem = useMemo(() => {
    return React.cloneElement(yearMap[year], {
      onHover,
      offHover,
    });
  }, [year, onHover, offHover, yearMap]);

  return <div className="body">{elem}</div>;
};
