import React from "react";
import { TimelineListItem } from "../TimelineListItem";
import { Anchor } from "./Anchor";

interface Props {
  onHover?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  offHover?: () => void;
}

export const Year1942TimelineDec: React.FunctionComponent<Props> = ({
  onHover = () => {},
  offHover = () => {},
}) => {
  return (
    <div className="timeline timeline-december">
      <div className="center">1941 December</div>

      <div className="relative">
        <div
          className="timeline-date"
          style={{
            top: "0px",
          }}
        >
          <strong>1941</strong>
        </div>

        <div
          className="timeline-date"
          style={{
            top: "57px",
          }}
        >
          Dec. 07
        </div>

        <div
          className="timeline-date"
          style={{
            top: "127px",
          }}
        >
          Dec. 14
        </div>

        <div
          className="timeline-date"
          style={{
            top: "197px",
          }}
        >
          Dec. 21
        </div>

        <div
          className="timeline-date"
          style={{
            top: "267px",
          }}
        >
          Dec. 28
        </div>
      </div>
      <div className="year-1942-timeline-wrapper">
        <svg viewBox="0 0 90 330">
          <linearGradient
            id="vertical-timeline"
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            <stop offset="0%" stopColor="#fafafa" stopOpacity="0" />
            <stop offset="45%" stopColor="#fafafa" stopOpacity="1" />
            <stop offset="55%" stopColor="#fafafa" stopOpacity="1" />
            <stop offset="100%" stopColor="#fafafa" stopOpacity="0" />
          </linearGradient>

          <line x1="54.5" x2="54.5" y1="-10" y2="330" stroke="#999" />

          {/** Pearl Harbor - 7 dec */}
          <Anchor timelineY={70} anchorY={14} />

          {/** Invasion of British Malaya - 8 dec */}
          <Anchor timelineY={80} anchorY={40} />

          {/** Invasion of Thailand - 8 dec */}
          <Anchor timelineY={80} anchorY={66} />

          {/** Philippines campaign - 10 dec */}
          <Anchor timelineY={100} anchorY={92} />

          {/** Guam - 10 dec  */}
          <Anchor timelineY={100} anchorY={118} />

          {/** Burma campaign - 14 dec */}
          <Anchor timelineY={140} anchorY={144} />

          {/** Borneo campaign - 160 dec */}
          <Anchor timelineY={160} anchorY={170} />

          {/** Battle of Hong Kong - 18 dec */}
          <Anchor timelineY={180} anchorY={196} />

          {/** Wake Island - 23 dec */}
          <Anchor timelineY={230} anchorY={222} />

          <rect x="53" width="3" y="-20" height="40" />
          <rect x="53" width="3" y="310" height="40" />
        </svg>
        <div className="timeline-links">
          <ul>
            <TimelineListItem
              name="Attack on Pearl Harbor"
              date="1941 Dec. 07"
              number="1"
              link="https://en.wikipedia.org/wiki/Attack_on_Pearl_Harbor"
            />
            <TimelineListItem
              name="Invasion of British Malaya"
              id="kota"
              onHover={onHover}
              offHover={offHover}
              date="Dec. 08"
              number="2"
              link="https://en.wikipedia.org/wiki/Japanese_invasion_of_Malaya"
            />
            <TimelineListItem
              name="Invasion of Thailand"
              id="thailand"
              onHover={onHover}
              offHover={offHover}
              date="Dec. 08"
              number="3"
              link="https://en.wikipedia.org/wiki/Japanese_invasion_of_Thailand"
            />
            <TimelineListItem
              name="Philippines campaign"
              id="philippines"
              onHover={onHover}
              offHover={offHover}
              date="Dec. 10"
              number="4"
              link="https://en.wikipedia.org/wiki/Philippines_campaign_(1941%E2%80%9342)"
            />
            <TimelineListItem
              name="Occupation of Guam"
              id="guam"
              onHover={onHover}
              offHover={offHover}
              date="Dec. 10"
              number="5"
              link="https://en.wikipedia.org/wiki/Japanese_occupation_of_Guam"
            />
            <TimelineListItem
              name="Burma campaign"
              id="burma_jpn"
              onHover={onHover}
              offHover={offHover}
              date="Dec. 14"
              number="6"
              link="https://en.wikipedia.org/wiki/Japanese_invasion_of_Burma"
            />

            <TimelineListItem
              name="Occupation of British Borneo"
              id="miri"
              onHover={onHover}
              offHover={offHover}
              date="Dec. 16"
              number="7"
              link="https://en.wikipedia.org/wiki/Japanese_occupation_of_British_Borneo"
            />
            <TimelineListItem
              name="Battle of Hong Kong"
              id="kong"
              onHover={onHover}
              offHover={offHover}
              date="Dec. 18"
              number="8"
              link="https://en.wikipedia.org/wiki/Battle_of_Hong_Kong"
            />

            <TimelineListItem
              name="Japan takes Wake Island"
              date="1941 Dec. 23"
              number="9"
              link="https://en.wikipedia.org/wiki/Battle_of_Wake_Island"
            />
          </ul>
        </div>
      </div>
    </div>
  );
};
