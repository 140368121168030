import { Filter, Level, Question } from "./Question";

interface BatchInterface {
  questions: Question[];
  filter: Filter;
}

export class Batch {
  questions: Question[];

  constructor(questions: BatchInterface) {
    if (questions.questions.length !== 3) {
      throw new Error("Question is missing alternative");
    }

    if (questions.questions[0].level !== Level.EASY) {
      throw new Error("Question has incorrect level");
    }

    if (questions.questions[1].level !== Level.MEDIUM) {
      throw new Error("Question has incorrect level");
    }

    if (questions.questions[2].level !== Level.HARD) {
      throw new Error("Question has incorrect level");
    }

    this.questions = questions.questions.filter((question) => {
      if (!questions.filter.levels.includes(question.level)) {
        return false;
      }

      if (!questions.filter.eras.includes(question.era)) {
        return false;
      }

      if (!questions.filter.geographies.includes(question.geography)) {
        return false;
      }

      return true;
    });
  }

  getQuestions(): Question[] {
    return this.questions;
  }

  getIncorrectQuestions(): Question[] {
    return this.questions.filter((question) => !question.isCorrect());
  }

  hasUnansweredQuestions(): boolean {
    return !this.questions.every((question) => question.isAnswered());
  }

  getNumberOfQuestions(): number {
    return this.questions.length;
  }

  getNumberOfCorrect(): number {
    let correct = 0;
    this.questions.forEach((question) => {
      if (question.isCorrect()) {
        correct++;
      }
    });

    return correct;
  }

  isCorrect(): boolean {
    return this.getNumberOfCorrect() === 3;
  }
}
