import React from "react";
import {
  AudioType,
  InterestType,
  Party,
  TooltipDirection,
} from "../../../data/dataMaps";
import { EnglishReigns } from "../EnglishReigns";
import { FrenchReigns } from "../FrenchReigns";
import { BodyLink } from "./BodyLink";
import { KingLink } from "../../../components/Links/KingLink";
import { MapLink } from "./MapLink";

export const Header1429 = "Henry V";

export const DataUrl1429 = "/maps/HundredYearsWar/1429.json";

export const Legends1429 = [
  {
    party: Party.ENGLAND,
    label: "Kingdom of England",
  },
  {
    party: Party.ENGLAND_EXP,
    label: "English expansion",
  },
  {
    party: Party.FRANCE,
    label: "Kingdom of France",
  },
  {
    party: Party.SCOTLAND,
    label: "Kingdom of Scotland",
    tabletOnly: true,
  },
  {
    party: Party.BURGUNDY_INDEPENDENT,
    label: "Burgundy at war with France",
  },
];

export const Highlights1429 = {
  rouen: {
    coords: [1.0918290634134329, 49.44189753347975],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Siege_of_Rouen_(1418%E2%80%9319)",
    id: "rouen",
    info: "1419 - Rouen",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: ["uk_1429"],
    mapIds: ["rouen"],
  },
  harfleur: {
    coords: [0.1297909467426614, 49.49494748280351],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Siege_of_Harfleur",
    id: "harfleur",
    info: "1415 - Harfleur",
    direction: TooltipDirection.ON_LEFT,
    mapAreas: ["uk_1429"],
    mapIds: ["harfleur"],
  },
  agincourt: {
    coords: [2.1420103227644276, 50.463392451166634],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Battle_of_Agincourt",
    id: "agincourt",
    info: "1415 - Agincourt",
    direction: TooltipDirection.ON_TOP,
    mapAreas: ["low_countries"],
    mapIds: ["agincourt"],
  },
  troyes: {
    coords: [4.073888446379149, 48.296626729546304],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Treaty_of_Troyes",
    id: "troyes",
    info: "1420 - Troyes",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: ["uk_1429"],
    mapIds: ["troyes"],
  },
  paris: {
    coords: [2.3503212083256217, 48.8554653687046],
    type: InterestType.POINT,
    id: "paris",
    info: "Paris",
    direction: TooltipDirection.ON_BOTTOM,
    mapAreas: ["uk_1429"],
    mapIds: ["paris"],
  },
  all: {
    id: "all",
    type: InterestType.ALL,
    mapIds: ["paris", "troyes", "agincourt", "harfleur", "rouen"],
    mapAreas: [
      "uk_1429",
      "low_countries",
      "gasgony",
      "duchy_of_burgundy",
      "county_of_burgundy",
      "rethel",
      "calais",
    ],
  },
};

export const Audio1429 = [
  {
    type: AudioType.SPOTIFY,
    src: "https://open.spotify.com/embed/playlist/3RXu0cykHTAnwwBlFSrTiM",
    title: "History of Europe - Hundred Years' War, 1413 - 1420",
  },
];

export const Sources1429 = [
  {
    link: "https://www.youtube.com/watch?v=mUgMlZU9TSw",
    label: "Extra history (video), part 5",
  },
  {
    link:
      "https://pages.uoregon.edu/mapplace/EU/EU20_France/Maps/EU20_71HundredYrsWar.jpg",
    label: "Univ. of Oregon, France 1429 (map)",
  },
  {
    link:
      "https://pages.uoregon.edu/mapplace/EU/EU20_France/Maps/EU20_67HundredYrsWar.jpg",
    label: "Univ. of Oregon, France 1429 (map)",
  },
  {
    link:
      "https://en.wikipedia.org/wiki/Hundred_Years%27_War_(1415%E2%80%931453)#/media/File:Treaty_of_Troyes.svg",
    label: "Wikipedia, Treaty of Troyes 1420 (map)",
  },
  {
    link:
      "https://www.manchesterhive.com/view/9781526112798/9781526112798.00004.xml",
    label: "Manchester Hive, France 1429 (map)",
  },
];

interface Props {
  onHover?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  offHover?: () => void;
}

export const Year1429: React.FunctionComponent<Props> = ({
  onHover = () => {},
  offHover = () => {},
}) => (
  <>
    <p>
      King <KingLink node={<EnglishReigns />}>Richard II</KingLink>, son of the
      Black Prince, inherited the throne after his grandfather Edward III. He
      was ousted by his cousin (
      <KingLink node={<EnglishReigns />}>Henry IV</KingLink>
      ). Henry IV was succeeded by his son,{" "}
      <KingLink node={<EnglishReigns />}>Henry V</KingLink>. Henry V is
      considered to be one of the greatest kings ever to have ruled England.
    </p>
    <p>
      The English 1415 campain led to victories at{" "}
      <MapLink id="harfleur" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Siege_of_Harfleur">
          Harfleur
        </BodyLink>
      </MapLink>{" "}
      and, the English troops heavily outnumbered by the French,{" "}
      <MapLink id="agincourt" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_Agincourt">
          Agincourt
        </BodyLink>
      </MapLink>
      . The campain of 1417–20, English forces now outside{" "}
      <MapLink id="paris" onMouseEnter={onHover} onMouseLeave={offHover}>
        Paris
      </MapLink>
      , resulted in the{" "}
      <MapLink id="troyes" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Treaty_of_Troyes">
          Treaty of Troyes
        </BodyLink>
      </MapLink>
      . The treaty recognised Henry as the heir and regent of France. The{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Charles_VII_of_France">
        Dauphin
      </BodyLink>{" "}
      (to be crowned Charles VII) however, did not participate and would not
      sign the treaty - the war continued.
    </p>
    <p>
      In France <KingLink node={<FrenchReigns />}>Charles VI</KingLink>{" "}
      inherited the throne in 1380 after his father, Charles V. Charles VI was
      plagued by mental illness. His mental absence led to a{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Armagnac%E2%80%93Burgundian_Civil_War">
        struggle for power
      </BodyLink>{" "}
      between the Burgundian faction and the Armagnac faction of the French
      royal family. In 1419 the Duke of Burgundy was killed by the Armagnacs.
      This led the Burgundian heir,{" "}
      <KingLink node={<FrenchReigns />}>Philip the Good</KingLink>, to ally with
      England.
    </p>
  </>
);
