import React from "react";
import { YearSlider } from "../../components/YearSlider/YearSlider";
import { Story, YearMap } from "../../data/dataMaps";
import { MapLegends } from "../../components/Legends/MapLegends";
import { References } from "../../components/References/References";
import { Audio } from "../../components/Audio/Audio";
import { Body } from "../../components/Body/Body";
import { ROUTE } from "../../pages/HundredYearsWar/HundredYearsWar";
import { Year1337 } from "../../pages/HundredYearsWar/years/Year1337";
import { Year1360 } from "../../pages/HundredYearsWar/years/Year1360";
import { Year1388 } from "../../pages/HundredYearsWar/years/Year1388";
import { Year1429 } from "../../pages/HundredYearsWar/years/Year1429";
import { Year1453 } from "../../pages/HundredYearsWar/years/Year1453";
import { GridHeader } from "../../components/Header/GridHeader";
import "./Info.scss";
import { IconInfo } from "../../components/IconInfo/IconInfo";

interface Props {
  year: string;
  story: Story;
}

const yearMap: YearMap = {
  "1337": <Year1337 />,
  "1360": <Year1360 />,
  "1388": <Year1388 />,
  "1429": <Year1429 />,
  "1453": <Year1453 />,
};

export const Info: React.FunctionComponent<Props> = ({ year, story }) => (
  <section>
    <div className="grid">
      <div className="grid-slider-input-and-legend">
        <YearSlider story={story} year={year} route={ROUTE} />

        <MapLegends story={story} year={year} />

        <IconInfo />
      </div>

      <div className="grid-header">
        <GridHeader
          initialYear="1337"
          year={year}
          story={story}
          header="Hundred Years' War"
          route={ROUTE}
        />
      </div>

      <div className="grid-body">
        <Body year={year} story={story} yearMap={yearMap} />
      </div>

      <div className="grid-sounds">
        <Audio year={year} story={story} />
      </div>

      <div className="grid-references">
        <References year={year} story={story} />
      </div>
    </div>
  </section>
);
