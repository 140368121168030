import React from "react";

export function Pending() {
  return (
    <div className="pending">
      <span />
      <span />
      <span />
    </div>
  );
}
