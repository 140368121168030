import React from "react";
import { Error } from "./Error";
import { Pending } from "./Pending";

export enum Status {
  PENDING = "is-pending",
  ERROR = "has-error",
  SUCCESS = "has-loaded",
}

interface Props {
  retry: () => void;
  children: React.ReactNode | string;
  status: Status;
}

export const Retry: React.FC<Props> = (props) => {
  const { retry, children, status } = props;

  let elem = null;
  if (status === Status.PENDING) {
    elem = <Pending />;
  }

  if (status === Status.ERROR) {
    elem = <Error retry={retry} />;
  }

  if (status === Status.SUCCESS) {
    elem = children;
  }

  return <>{elem}</>;
};
