import React, { useState } from "react";
import { Link, Redirect, useParams } from "react-router-dom";
import { Slides } from "../../components/Slides/Slides";
import { Page } from "../Page";
import { QuizIntro } from "./QuizIntro";
import { QuizBatch } from "./QuizBatch";
import { QuizComplete } from "./QuizComplete";
import { Questions } from "../../components/Quiz/Questions";
import { ROUTE as HomeRoute } from "../Home";
import { ROUTE as BounceRoute } from "./Bounce";
import "./Quiz.scss";

export const ROUTE = "/quiz";

export const setQuizRoute = () => {
  const l = window.location;
  window.location.href = `${l.protocol}//${l.host}${ROUTE}`;
};

export const Quiz: React.FunctionComponent = () => {
  const { id: idParam = "0" } = useParams<{ id: string }>();
  const [questions, setQuestions] = useState<Questions>();
  const batches = questions?.getQuestions() ?? [];
  const index = parseInt(idParam, 10);
  let slidesIndex;
  if (idParam === "done") {
    slidesIndex = (batches.length ?? 0) + 1;
  } else {
    slidesIndex = index - 1;
  }

  if (idParam !== "0" && !batches.length) {
    return <Redirect to={BounceRoute} />;
  }

  let elem = null;
  if (idParam === "0") {
    elem = <QuizIntro setQuestions={setQuestions} />;
  } else if (idParam === "done") {
    elem = <QuizComplete questions={questions} />;
  } else {
    elem = (
      <QuizBatch
        batch={batches[index - 1]}
        index={index - 1}
        isLast={index === batches.length}
        isFirst={index === 1}
        numberOfQuestions={batches.length}
      />
    );
  }

  return (
    <Page title="Quiz" className="quiz">
      <section>
        <div className="quiz-wrapper">
          <div className="page-breadcrumbs">
            <Link to={HomeRoute}>Home</Link>
            <span>&rsaquo;</span>
            {idParam !== "0" ? <Link to={BounceRoute}>Quiz</Link> : "Quiz"}
          </div>
        </div>
        <div className="quiz-wrapper">
          <Slides index={slidesIndex}>{elem}</Slides>
        </div>
      </section>
    </Page>
  );
};
