export const dataLibs = {
  initTopojson: (): Promise<any> => {
    if (window.topojson) {
      return Promise.resolve();
    }
    return window.maps.importScript("/libs/topojson-client.min.js");
  },

  initD3: (): Promise<any> => {
    if (window.d3) {
      return Promise.resolve();
    }

    return window.maps.importScript("/libs/d3.v7.min.js");
  },
};
