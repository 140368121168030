import React, { ReactNode, useCallback } from "react";
import cx from "classnames";
import { useSetRecoilState } from "recoil";
import { overlayState } from "../../state/uiState";
import "./IconLink.scss";

interface Props {
  node: ReactNode;
  children: ReactNode;
  icon: ReactNode;
  className?: string;
}

export const IconLink: React.FunctionComponent<Props> = ({
  node,
  children,
  icon,
  className,
}) => {
  const setShowOverlay = useSetRecoilState(overlayState);

  const click = useCallback(() => {
    setShowOverlay(node);
  }, [node, setShowOverlay]);

  return (
    <button className={cx("icon-link", className)} onClick={click}>
      <span>{children}</span>
      <span className="icon-wrapper">{icon}</span>
    </button>
  );
};
