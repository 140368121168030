import React, { useCallback, useEffect } from "react";
import cx from "classnames";
import { useRecoilState } from "recoil";
import { Action } from "../Buttons/Action";
import { mapActiveState } from "../../state/mapState";
import { Place } from "../Icons/Place";
import { Close } from "../Icons/Close";
import "./MapInfoToggler.scss";
import {
  PointOfInterest,
  AreasOfInterest,
  InterestType,
} from "../../data/dataMaps";

interface Props {
  highlights?: {
    [key: string]: PointOfInterest | AreasOfInterest;
  };
}

export const MapInfoToggler: React.FunctionComponent<Props> = ({
  highlights = {},
}) => {
  const [active, setActive] = useRecoilState(mapActiveState);

  const onClick = useCallback(
    (data) => {
      if (data.active) {
        setActive(null);
      } else {
        setActive(highlights ? highlights.all : null);
      }
    },
    [setActive, highlights]
  );

  useEffect(() => {
    if (active && active.type === InterestType.ALL) {
      setActive(highlights ? highlights.all : null);
    }
  }, [highlights, active, setActive]);

  const elem = active ? <Close data-id="close" /> : <Place data-id="place" />;

  return (
    <div className={cx("map-info-toggler")}>
      <Action
        onClick={onClick}
        data={{ active: !!active }}
        attrs={{
          "aria-label": "Show information in the map",
        }}
      >
        {elem}
      </Action>
    </div>
  );
};
