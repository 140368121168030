import React from "react";
import { TimelineListItem } from "../TimelineListItem";
import { Anchor } from "./Anchor";

interface Props {
  onHover?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  offHover?: () => void;
}

export const Year1942TimelineFeb: React.FunctionComponent<Props> = ({
  onHover = () => {},
  offHover = () => {},
}) => {
  return (
    <div className="timeline timeline-february">
      <div className="center">1942 February</div>

      <div className="relative">
        <div
          className="timeline-date"
          style={{
            top: "7px",
          }}
        >
          Feb. 02
        </div>

        <div
          className="timeline-date"
          style={{
            top: "77px",
          }}
        >
          Feb. 09
        </div>

        <div
          className="timeline-date"
          style={{
            top: "147px",
          }}
        >
          Feb. 16
        </div>

        <div
          className="timeline-date"
          style={{
            top: "217px",
          }}
        >
          Feb. 23
        </div>
      </div>
      <div className="year-1942-timeline-wrapper">
        <svg viewBox="0 0 90 330">
          <linearGradient
            id="vertical-timeline"
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            <stop offset="0%" stopColor="#fafafa" stopOpacity="0" />
            <stop offset="45%" stopColor="#fafafa" stopOpacity="1" />
            <stop offset="55%" stopColor="#fafafa" stopOpacity="1" />
            <stop offset="100%" stopColor="#fafafa" stopOpacity="0" />
          </linearGradient>

          <line x1="54.5" x2="54.5" y1="-10" y2="330" stroke="#999" />

          {/** Sumatra */}
          <Anchor timelineY={140} anchorY={14} />

          {/* Singapore **/}
          <Anchor timelineY={150} anchorY={40} />

          {/** Java */}
          <Anchor timelineY={280} anchorY={66} />

          <rect x="53" width="3" y="-20" height="40" />
          <rect x="53" width="3" y="310" height="40" />
        </svg>
        <div className="timeline-links">
          <ul>
            <TimelineListItem
              name="Invasion of Sumatra"
              id="sumatra"
              onHover={onHover}
              offHover={offHover}
              number="13"
              link="https://en.wikipedia.org/wiki/Invasion_of_Sumatra"
            />
            <TimelineListItem
              name="Singapore falls"
              id="singapore"
              onHover={onHover}
              offHover={offHover}
              number="14"
              link="https://en.wikipedia.org/wiki/Battle_of_Singapore"
            />
            <TimelineListItem
              name="Invasion of Java"
              number="15"
              id="java"
              onHover={onHover}
              offHover={offHover}
              link="https://en.wikipedia.org/wiki/Battle_of_Java_(1942)"
            />
          </ul>
        </div>
      </div>
    </div>
  );
};
