import React from "react";
import { YearSlider } from "../../components/YearSlider/YearSlider";
import { SimpleStory, YearMap } from "../../data/dataMaps";
import { References } from "../../components/References/References";
import { Audio } from "../../components/Audio/Audio";
import { Body } from "../../components/Body/Body";
import { ROUTE } from "../../pages/SupernovaInTheEast/SupernovaInTheEast";
import { Year1870 } from "./years/Year1870";
import { Year1905 } from "./years/Year1905";
import { GridHeader } from "../../components/Header/GridHeader";
import { MapLegends } from "../../components/Legends/MapLegends";
import { Year1933 } from "./years/Year1933";
import { Year1941 } from "./years/Year1941";
import { Year1942 } from "./years/Year1942";
import { Year1942Timeline } from "./years/Year1942Timeline";
import "./Info.scss";
import { Year1945 } from "./years/Year1945";
import { IconInfo } from "../../components/IconInfo/IconInfo";

interface Props {
  year: string;
  story: SimpleStory;
  month: string;
  setMonth: (month: string) => void;
}

const yearMap: YearMap = {
  "1870": <Year1870 />,
  "1905": <Year1905 />,
  "1933": <Year1933 />,
  "1941": <Year1941 />,
  "1942": <Year1942 />,
  "1945": <Year1945 />,
};

export const Info: React.FunctionComponent<Props> = ({
  year,
  story,
  month,
  setMonth,
}) => (
  <section className="supernova-in-the-east-info">
    <div className="grid">
      <div className="grid-slider-input-and-legend">
        <YearSlider story={story} year={year} route={ROUTE} />
        <MapLegends story={story} year={year} />
        <IconInfo />
      </div>

      <div className="grid-header ellipsis">
        <GridHeader
          initialYear="1870"
          year={year}
          story={story}
          header="Imperial Japan"
          route={ROUTE}
        />
      </div>

      <div className="grid-body">
        {year === "1942" ? (
          <Year1942Timeline month={month} setMonth={setMonth} />
        ) : null}
        <Body year={year} story={story} yearMap={yearMap} />
      </div>

      <div className="grid-sounds">
        <Audio year={year} story={story} />
      </div>

      <div className="grid-references">
        <References year={year} story={story} />
      </div>
    </div>
  </section>
);
