import React from "react";
import { Image } from "../../../components/Images/Image";
import { BodyLink } from "../../HundredYearsWar/years/BodyLink";
import "../../../components/Overlay/Battles.scss";

export const Hannibal: React.FunctionComponent = () => (
  <div className="reigns-trasimene battle-overlay">
    <Image
      src="/images/punic/hannibal_843x420.jpg"
      ratio={843 / 420}
      alt="Hannibal Barca"
    />
    <div className="battle-image-attributes">
      <i>
        Hannibal jurant haine aux Romains
        <br />
        (Hannibal swearing hatred to the Romans)
      </i>
      <span>Claudio Francesco Beaumont</span>
    </div>

    <div className="battle-body">
      <h5>
        <BodyLink link="https://en.wikipedia.org/wiki/Hannibal">
          Hannibal Barca
        </BodyLink>
      </h5>
      <i className="battle-date">247 BCE - 183-181 BCE (age 63 - 66)</i>
      <p>
        After the First Punic War, Carhaginian general{" "}
        <BodyLink link="https://en.wikipedia.org/wiki/Hamilcar_Barca">
          Hamilcar Barca
        </BodyLink>{" "}
        brought his nine year old son, Hannibal, with him to the Iberian
        peninsula. According to{" "}
        <BodyLink link="https://en.wikipedia.org/wiki/Polybius">
          Polybius
        </BodyLink>
        , Hamilcar had the young Hannibal swear never to be a friend of Rome.
        Hannibal was raised in military camps.
      </p>
      <p>
        From 221 BCE until 202 BCE Hannibal commanded the Carthaginian army.
        During the Second Punic War, he lost an eye to infection. Both his
        younger brothers,{" "}
        <BodyLink link="https://en.wikipedia.org/wiki/Hasdrubal_Barca">
          Hasdrubal
        </BodyLink>{" "}
        and{" "}
        <BodyLink link="https://en.wikipedia.org/wiki/Mago_Barca">
          Mago
        </BodyLink>{" "}
        , were killed in the same war. He and his army spent 15 years in hostile
        Roman territory.
      </p>
      <p>
        After the war he was made suffete and reformed the{" "}
        <BodyLink link="https://www.ancient.eu/Carthaginian_Government/">
          Carhaginian state
        </BodyLink>
        . It is unknown whether Hannibal was actually conspiring with Rome's
        enemies, but when Rome came looking for him, he went into exile. When,
        around 183 BCE, Rome again caught up with him, Hannibal ended his life
        by taking poison.
      </p>
      <hr />
      <p>
        Few generals have commanded such respect and loyalty in his troops as
        Hannibal. The march over the Alps is unparallelled and a testament to
        his leadership. He shared in his soldiers' hardship. He was also a
        cunning tactitian that amplified his army's strengths. During the Second
        Punic War, deep into hostile territory, he could not afford defeat, but
        was bold when opportunity arose. Even with great victories behind him,
        he did not get reckless.
      </p>

      <hr />

      <figure>
        <blockquote>
          &ldquo;It is a remarkable and very cogent proof of Hannibal's having
          been by nature a real leader and far superior to anyone else in
          statesmanship, that though he spent seventeen years in the field,
          passed through so many barbarous countries, and employed to aid him in
          desperate and extraordinary enterprises numbers of men of different
          nations and languages, no one ever dreamt of conspiring against him,
          nor was he ever deserted by those who had once joined him or submitted
          to him.&rdquo;
        </blockquote>
        <figcaption>
          <cite>- Polybius</cite>
        </figcaption>
      </figure>
    </div>
  </div>
);
