import React, { useCallback } from "react";
import cx from "classnames";
import { PointOfInterest, SimpleStory, Story } from "../../data/dataMaps";
import "./MapLegends.scss";
import { useSetRecoilState } from "recoil";
import { mapActiveState } from "../../state/mapState";
import { MapLink } from "../../pages/HundredYearsWar/years/MapLink";

interface Props {
  year: string;
  story: Story | SimpleStory;
}

export const MapLegends: React.FunctionComponent<Props> = ({ story, year }) => {
  const legends = story.years[year].legends;
  const highlights = story.years[year].highlights;

  const setActive = useSetRecoilState(mapActiveState);

  const onHover = useCallback(
    (ev) => {
      const { id } = ev.currentTarget.dataset;
      const mq = window.matchMedia("(pointer: fine)");
      if (id && mq.matches) {
        const pointOfInterest = story.years[year].highlights[id];
        setActive(pointOfInterest as PointOfInterest);
      }
    },
    [setActive, story, year]
  );

  const offHover = useCallback(() => {
    const mq = window.matchMedia("(pointer: fine)");
    if (mq.matches) {
      setActive(null);
    }
  }, [setActive]);

  return (
    <ul className="map-legends">
      {legends.map((item) => {
        let elem = item.label;

        if (item.mapId && highlights[item.mapId]) {
          elem = (
            <MapLink
              id={item.mapId}
              onMouseEnter={onHover}
              onMouseLeave={offHover}
            >
              {item.label}
            </MapLink>
          );
        }

        return (
          <li
            key={item.party}
            className={cx(item.party, {
              "tablet-and-up": item.tabletOnly,
            })}
          >
            <div />
            {elem}
          </li>
        );
      })}
    </ul>
  );
};
