import React from "react";
import { Image } from "../../../components/Images/Image";
import { BodyLink } from "../../HundredYearsWar/years/BodyLink";
import "../../../components/Overlay/Battles.scss";

export const Cannae: React.FunctionComponent = () => (
  <div className="reigns-cannae battle-overlay">
    <Image
      src="/images/punic/cannae_a_742x571.png"
      ratio={742 / 571}
      alt="Battle of Cannae"
    />
    <div className="battle-image-attributes">
      <i>
        A) Frank Martini. Cartographer, Department of History, United States
        Military Academy; Date should be <b>216 B.C.</b>
      </i>
    </div>

    <div className="battle-body battle-formations">
      <h5>
        <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_Cannae">
          Battle of the Cannae
        </BodyLink>
      </h5>
      <i className="battle-date"> 2 August, 216 BCE</i>
      <p>
        At Cannae the Romans had brought a massive army of 85,000 troops. With
        both consuls,{" "}
        <BodyLink link="https://en.wikipedia.org/wiki/Lucius_Aemilius_Paullus_(consul_219_BC)">
          Lucius Aemilius Paullus
        </BodyLink>{" "}
        and{" "}
        <BodyLink link="https://en.wikipedia.org/wiki/Gaius_Terentius_Varro">
          Gaius Terentius Varro
        </BodyLink>
        , at the scene the rule was to alternate command on a daily basis. At
        the day of the battle, Varro was in command.
      </p>
      <p>
        Roman sources says Hannibal placed his weaker forces in the center of a
        semicircle pointing towards the Romans. Many modern sources instead
        think the center was occupied by Hannibals veterans who could maintain
        cohesion in the midst of battle.
      </p>
    </div>
    <Image
      src="/images/punic/cannae_b_757x567.png"
      ratio={757 / 567}
      alt="Battle of Cannae"
    />
    <div className="battle-image-attributes">
      <i>
        B) Frank Martini. Cartographer, Department of History, United States
        Military Academy; Date should be <b>216 B.C.</b>
      </i>
    </div>

    <div className="battle-body">
      <p>
        As Hannibal had anticipated, the strong Roman center pushed forward. The
        Carthaginian counterpart slowly fell back. On the flanks, however, the
        Carthaginians held their ground. The Roman lines therefore lost cohesion
        and the center lost it's flanking protection. Now Hannibal ordered his
        flanking units to push inwards. The Romans had no way to escape and were
        massacred.
      </p>
      <p>
        <BodyLink link="https://en.wikipedia.org/wiki/Livy">Livy</BodyLink> has
        48,200 Romans killed and 19,300 captured. Hannibal's battle plan at
        Cannae has been admired ever since, known as the{" "}
        <BodyLink link="https://en.wikipedia.org/wiki/Pincer_movement">
          double envelopment
        </BodyLink>
        . A noteable high level implementation is the{" "}
        <BodyLink link="https://en.wikipedia.org/wiki/Schlieffen_Plan">
          Schlieffen Plan
        </BodyLink>
        .
      </p>
    </div>
  </div>
);
