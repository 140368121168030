import React, { useEffect } from "react";
import cx from "classnames";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { Lazy } from "./components/Lazy/Lazy";
import {
  backdropState,
  deviceWidthState,
  getNamedDeviceWidth,
} from "./state/uiState";
import { useWidth } from "./hooks/useWidth";
import { ROUTE as HundredYearsRoute } from "./pages/HundredYearsWar/HundredYearsWar";
import { ROUTE as SupernovaInTheEastRoute } from "./pages/SupernovaInTheEast/SupernovaInTheEast";
import { ROUTE as PunicWarsRoute } from "./pages/PunicWars/PunicWars";
import { Overlay } from "./components/Overlay/Overlay";
import { Home } from "./pages/Home";
import { NotFound } from "./pages/NotFound/NotFound";
import { ScrollOnNavigation } from "./pages/ScrollOnNavigation";
import { About } from "./pages/About/About";
import { Acknowledgements } from "./pages/Acknowledgements/Acknowledgements";
import { Rulers } from "./pages/Rulers/Rulers";

const HundredYearsWar = function () {
  return (
    <Lazy
      load={() =>
        import(
          /* webpackChunkName: "hundredYearsWar" */ "./pages/HundredYearsWar/HundredYearsWar"
        )
      }
      name="HundredYearsWar"
    />
  );
};

const SupernovaInTheEast = function () {
  return (
    <Lazy
      load={() =>
        import(
          /* webpackChunkName: "supernovaInTheEast" */ "./pages/SupernovaInTheEast/SupernovaInTheEast"
        )
      }
      name="SupernovaInTheEast"
    />
  );
};

const PunicWars = function () {
  return (
    <Lazy
      load={() =>
        import(
          /* webpackChunkName: "punicWars" */ "./pages/PunicWars/PunicWars"
        )
      }
      name="PunicWars"
    />
  );
};

const Styleguide = function () {
  return (
    <Lazy
      load={() =>
        import(/* webpackChunkName: "styleguide" */ "./pages/Styleguide")
      }
      name="Styleguide"
    />
  );
};

const QuizPage = function () {
  return (
    <Lazy
      load={() => import(/* webpackChunkName: "Quiz" */ "./pages/Quiz/Quiz")}
      name="Quiz"
    />
  );
};

const Support = function () {
  return (
    <Lazy
      load={() =>
        import(/* webpackChunkName: "support" */ "./pages/Support/Support")
      }
      name="Support"
    />
  );
};

function App() {
  const [namedWidth, setNamedWidth] = useRecoilState(deviceWidthState);
  const showBackdrop = useRecoilValue(backdropState);
  const width = useWidth();

  useEffect(() => {
    function handleResize() {
      setNamedWidth(getNamedDeviceWidth(width));
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [width, setNamedWidth]);

  return (
    <Router>
      <div
        className={cx("app", namedWidth, {
          "show-backdrop": showBackdrop,
        })}
      >
        <div id="backdrop" />
        <Overlay />
        <ScrollOnNavigation />
        <main>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>

            <Route path={HundredYearsRoute} exact>
              <HundredYearsWar />
            </Route>

            <Route path={SupernovaInTheEastRoute} exact>
              <SupernovaInTheEast />
            </Route>

            <Route path={PunicWarsRoute} exact>
              <PunicWars />
            </Route>

            <Route path="/styleguide" exact>
              <Styleguide />
            </Route>

            <Route path="/acknowledgements" exact>
              <Acknowledgements />
            </Route>

            <Route path="/about" exact>
              <About />
            </Route>

            <Route path="/quiz/:id?" exact>
              <QuizPage />
            </Route>

            <Route path="/support" exact>
              <Support />
            </Route>

            {/* <Route path="/1" exact>
              <Quiz1 />
            </Route>

            <Route path="/2" exact>
              <Quiz2 />
            </Route>

            <Route path="/3" exact>
              <Quiz3 />
            </Route>

            <Route path="/4" exact>
              <Quiz4 />
            </Route>

            <Route path="/5" exact>
              <Quiz5 />
            </Route>

            <Route path="/6" exact>
              <Quiz6 />
            </Route>

            <Route path="/7" exact>
              <Quiz7 />
            </Route>

            <Route path="/albums" exact>
              <Albums />
            </Route>

            <Route path="/countries" exact>
              <Countries />
            </Route> */}

            <Route path="/rulers" exact>
              <Rulers />
            </Route>

            <Route>
              <NotFound />
            </Route>
          </Switch>
        </main>
      </div>
    </Router>
  );
}

export default App;
