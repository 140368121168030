import React from "react";

const progress200 = [
  0, 0.08341500000000451, 0.16683000000000903, 0.25024500000001354,
  0.33365999999999985, 0.41707500000000436, 0.5004900000000089,
  0.5839050000000134, 0.6673199999999997, 0.7507350000000043,
  0.8341500000000087, 0.9175650000000133, 1,
];

interface Props {
  children: React.ReactNode;
  name: string;
}

interface State {
  opacity: number;
  height: string;
  children: React.ReactNode;
  trigger: boolean;
}

interface Animate {
  from: number;
  to: number;
  name: keyof State;
  postfix?: string;
}

export class AnimateHeight extends React.PureComponent<Props, State> {
  wrapperRef = React.createRef<HTMLDivElement>();
  childRef = React.createRef<HTMLDivElement>();
  isAnimating: boolean = false;
  isInit: boolean = true;
  isUnmounting: boolean = false;
  name: string;

  constructor(props: Props) {
    super(props);
    this.state = {
      opacity: 0,
      height: "0px",
      children: props.children,
      trigger: false,
    };
    this.name = props.name;
  }

  componentWillUnmount() {
    this.isUnmounting = true;
  }

  fadeOut = () => {
    return this.animate({
      from: 1,
      to: 0,
      name: "opacity",
    });
  };

  fadeIn = () => {
    return this.animate({
      from: 0,
      to: 1,
      name: "opacity",
    });
  };

  updateState =
    (key: keyof State, value: number | string) =>
    (prevState: State): State => ({
      ...prevState,
      [key]: value,
    });

  animate = (props: Animate) => {
    const self = this;

    return new Promise<void>((resolve, reject) => {
      const { from, to, name, postfix } = props;
      if (from === to) {
        resolve();
        return;
      }
      let value: string | number = 0;
      let index = 0;

      function draw() {
        if (self.isUnmounting) {
          reject();
          return;
        }

        const progress = progress200[index];
        index++;

        if (!progress && progress !== 0) {
          resolve();
          return;
        }

        value = from + (to - from) * progress;

        if (postfix) {
          value = `${value}${postfix}`;
        }

        self.setState(self.updateState(name, value));

        requestAnimationFrame(draw);
      }

      requestAnimationFrame(draw);
    });
  };

  initAnimation = () => {
    const endHeight = this.childRef.current?.scrollHeight ?? 0;
    this.animate({
      from: parseInt(this.state.height, 10),
      to: endHeight,
      name: "height",
      postfix: "px",
    }).then(() => {
      this.setState(
        {
          height: "auto",
        },
        () => {
          this.fadeIn()
            .then(() => {
              this.isAnimating = false;
              this.setState({ trigger: !this.state.trigger });
            })
            .catch((err) => {
              console.log("err", err);
            });
        }
      );
    });
  };

  componentDidMount() {
    if (this.isInit) {
      this.isInit = false;
      this.isAnimating = true;
      this.initAnimation();
    }
  }

  componentDidUpdate() {
    if (this.isAnimating) {
      return;
    }

    if (this.isInit) {
      this.isInit = false;
      this.isAnimating = true;
      this.initAnimation();
      return;
    }

    if (this.name === this.props.name) {
      this.setState({ children: this.props.children });
      return;
    }

    this.isAnimating = true;
    this.name = this.props.name;

    this.fadeOut()
      .then(() => {
        this.setState(
          {
            height: `${this.wrapperRef.current?.scrollHeight}px`,
          },
          () => {
            this.setState(
              {
                children: this.props.children,
              },
              () => {
                const endHeight = this.childRef.current?.scrollHeight ?? 0;
                this.animate({
                  from: parseInt(this.state.height, 10),
                  to: endHeight,
                  name: "height",
                  postfix: "px",
                }).then(() => {
                  this.setState(
                    {
                      height: "auto",
                    },
                    () => {
                      this.fadeIn().then(() => {
                        this.isAnimating = false;
                        this.setState({ trigger: !this.state.trigger });
                      });
                    }
                  );
                });
              }
            );
          }
        );
      })
      .catch(() => {});
  }

  render() {
    const { opacity, height, children } = this.state;

    return (
      <div
        className="height-anim"
        ref={this.wrapperRef}
        style={{
          height,
          opacity,
        }}
      >
        <div ref={this.childRef}>{children}</div>
      </div>
    );
  }
}
