import React, { useState, useEffect, useCallback } from "react";
import cx from "classnames";
import "./Rulers.scss";
import { Page } from "../Page";

interface Props {}

export const Rulers: React.FunctionComponent<Props> = ({}) => {
  return (
    <Page title="Rulers" className="rulers">
      <section>
        <article>
          <div className="ruler-wrapper">
            <p>
              Mm, ja, hur bedömmer man en regent?
              <br />
              Antagligen genom ett konsoliderat utslag på några uppenbara skalor
              - hur progressiv personen är, styrets stabilitet, dess tolerans,
              militäristiska erövringar / nobla försvar, högkultur, noterbara
              reformer, ...
            </p>
            <p>
              Svårt. Jag bara bortser från dem, och har istället utgått från vem
              jag hade trott varit en bra ledare för mig, oavsett vilken tid vi
              hade befunnit oss i. Men ja, det finns några undantag.
            </p>
            <p>
              Listan är sorgligt västcentrerad av den enkla anledning att mina
              egna kunskaper är geografiskt begränsade.
            </p>
            <p>
              Det finns några namn som kanske hade platsat på listan, men som är
              mindre kända för gemene man. Jag har velat hålla mig till de där
              personerna som många känner igen.
            </p>
            <p>Dåså, med alla brasklappar på plats. Let's go.</p>

            <h1>Regenter</h1>
            <ul>
              <li>
                <b>Kyros den store</b>{" "}
                <i>(Perserriket. Regerade från 559 f.Kr. till 530 f.Kr.)</i>
                <p>
                  Grundande Perserriket. Tolerant mot främmande kulturer och
                  besegrade lydstater. Lag och ordning. Hans rike sträckte sig
                  från Egeiska havet i väst till Indien i öst.
                </p>
                <p>
                  Hans efterföljare försökte straffa grekerna vid Marathon (490
                  f.Kr) och Thermopyle (480 f.Kr) men även de annars ofta
                  nedlåtande grekiska kommentarerna hyllar Kyrus som en ideal
                  härskare.
                </p>
              </li>
              <li>
                <b>Marcus Aurelius</b>{" "}
                <i>(Romarriket. Regerade från 161 e.Kr. till 180 e.Kr.)</i>
                <p>
                  Romarna har många kejsare som skulle kunna gå in på den här
                  listan - Augustus och Konstantin den Store är antagligen de
                  mest uppenbara namnen, men det finns många andra, inkl. Caesar
                  (konsul).
                </p>
                <p>
                  Men min favorit är Marcus Aurelius. Kompentet, plikttrogen,
                  prestigelös. Man får känslan av att han helst han suttit i sin
                  villa i Rom med ett glas vin läsandes Platon, men underkastade
                  sig istället kejsarämbetets plikter. Hans stoiska tankar och
                  funderingar resulterade i verket{" "}
                  <a
                    href="https://www.goodreads.com/book/show/30659.Meditations"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Meditations
                  </a>{" "}
                  som läses än idag.
                </p>
                <p>
                  Han överlät riket till sin tyranniske son Commodus som anses
                  vara en av Roms sämsta regenter (Gladiator-filmen).
                </p>
              </li>
              <li>
                <b>Elisabeth I</b>{" "}
                <i>
                  (Storbritannien. Regerade från 1533 e.Kr. till 1603 e.Kr.)
                </i>
                <p>
                  Storbritannien har haft ett par starka kvinnliga monarker. Jag
                  väljer Elizabeth I framför Victoria och nyligen avlidna
                  Elizabeth II.
                </p>
                <p>
                  Regerade framgångsrikt under en tid som generellt måste anses
                  vara otroligt patriarkalisk, i vissa fall säkert när nog
                  misogyn. Landet är splittrat mellan protestanter och
                  katoliker. Slår tillbaka spanska armadan, stödjer kultur (bl a
                  Shakespeare) och koloniaserar framför allt nordamerika
                  (delstaten Virgina är uppkallad efter henne).
                </p>
                <p>
                  Hade inga egna barn. Krigen var kostsamma och hon lämnade
                  landet med stora skulder.
                </p>
              </li>
              <li>
                <b>Ludvig XIV (Solkungen)</b>{" "}
                <i>(Frankrike. Regerade från 1643 e.Kr. till 1715 e.Kr.)</i>
                <p>
                  Solkungen måste nog in på listan även om jag behöver erkänna
                  att det är högst tveksamt att hans tid som regent hade gjort
                  ett stort positivt avtryck på min egna situation.
                </p>
                <p>
                  Satt på tronen i hela 72 år. Bröt provinseliternas maktuövning
                  och centraliserade Frankrike. "Staten, det är jag". Fransk
                  kultur blev en förebild i hela Europa. .
                </p>
                <p>
                  Det verkade som att Ludvig alltid hade en plan - i de fall
                  opponenterna segrade så såg fredsavtalen ändå ut att vara
                  Frankrike till gagn. Frankrike var fruktat.
                </p>
                <p>
                  Hans efterföljare (efterträddes av sin sonsonson) kunde inte
                  leva upp till förfaderns namn och lyckades inte refomera
                  Frankrike vilket slutade i Franska revolutionen och den första
                  republiken.
                </p>
              </li>
              <li>
                <b>Peter den Store</b>{" "}
                <i>(Ryssland. Regerande från 1682 e.Kr. till 1725 e.Kr.)</i>
                <p>
                  Varför Peter och inte Katarina den Stora? Osäker. Kanske
                  orättvis slump. Peter var före, och om inte han tvingat
                  Ryssland igenom vissa reformer så hade Katarinas förutsättning
                  sett annorlunda ut.
                </p>
                <p>
                  Tuff uppväxt. Tuff person. Älskar flottan. Anlägger St
                  Petersburg för att han en duglig Östersjöhamn (vilken han
                  också gjorde till huvudstad). Great Embassy - ger sig ut i
                  Europa för att ena motstånd mot turkarna men också för att
                  inspektera båtvarven i Nederländerna och England.
                </p>
                <p>Många barn men få</p>
              </li>
              <li>Napoleon Bonaparte</li>
              <li>Bismarck</li>
              <li>Franklin D. Roosevelt</li>
            </ul>

            <h3>Alternativ</h3>
            <ul>
              <li>Ramses II</li>
              <li>Alexander den Store</li>
              <li>Charlemange</li>
              <li>Kublai Khan</li>
              <li>Fredrik II av Preussen (den Store)</li>
              <li>Meiji</li>
              <li>Victoria</li>
              <li>Pedro II</li>
              <li>JFK</li>
              <li>Churchill</li>
            </ul>
          </div>
        </article>
      </section>
    </Page>
  );
};
