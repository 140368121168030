import React, { ReactNode } from "react";
import { External } from "../../../components/Icons/External";
import "./BodyLink.scss";

interface Props {
  link: string;
  children: ReactNode;
}

export const BodyLink: React.FunctionComponent<Props> = ({
  link,
  children,
}) => {
  return (
    <a className="body-link" href={link} target="_blank" rel="noreferrer">
      <External />
      {children}
    </a>
  );
};
