import React from "react";
import { KingLink } from "../../../components/Links/KingLink";
import {
  AudioType,
  InterestType,
  Party,
  TooltipDirection,
} from "../../../data/dataMaps";
import { EnglishReigns } from "../EnglishReigns";
import { FrenchReigns } from "../FrenchReigns";
import { BodyLink } from "./BodyLink";
import { MapLink } from "./MapLink";

export const Header1337 = "Prelude to war";

export const DataUrl1337 = "/maps/HundredYearsWar/1337_final.json";

export const Legends1337 = [
  {
    party: Party.ENGLAND,
    label: "Kingdom of England",
  },
  {
    party: Party.ENGLAND_EXP,
    label: "English expansion",
  },
  {
    party: Party.FRANCE,
    label: "Kingdom of France",
  },
  {
    party: Party.SCOTLAND,
    label: "Kingdom of Scotland",
    tabletOnly: true,
  },
  {
    party: Party.AVIGNON,
    label: "Avignon Papacy",
  },
];

export const Highlights1337 = {
  gascony: {
    coords: [0, 45.205826],
    type: InterestType.AREA,
    id: "gascony",
    info: "Gascony",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: ["gascony"],
    mapIds: ["gascony"],
  },
  avignon: {
    coords: [4.89, 43.81],
    type: InterestType.AREA,
    link: "https://en.wikipedia.org/wiki/Avignon_Papacy",
    id: "avignon",
    info: "Avignon Papacy",
    direction: TooltipDirection.ON_BOTTOM,
    mapAreas: ["avignon"],
    mapIds: ["avignon"],
  },
  ponthieu: {
    coords: [2.1844890937229044, 50.247391471611905],
    type: InterestType.AREA,
    link: "https://en.wikipedia.org/wiki/Ponthieu",
    id: "ponthieu",
    info: "Ponthieu",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: ["ponthieu"],
    mapIds: ["ponthieu"],
  },
  all: {
    id: "all",
    type: InterestType.ALL,
    mapAreas: ["gascony", "ponthieu", "calais", "avignon"],
    mapIds: ["gascony", "ponthieu", "calais", "avignon"],
  },
};

export const Audio1337 = [
  {
    type: AudioType.SPOTIFY,
    src: "https://open.spotify.com/embed/playlist/0cqn4CO3pfaVSG6j6aF1aT",
    title: "History of Europe - Hundred Years' War, Introduction",
  },
];

export const Sources1337 = [
  {
    link: "https://www.youtube.com/watch?v=i0NXWfCLIfI",
    label: "Extra history, part 1 (video)",
  },
  {
    link:
      "https://en.wikipedia.org/wiki/Hundred_Years%27_War_(1337%E2%80%931360)",
    label: "Wikipedia, Edwardian Era War",
  },
  {
    link: "https://www.ancient.eu/Hundred_Years'_War/",
    label: "Ancient history, Hundred Years' War",
  },
  {
    link: "https://www.britannica.com/event/Hundred-Years-War",
    label: "Britannica, Hundred Years' War",
  },
  {
    link: "https://www.deviantart.com/cyowari/art/Europe-1330-AD-752207121",
    label: "Cyowari Deviant art, Europe 1330 (map)",
  },
  {
    link: "https://twitter.com/Amazing_Maps/status/812757246090342402/photo/1",
    label: "Amazing maps, Europe 1328 (map)",
  },
];

interface Props {
  onHover?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  offHover?: () => void;
}

export const Year1337: React.FunctionComponent<Props> = ({
  onHover = () => {},
  offHover = () => {},
}) => (
  <>
    <p>
      The{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Hundred_Years%27_War">
        Hundred years' war
      </BodyLink>{" "}
      was a series of conflicts between the Kingdom of England and the Kingdom
      of France from 1337 to 1453.
    </p>
    <p>
      England had held land on the continent since{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/William_the_Conqueror">
        William the Conqueror
      </BodyLink>{" "}
      in 1066. The province of{" "}
      <MapLink id="gascony" onMouseEnter={onHover} onMouseLeave={offHover}>
        Gascony
      </MapLink>{" "}
      was added to the English crown when its ruler,{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Eleanor_of_Aquitaine">
        Eleanor of Aquitaine
      </BodyLink>
      , saw her husband become King{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Henry_II_of_England">
        Henry II
      </BodyLink>{" "}
      of England in 1154.
    </p>
    <p>
      In 1337 King <KingLink node={<FrenchReigns />}>Philip VI</KingLink> of
      France set out to seize Gascony outright. King{" "}
      <KingLink node={<EnglishReigns />}>Edward III</KingLink> of England would
      defend his lands.
    </p>
    <p>Reasons for war:</p>
    <ol>
      <li>
        <span className="number">1</span>The disputed territory of Gascony
      </li>
      <li>
        <span className="number">2</span>Dynastic claims. Edward III had rights
        to the French throne
      </li>
      <li>
        <span className="number">3</span>Philip VI threatened English wool trade
        with Flanders
      </li>
      <li>
        <span className="number">4</span>French support for{" "}
        <BodyLink link="https://en.wikipedia.org/wiki/Second_War_of_Scottish_Independence">
          Scotland's war
        </BodyLink>{" "}
        against England
      </li>
    </ol>
  </>
);
