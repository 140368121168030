import React, { useCallback, useMemo } from "react";
import cx from "classnames";
import "./Choice.scss";
import { IconCheckboxChecked } from "../../Icons/IconCheckboxChecked";
import { IconCheckboxUnchecked } from "../../Icons/IconCheckboxUnchecked";
import { IconRadioChecked } from "../../Icons/IconRadioChecked";
import { IconRadioUnchecked } from "../../Icons/IconRadioUnchecked";

export enum ChoiceType {
  CHECKBOX,
  RADIO,
}

export interface Option {
  value: string;
  label: string | React.ReactNode;
}

export interface ChangeEvent {
  value: string;
  id: string;
  index?: number;
  checked?: boolean;
}

interface Props {
  type: ChoiceType;
  label?: string | React.ReactNode;
  id: string;
  options: Option[];
  value: string[];
  onChange: (type: ChangeEvent) => void;
}

export const Choice: React.FunctionComponent<Props> = ({
  type,
  label,
  id,
  options,
  value,
  onChange,
}) => {
  const change = useCallback(
    (ev) => {
      onChange({
        id,
        value: ev.target.value,
        index: ev.target.dataset.index,
        checked: ev.target.checked,
      });
    },
    [onChange, id]
  );

  const indexLength = options.length - 1;
  const icons = useMemo(
    () =>
      type === ChoiceType.CHECKBOX ? (
        <>
          <IconCheckboxChecked />
          <IconCheckboxUnchecked />
        </>
      ) : (
        <>
          <IconRadioChecked />
          <IconRadioUnchecked />
        </>
      ),
    [type]
  );

  return (
    <div className="input choice">
      {label && <div className="input-header">{label}</div>}
      {options.map((option, idx) => {
        const checked = value.includes(option.value);
        const isCheckbox = type === ChoiceType.CHECKBOX;
        const classes = cx("choice-wrapper", {
          "is-first": idx === 0,
          "is-last": indexLength === idx,
          "is-checked": checked,
        });
        return (
          <label className={classes} key={option.value}>
            <div className="choice-icon">{icons}</div>
            <div className="choice-label">{option.label}</div>
            <input
              type={isCheckbox ? "checkbox" : "radio"}
              name={id}
              onChange={change}
              value={option.value}
              checked={checked}
              data-index={idx}
            />
          </label>
        );
      })}
    </div>
  );
};
