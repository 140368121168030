import React from "react";
import {
  AudioType,
  InterestType,
  Party,
  TooltipDirection,
} from "../../../data/dataMaps";
import { EnglishReigns } from "../EnglishReigns";
import { FrenchReigns } from "../FrenchReigns";
import { BodyLink } from "./BodyLink";
import { KingLink } from "../../../components/Links/KingLink";
import { MapLink } from "./MapLink";

export const Header1360 = "English victories";

export const DataUrl1360 = "/maps/HundredYearsWar/1360.json";

export const Legends1360 = [
  {
    party: Party.ENGLAND,
    label: "Kingdom of England",
  },
  {
    party: Party.ENGLAND_EXP,
    label: "English expansion",
  },
  {
    party: Party.FRANCE,
    label: "Kingdom of France",
  },
  {
    party: Party.SCOTLAND,
    label: "Kingdom of Scotland",
    tabletOnly: true,
  },
  {
    party: Party.AVIGNON,
    label: "Avignon Papacy",
  },
];

export const Highlights1360 = {
  sluys: {
    coords: [3.211388048353399, 51.33958203549697],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Battle_of_Sluys",
    id: "sluys",
    info: "1340 - Sluys",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: [],
    mapIds: ["sluys"],
  },
  calais: {
    coords: [1.5, 50.95820149350909],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Siege_of_Calais_(1346%E2%80%931347)",
    id: "calais",
    info: "1347 - Calais",
    direction: TooltipDirection.ON_LEFT,
    mapAreas: ["calais"],
    mapIds: ["calais"],
  },
  crecy: {
    coords: [1.8462248686614178, 50.23837840109451],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Battle_of_Cr%C3%A9cy",
    id: "crecy",
    info: "1346 - Crécy",
    direction: TooltipDirection.ON_BOTTOM,
    mapAreas: ["ponthieu"],
    mapIds: ["crecy"],
  },
  poitiers: {
    coords: [0.40240325935099513, 46.53011809854953],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Battle_of_Poitiers",
    id: "poitiers",
    info: "1356 - Poitiers",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: ["gascony"],
    mapIds: ["poitiers"],
  },
  all: {
    id: "all",
    type: InterestType.ALL,
    mapAreas: ["gascony", "ponthieu", "calais"],
    mapIds: ["poitiers", "sluys", "calais", "crecy"],
  },
};

export const Audio1360 = [
  {
    type: AudioType.SPOTIFY,
    src: "https://open.spotify.com/embed/playlist/6JQYSIhqGmPyxt0A00MXGR",
    title: "History of Europe - Hundred Years' War, 1336 - 1356",
  },
];

export const Sources1360 = [
  {
    link: "https://www.youtube.com/watch?v=w5K2K-S013U",
    label: "Extra history, part 2 (video)",
  },
  {
    link: "https://www.youtube.com/watch?v=JWuxh-e87dg",
    label: "Extra history, part 3 (video)",
  },
  {
    link:
      "https://pages.uoregon.edu/mapplace/EU/EU20_France/Maps/EU20_67HundredYrsWar.jpg",
    label: "Univ. of Oregon, France 1360 (map)",
  },
  {
    link:
      "https://pages.uoregon.edu/mapplace/EU/EU20_France/Maps/EU20_71HundredYrsWar.jpg",
    label: "Univ. of Oregon, France 1360 (map)",
  },
  {
    link: "https://sourcebooks.fordham.edu/maps/1360france.jpg",
    label: "Fordham Univ., France 1360 (map)",
  },
];

interface Props {
  onHover?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  offHover?: () => void;
}

export const Year1360: React.FunctionComponent<Props> = ({
  onHover = () => {},
  offHover = () => {},
}) => (
  <>
    <p>
      The sea battle of{" "}
      <MapLink id="sluys" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_Sluys">
          Sluys
        </BodyLink>
      </MapLink>{" "}
      1340 was the first major engagement of the Hundred years' war. The English
      victory greatly reduced French naval capability. On land, King Edward III
      and his son,{" "}
      <KingLink node={<EnglishReigns />}>the Black Prince</KingLink>, saw
      impressive victories at{" "}
      <MapLink id="crecy" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_Cr%C3%A9cy">
          Crécy
        </BodyLink>
      </MapLink>{" "}
      (1346) and{" "}
      <MapLink id="poitiers" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_Poitiers">
          Poitiers
        </BodyLink>
      </MapLink>{" "}
      (1356). The English longbowmen wreaked havoc among the French knights.
    </p>
    <p>
      At Poitiers king <KingLink node={<FrenchReigns />}>John II</KingLink> of
      France, son to deceased Philip VI, was captured along with his fourth and
      favourite son <KingLink node={<FrenchReigns />}>Philip the Bold</KingLink>
      . King John II eventually made Philip Duke of Burgundy which, down the
      road, had unintended and disastrous consequences for the French crown.
      Burgundy would later ally with England against France.
    </p>
    <p>
      The{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Treaty_of_Br%C3%A9tigny">
        Treaty of Brétigny
      </BodyLink>
      , signed in 1360, transferred lands in the south west of France to the
      English crown.
    </p>
  </>
);
