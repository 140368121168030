import React from "react";
import cx from "classnames";
import "./Icon.scss";

interface Props {
  className?: string;
}

export const ChevronRight: React.FunctionComponent<Props> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={cx("icon", "chevron-right", className)}
  >
    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);
