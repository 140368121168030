import React from "react";
import { InfoLink } from "../../../components/Links/InfoLink";
import { KingLink } from "../../../components/Links/KingLink";
import {
  AudioType,
  InterestType,
  TooltipDirection,
} from "../../../data/dataMaps";
import { BodyLink } from "../../HundredYearsWar/years/BodyLink";
import { MapLink } from "../../HundredYearsWar/years/MapLink";
import { Hannibal } from "../battles/Hannibal";
import { Ilipa } from "../battles/Ilipa";
import { Scipio } from "../battles/Scipio";

interface BodyProps {
  onHover?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  offHover?: () => void;
}

export const Year201: React.FunctionComponent<BodyProps> = ({
  onHover = () => {},
  offHover = () => {},
}) => (
  <>
    <p>
      Rome elected experienced soldiers to the role of consul in order to defend
      against <KingLink node={<Hannibal />}>Hannibal</KingLink>. In contrast, a
      young 25 year old <KingLink node={<Scipio />}>Scipio Africanus</KingLink>
      (Scipio) , was appointed{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Proconsul">
        proconsul
      </BodyLink>{" "}
      of Hispania (
      <MapLink id="iberia" onMouseEnter={onHover} onMouseLeave={offHover}>
        Iberia
      </MapLink>
      ). No one else would apply for the position voluntarily, by many
      considered a death sentence. Scipio, however, was not inexperienced, he
      had been at{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_Cannae">
        Cannae
      </BodyLink>
      , and, according to some sources, had saved his father at{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_Ticinus">
        Ticinus
      </BodyLink>
      . He would now succeed his father (
      <BodyLink link="https://en.wikipedia.org/wiki/Publius_Cornelius_Scipio_(consul_218_BC)">
        Publius
      </BodyLink>
      ) and uncle (
      <BodyLink link="https://en.wikipedia.org/wiki/Gnaeus_Cornelius_Scipio_Calvus">
        Gnaeus
      </BodyLink>
      ), both{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_the_Upper_Baetis">
        killed in battle
      </BodyLink>
      , as commander in Iberia.
    </p>
    <p>
      In 209 BCE, Scipio suprised everyone with the capture of Carthaginian
      stronghold{" "}
      <MapLink id="nova" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_Cartagena_(209_BC)">
          Carthago Nova
        </BodyLink>
      </MapLink>
      . He was victorious against Hannibal's brother{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Hasdrubal_Barca">
        Hasdrubal Barca
      </BodyLink>{" "}
      at{" "}
      <MapLink id="baecula" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_Baecula">
          Baecula
        </BodyLink>
      </MapLink>{" "}
      in 208 BCE.
    </p>
    <p>
      Hasdrubal took the remainder of his force and marched over the Alps in
      order to reinforce Hannibal. His army was, however, intercepted and
      defeated at the battle of{" "}
      <MapLink id="metaurus" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_the_Metaurus">
          Metaurus
        </BodyLink>
      </MapLink>{" "}
      in 207 BCE. While Hannibal returned to a battle field to give an enemy
      consul or commander a proper burial, the Romans instead cut of the head of
      Hasdrubal and had it thrown into Hannibal's camp.
    </p>
    <p>
      In 206 BCE, at the battle of{" "}
      <InfoLink node={<Ilipa />}>
        <MapLink id="ilipa" onMouseEnter={onHover} onMouseLeave={offHover}>
          Ilipa
        </MapLink>
      </InfoLink>
      , a brilliant Scipio had the Carthaginians outmaneuvered and won a
      decisive victory. The Romans had their adversary pushed off the Iberian
      peninsula.
    </p>
    <p>
      Scipio was determined to take the battle to the Carthaginians. In 204 BCE
      he landed with an army in Africa. The Carthaginian senate ordered Hannibal
      to return. It is said that Hannibal left the{" "}
      <MapLink id="apennine" onMouseEnter={onHover} onMouseLeave={offHover}>
        Apennine Peninsula
      </MapLink>{" "}
      in sadness. The two opposing armies met at{" "}
      <MapLink id="zama" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_Zama">
          Zama
        </BodyLink>
      </MapLink>
      . The battle hung in the balance until the Roman cavalry managed to drive
      off their conterparts and return to attack the Carthaginians in the back.
      Carthage sued for peace.
    </p>
  </>
);

export const Highlights201 = {
  apennine: {
    coords: [14.062038299226435, 41.738488668723285],
    type: InterestType.AREA,
    id: "apennine",
    info: "Apennine Peninsula",
    direction: TooltipDirection.ON_LEFT,
    mapAreas: ["rome"],
    mapIds: ["apennine"],
  },
  syracuse: {
    coords: [15.2875, 37.069167],
    type: InterestType.POINT,
    link:
      "https://en.wikipedia.org/wiki/Siege_of_Syracuse_(213%E2%80%93212_BC)",
    id: "syracuse",
    info: "Syracuse",
    direction: TooltipDirection.ON_TOP,
    mapAreas: ["syracuse"],
    mapIds: ["syracuse"],
  },
  saguntum: {
    coords: [-0.28, 39.677],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Siege_of_Saguntum",
    id: "saguntum",
    info: "Saguntum",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: [],
    mapIds: ["saguntum"],
  },
  rome: {
    coords: [12.502017074584536, 41.88767751335361],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Roman_Republic",
    id: "rome",
    info: "Rome",
    direction: TooltipDirection.ON_TOP,
    mapAreas: [
      "rome",
      "rome_po",
      "rome_sicily",
      "rome_corsica",
      "rome_sardinia",
      "rome_iberia",
      "rome_ebro_upper",
      "rome_ebro_lower",
      "rome_malta",
      "rome_mallorca",
    ],
    mapIds: ["rome"],
  },
  zama: {
    coords: [9.449167, 36.298889],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Battle_of_Zama",
    id: "zama",
    info: "Zama",
    direction: TooltipDirection.ON_BOTTOM,
    mapAreas: [],
    mapIds: ["zama"],
  },
  ilipa: {
    coords: [-5.936332, 37.548825],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Battle_of_Ilipa",
    id: "ilipa",
    info: "Ilipa",
    direction: TooltipDirection.ON_LEFT,
    mapAreas: [],
    mapIds: ["ilipa"],
  },
  metaurus: {
    coords: [13.0145, 43.7824],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Battle_of_the_Metaurus",
    id: "metaurus",
    info: "Metaurus",
    direction: TooltipDirection.ON_LEFT,
    mapAreas: [],
    mapIds: ["metaurus"],
  },
  baetis: {
    coords: [-6.35, 36.783333],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Battle_of_the_Upper_Baetis",
    id: "baetis",
    info: "Upper Baetis",
    direction: TooltipDirection.ON_BOTTOM,
    mapAreas: [],
    mapIds: ["baetis"],
  },
  baecula: {
    coords: [-3.119, 38.007],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Battle_of_Baecula",
    id: "baecula",
    info: "Baecula",
    direction: TooltipDirection.ON_TOP,
    mapAreas: [],
    mapIds: ["baecula"],
  },
  nova: {
    coords: [-0.983333, 37.6],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Battle_of_Cartagena_(209_BC)",
    id: "nova",
    info: "Carthago Nova",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: [],
    mapIds: ["nova"],
  },
  utica: {
    coords: [10.0623, 37.0565],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Battle_of_Utica_(203_BC)",
    id: "utica",
    info: "Utica",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: [],
    mapIds: ["utica"],
  },
  iberia: {
    coords: [-2.5, 41],
    type: InterestType.AREA,
    id: "iberia",
    info: "Iberia",
    direction: TooltipDirection.ON_LEFT,
    mapAreas: ["carthage_iberia", "carthage_ebro"],
    mapIds: ["iberia"],
  },
  carthage: {
    coords: [10.3233, 36.8528],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Carthage",
    id: "carthage",
    info: "Carthage",
    direction: TooltipDirection.ON_BOTTOM,
    mapIds: ["carthage"],
    mapAreas: [
      "carthage",
      "carthage_egypt",
      "carthage_africa",
      "carthage_ebro",
    ],
  },
  all: {
    id: "all",
    type: InterestType.ALL,
    mapAreas: [],
    mapIds: [
      "apennine",
      "iberia",
      "nova",
      "saguntum",
      "baetis",
      "baecula",
      "metaurus",
      "ilipa",
      "syracuse",
      "utica",
      "zama",
    ],
  },
};

export const Audio201 = [
  {
    type: AudioType.SPOTIFY,
    src: "https://open.spotify.com/embed/playlist/64zRVrVkHqKoueMWBcPNDe",
    title: "History of Rome - Second Punic War",
  },
];

export const Sources201 = [
  {
    link: "https://www.youtube.com/watch?v=bXNRlSrVLG0",
    label: "Biographics, Scipio (video)",
  },
  {
    link:
      "https://www.deviantart.com/cyowari/art/Second-Punic-War-Part-III-211-208-BC-814913765",
    label: "Cyowari, 211 - 208 BCE (map)",
  },
  {
    link:
      "https://www.deviantart.com/cyowari/art/Second-Punic-War-Part-IV-207-201-BC-815660895",
    label: "Cyowari, 207 - 201 BCE (map)",
  },
  {
    link:
      "https://www.deviantart.com/cyowari/art/After-the-Second-Punic-War-200-BC-815791030",
    label: "Cyowari, 200 BCE (map)",
  },
];

export const Legends201 = [
  {
    party: "rome",
    label: "Roman Republic",
    mapId: "rome",
  },
  {
    party: "carthage",
    label: "Republic of Carthage",
    mapId: "carthage",
  },
];
