import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import cx from "classnames";
import { useWidth } from "../../hooks/useWidth";
import { MapTooltip } from "../../components/Map/MapTooltip";
import {
  AreasOfInterest,
  InterestType,
  PointOfInterest,
} from "../../data/dataMaps";
import "./Map.scss";

interface Props {
  land: any;
  // rivers: any;
  imageUrl: string;
  territories: any;
  isWideDevice: boolean;
  highlights?: {
    [key: string]: PointOfInterest | AreasOfInterest;
  };
  isActive: null | AreasOfInterest | PointOfInterest;
}

interface Area {
  w: number;
  h: number;
}

export const fluerDeLis =
  "m 182.75847,203.5552 c 4.80546,-7.14689 4.39578,-22.79233 -5.21215,-28.31548 -7.35026,-3.67513 -15.65143,-2.2368 -19.52991,0.92698 -6.24382,3.7768 -12.25739,15.85478 -12.25739,28.08525 l 21.56033,-0.0269 -0.006,24.0752 -44.74739,-0.0239 c 0.58611,12.38299 7.3383,22.30191 19.90367,27.82506 -1.61776,5.21816 -9.19529,15.85477 -19.60164,15.1341 -2.05436,11.25565 -4.00707,17.5742 -13.36383,27.80116 -9.36272,-10.22397 -11.31543,-16.54551 -13.36978,-27.80116 -10.40639,0.72067 -17.98389,-9.91594 -19.60168,-15.1341 12.56541,-5.52315 19.31759,-15.44207 19.9037,-27.82506 l -44.75038,0.0239 v -24.0752 l 21.55435,0.0269 c 0,-12.23047 -6.00459,-24.30845 -12.2544,-28.08525 -3.87848,-3.16079 -12.17665,-4.59912 -19.52392,-0.92698 -9.61391,5.52315 -10.02658,21.17158 -5.22112,28.31548 -45.3992696,-8.42975 -40.8958396,-52.27406 -25.42981,-68.19163 12.49961,-12.85846 26.12656,-14.86795 36.63459,-10.81305 23.88083,8.56133 39.94194,37.08913 40.72242,79.70143 h 12.48762 c 0,-38.73681 -6.74019,-51.08691 -24.30247,-99.73969 C 67.50787,81.51957 72.82172,53.09344 88.6346,26.91901 94.29829,17.53832 101.37344,9.06969 109.50416,0 c 8.12476,9.06969 15.19988,17.53832 20.86357,26.91901 15.81291,26.17443 21.12673,54.60056 12.2873,77.59325 -17.57121,48.65278 -24.31144,60.99989 -24.31144,99.73969 h 12.48765 c 0.78049,-42.6123 16.84758,-71.1401 40.72242,-79.70143 10.50803,-4.05191 24.14398,-2.04541 36.63758,10.81305 15.46304,15.91757 19.9665,59.76188 -25.43279,68.19163 z";

function getBounds(state: any, path: any, w: any, h: any) {
  const b = path.bounds(state);
  const s = 1 / Math.max((b[1][0] - b[0][0]) / w, (b[1][1] - b[0][1]) / h);
  const t = [
    (w - s * (b[1][0] + b[0][0])) / 2,
    (h - s * (b[1][1] + b[0][1])) / 2,
  ];
  const rasterWidth = (b[1][0] - b[0][0]) * s;
  const rasterHeight = (b[1][1] - b[0][1]) * s;
  const rtranslateX = (w - rasterWidth) / 2;
  const rtranslateY = (h - rasterHeight) / 2;
  const raster = [rasterWidth, rasterHeight, rtranslateX, rtranslateY];
  return { s, t, raster };
}

function getArea(plot: MutableRefObject<HTMLDivElement | null>) {
  if (!plot.current) {
    return {
      w: 0,
      h: 0,
    };
  }
  const box = plot.current.getBoundingClientRect();
  const w = box.width;
  const h = box.height;
  return {
    w,
    h,
  };
}

function getPatternFrance(isWideDevice: boolean) {
  if (isWideDevice) {
    return (
      <>
        <path d={fluerDeLis} transform="translate(95, 150)scale(0.08)" />
        <path d={fluerDeLis} transform="translate(115, 150)scale(0.08)" />
        <path d={fluerDeLis} transform="translate(105, 170)scale(0.08)" />
      </>
    );
  } else {
    return (
      <>
        <path d={fluerDeLis} transform="translate(80, 130)scale(0.14)" />
        <path d={fluerDeLis} transform="translate(120, 130)scale(0.14)" />
        <path d={fluerDeLis} transform="translate(100, 170)scale(0.14)" />
      </>
    );
  }
}

function getPatternEngland(isWideDevice: boolean) {
  if (isWideDevice) {
    return (
      <>
        <line x1="36%" x2="36%" y1="0" y2="45%" strokeWidth="4%" />
        <line x1="0" x2="100%" y1="37%" y2="37%" strokeWidth="4%" />
      </>
    );
  } else {
    return (
      <>
        <line x1="30%" x2="30%" y1="0" y2="35%" strokeWidth="7%" />
        <line x1="0" x2="100%" y1="18%" y2="18%" strokeWidth="7%" />
      </>
    );
  }
}

interface State {
  area: Area;
  projection: any;
  pathFunc: any;
  rasterOffsets: number[];
}

const mapCache = {
  topoData: undefined,
  meshData: undefined,
};

function getTopoData(land: any) {
  let topoData = mapCache.topoData;
  if (!topoData) {
    topoData = window.topojson.feature(land, land.objects.geo);
    mapCache.topoData = topoData;
  }
  return topoData;
}

function getMeshData(land: any) {
  let meshData = mapCache.meshData;
  if (!meshData) {
    meshData = window.topojson.mesh(
      land,
      land.objects.geo,
      (a: any, b: any) => a.properties.id === b.properties.id
    );
    mapCache.meshData = meshData;
  }
  return meshData;
}

export const Map: React.FunctionComponent<Props> = ({
  land,
  // rivers,
  territories,
  imageUrl,
  isWideDevice,
  highlights = {},
  isActive,
}) => {
  const plot = useRef<HTMLDivElement>(null);
  const width = useWidth();
  // const riversData = window.topojson.feature(rivers, rivers.objects.geo);
  const topoData = getTopoData(land);
  const meshData = getMeshData(land);

  const territoriesData = territories
    ? window.topojson.feature(territories, territories.objects.geo)
    : undefined;

  const [state, setState] = useState<State>({
    area: { w: 0, h: 0 },
    projection: undefined,
    pathFunc: undefined,
    rasterOffsets: [0, 0, 0, 0],
  });

  useEffect(() => {
    const area = getArea(plot);
    const topo = window.topojson.feature(land, land.objects.geo);
    const projection = window.d3.geoMercator().scale(1).translate([0, 0]);
    const pathFunc = window.d3.geoPath().projection(projection);
    const { s, t, raster } = getBounds(topo, pathFunc, area.w, area.h);
    projection.scale(s).translate(t);
    setState({
      area,
      pathFunc,
      projection: projection,
      rasterOffsets: raster,
    });
  }, [width, land]);

  return (
    <div
      className={cx("hundred-years-war-map", "map", {
        "is-active": isActive && isActive.type === InterestType.ALL,
        "has-item-hovered": isActive && isActive.type !== InterestType.ALL,
      })}
      ref={plot}
    >
      <img
        src={imageUrl}
        style={{
          width: `${state.rasterOffsets[0]}px`,
          height: `${state.rasterOffsets[1]}px`,
          left: `${state.rasterOffsets[2]}px`,
          top: `${state.rasterOffsets[3]}px`,
        }}
        alt=""
      />

      <div className="map-highlights">
        {highlights &&
          Object.values(highlights).map((item) => {
            return (
              <MapTooltip
                isActive={isActive}
                key={item.id}
                pointOfInterest={item}
                projection={state.projection}
              />
            );
          })}
      </div>

      <svg viewBox={`0 0 ${state.area.w} ${state.area.h}`}>
        <defs>
          <pattern
            id="pattern_uk"
            width="100%"
            height="100%"
            patternUnits="userSpaceOnUse"
          >
            <rect x="0" y="0" width="100%" height="100%" />
            {getPatternEngland(isWideDevice)}
          </pattern>

          <pattern
            id="pattern_scotland"
            width="100%"
            height="100%"
            patternUnits="userSpaceOnUse"
          >
            <rect x="0" y="0" width="100%" height="100%" />
            <line x1="7%" x2="47%" y1="23%" y2="8%" strokeWidth="2%" />
            <line x1="0" x2="40%" y1="8%" y2="21%" strokeWidth="2%" />
          </pattern>

          <pattern
            id="pattern_uk_exp"
            width="3"
            height="3"
            patternTransform="rotate(45 0 0)"
            patternUnits="userSpaceOnUse"
          >
            <line x1="0" y1="0" x2="0" y2="3" />
          </pattern>

          <pattern
            id="pattern_burgundy"
            width="3"
            height="3"
            patternTransform="rotate(45 0 0)"
            patternUnits="userSpaceOnUse"
          >
            <line x1="0" x2="0" y1="0" y2="3" />
          </pattern>

          <pattern
            id="pattern_ally_burgundy"
            width="3"
            height="3"
            patternTransform="rotate(45 0 0)"
            patternUnits="userSpaceOnUse"
          >
            <line x1="0" x2="0" y1="0" y2="3" />
          </pattern>

          <pattern
            id="pattern_france"
            width="100%"
            height="100%"
            viewBox="0 0 219 299.03668"
            patternUnits="userSpaceOnUse"
          >
            <rect x="0" y="0" width="100%" height="100%" />
            {getPatternFrance(isWideDevice)}
          </pattern>
        </defs>

        {state.pathFunc && meshData && (
          <path d={state.pathFunc(meshData)} className="mesh" />
        )}

        {state.pathFunc &&
          topoData &&
          (topoData as any).features.map((d: any, i: number) => (
            <path key={`path-${i}`} d={state.pathFunc(d)} className="land" />
          ))}

        {/* {pathFunc &&
          riversData.features.map((d: any, i: number) => (
            <path key={`path-${i}`} d={pathFunc(d)} className="river" />
          ))} */}

        {/* 44.605826, 0.051629 */}

        {state.pathFunc &&
          territoriesData &&
          territoriesData.features.map((d: any, i: number) => {
            const activeMap: { [key: string]: boolean } = {};

            if (isActive) {
              if (isActive.type === InterestType.ALL) {
                highlights.all.mapAreas.forEach((id) => {
                  activeMap[id] = true;
                });
              } else {
                isActive.mapAreas.forEach((id) => {
                  activeMap[id] = true;
                });
              }
            }
            return (
              <path
                key={`path-${i}`}
                d={state.pathFunc(d)}
                className={cx("territory", d.properties.sov, {
                  "is-active": activeMap[d.properties.id],
                })}
              />
            );
          })}
      </svg>
    </div>
  );
};
