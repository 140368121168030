import React from "react";
import { SimpleStory, Story } from "../../data/dataMaps";
import "./References.scss";

interface Props {
  year: string;
  story: Story | SimpleStory;
}

export const References: React.FunctionComponent<Props> = ({ year, story }) => {
  const sources = story.years[year].sources;

  return (
    <div className="references">
      <ul>
        {sources.map((source, idx) => {
          if (idx) {
            return (
              <li key={source.link}>
                <a href={source.link} target="_blank" rel="noreferrer">
                  {source.label}
                </a>
              </li>
            );
          }

          return (
            <li key={source.link}>
              <div>Sources:</div>
              <a href={source.link} target="_blank" rel="noreferrer">
                {source.label}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
