import React, { useCallback, useEffect, useState } from "react";
import {
  Choice,
  ChoiceType,
} from "../../components/Interactions/Choice/Choice";
import { Question } from "../../components/Quiz/Question";
import { v4 as uid } from "uuid";
import "./QuizQuestion.scss";
import { LevelTag } from "./LevelTag";

interface Props {
  question?: Question;
}

export const QuizQuestion: React.FunctionComponent<Props> = ({ question }) => {
  // const { id } = useParams<{ id: string }>();

  // if (!questions || !id || !questions[parseInt(id, 10)]) {
  //   return <Redirect to="/quiz" />;
  // }
  const [, setAnswers] = useState<string[]>([]);

  useEffect(() => {
    setAnswers(question?.customerAnswers ?? []);
  }, [question]);

  const onChange = useCallback(
    (ev) => {
      const value = ev.value;
      if (question) {
        question.setAnswer(value);
        setAnswers([value]);
      }
    },
    [question]
  );

  if (!question) {
    return null;
  }

  return (
    <div className="quiz-question">
      <LevelTag level={question.level} />
      <Choice
        options={question.options}
        id={uid()}
        type={ChoiceType.RADIO}
        label={<span dangerouslySetInnerHTML={{ __html: question.question }} />}
        onChange={onChange}
        value={question.customerAnswers}
      />

      {/* <div className="question-label">{question?.question}</div>
      {question.options.map((item) => (
        <label key={item.value}>
          <input
            type="radio"
            id={item.value}
            name={item.value}
            onChange={onChange}
            checked={answers.includes(item.id)}
          />
          {item.label}
        </label>
      ))} */}
    </div>
  );
};
