import React from "react";
import { TimelineListItem } from "../TimelineListItem";
import { Anchor } from "./Anchor";

interface Props {
  onHover?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  offHover?: () => void;
}

export const Year1942TimelineApril: React.FunctionComponent<Props> = ({
  onHover = () => {},
  offHover = () => {},
}) => {
  return (
    <div className="timeline timeline-february">
      <div className="center">1942 April</div>

      <div className="relative">
        <div
          className="timeline-date"
          style={{
            top: "47px",
          }}
        >
          Apr. 06
        </div>

        <div
          className="timeline-date"
          style={{
            top: "117px",
          }}
        >
          Apr. 13
        </div>

        <div
          className="timeline-date"
          style={{
            top: "187px",
          }}
        >
          Apr. 20
        </div>

        <div
          className="timeline-date"
          style={{
            top: "257px",
          }}
        >
          Apr. 27
        </div>
      </div>
      <div className="year-1942-timeline-wrapper">
        <svg viewBox="0 0 90 330">
          <linearGradient
            id="vertical-timeline"
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            <stop offset="0%" stopColor="#fafafa" stopOpacity="0" />
            <stop offset="45%" stopColor="#fafafa" stopOpacity="1" />
            <stop offset="55%" stopColor="#fafafa" stopOpacity="1" />
            <stop offset="100%" stopColor="#fafafa" stopOpacity="0" />
          </linearGradient>

          <line x1="54.5" x2="54.5" y1="-10" y2="330" stroke="#999" />

          {/** Bataan - 9 April */}
          <Anchor timelineY={90} anchorY={14} />

          {/** Bataan - 10 April */}
          <Anchor timelineY={100} anchorY={40} />

          {/** Doolittle - 18 April */}
          <Anchor timelineY={180} anchorY={66} />

          <rect x="53" width="3" y="-20" height="40" />
          <rect x="53" width="3" y="310" height="40" />
        </svg>
        <div className="timeline-links">
          <ul>
            <TimelineListItem
              name="US and Filipino forces surrender at Bataan"
              number="19"
              id="bataan"
              onHover={onHover}
              offHover={offHover}
              link="https://en.wikipedia.org/wiki/Battle_of_Bataan"
            />
            <TimelineListItem
              name="Bataan Deathmarch"
              id="deathmarch"
              onHover={onHover}
              offHover={offHover}
              number="20"
              link="https://en.wikipedia.org/wiki/Bataan_Death_March"
            />
            <TimelineListItem
              name="Doolittle Raid"
              id="doolittle"
              onHover={onHover}
              offHover={offHover}
              number="21"
              link="https://en.wikipedia.org/wiki/Doolittle_Raid"
            />
          </ul>
        </div>
      </div>
    </div>
  );
};
