import React, { ReactNode } from "react";
import cx from "classnames";
import { Batch } from "../../components/Quiz/Batch";
import "./QuestionComplete.scss";

interface Props {
  batch: Batch;
  index: number;
  showOnlyIncorrect: boolean;
}

function getAnswers(items: ReactNode[], prefix: string) {
  return (
    <ul>
      {items.map((item, idx) => (
        <li key={`${prefix}-${idx}`}>{item}</li>
      ))}
    </ul>
  );
}

export const QuestionComplete: React.FunctionComponent<Props> = ({
  batch,
  index,
  showOnlyIncorrect,
}) => {
  const questions = showOnlyIncorrect
    ? batch.getIncorrectQuestions()
    : batch.getQuestions();

  if (!questions.length) {
    return null;
  }

  return (
    <div className="question-complete">
      <span>
        {index}. {questions[0].header}
      </span>
      {questions.map((question) => {
        const isCorrect = question.isCorrect();

        return (
          <div
            className={cx("question-answer", {
              "is-correct": isCorrect,
              "is-incorrect": !isCorrect,
            })}
            key={question.question}
          >
            <div
              className="question-label"
              dangerouslySetInnerHTML={{ __html: question.question }}
            />
            <div className="question-answer-cells">
              <div>
                <b>Correct answer:</b>{" "}
                {getAnswers(question.getCorrectAnswers(), "correct")}
              </div>
              {!isCorrect && (
                <div>
                  <b>Your answer:</b>{" "}
                  {getAnswers(question.getCustomerAnswers(), "customer")}
                </div>
              )}
            </div>
            <div className="question-explanation">
              <i dangerouslySetInnerHTML={{ __html: question.explanation }} />
            </div>
          </div>
        );
      })}
    </div>
  );
};
