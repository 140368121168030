import React from "react";
import { TimelineListItem } from "../TimelineListItem";
import { Anchor } from "./Anchor";

interface Props {
  onHover?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  offHover?: () => void;
}

export const Year1942TimelineMarch: React.FunctionComponent<Props> = ({
  onHover = () => {},
  offHover = () => {},
}) => {
  return (
    <div className="timeline timeline-february">
      <div className="center">1942 March</div>

      <div className="relative">
        <div
          className="timeline-date"
          style={{
            top: "7px",
          }}
        >
          Mar. 02
        </div>

        <div
          className="timeline-date"
          style={{
            top: "77px",
          }}
        >
          Mar. 09
        </div>

        <div
          className="timeline-date"
          style={{
            top: "147px",
          }}
        >
          Mar. 16
        </div>

        <div
          className="timeline-date"
          style={{
            top: "217px",
          }}
        >
          Mar. 23
        </div>

        <div
          className="timeline-date"
          style={{
            top: "287px",
          }}
        >
          Mar. 30
        </div>
      </div>
      <div className="year-1942-timeline-wrapper">
        <svg viewBox="0 0 90 330">
          <linearGradient
            id="vertical-timeline"
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            <stop offset="0%" stopColor="#fafafa" stopOpacity="0" />
            <stop offset="45%" stopColor="#fafafa" stopOpacity="1" />
            <stop offset="55%" stopColor="#fafafa" stopOpacity="1" />
            <stop offset="100%" stopColor="#fafafa" stopOpacity="0" />
          </linearGradient>

          <line x1="54.5" x2="54.5" y1="-10" y2="330" stroke="#999" />

          {/** Salamaua 8 March */}
          <Anchor timelineY={80} anchorY={14} />

          {/** Corregidor 11 March */}
          <Anchor timelineY={110} anchorY={40} />

          {/** Andaman 23 March */}
          <Anchor timelineY={230} anchorY={66} />

          <rect x="53" width="3" y="-20" height="40" />
          <rect x="53" width="3" y="310" height="40" />
        </svg>
        <div className="timeline-links">
          <ul>
            <TimelineListItem
              name="Invasion of Salamaua"
              number="16"
              id="salamaua"
              onHover={onHover}
              offHover={offHover}
              link="https://en.wikipedia.org/wiki/Invasion_of_Salamaua%E2%80%93Lae"
            />
            <TimelineListItem
              name="MacArthur leaves Corregidor"
              number="17"
              id="corregidor"
              onHover={onHover}
              offHover={offHover}
              link="https://en.wikipedia.org/wiki/Douglas_MacArthur%27s_escape_from_the_Philippines"
            />
            <TimelineListItem
              name="Japan invade Andaman Islands"
              number="18"
              id="andaman"
              onHover={onHover}
              offHover={offHover}
              link="https://en.wikipedia.org/wiki/Japanese_occupation_of_the_Andaman_Islands"
            />
          </ul>
        </div>
      </div>
    </div>
  );
};
