import React from "react";
import {
  AudioType,
  InterestType,
  Party,
  TooltipDirection,
} from "../../../data/dataMaps";
import { FrenchReigns } from "../FrenchReigns";
import { BodyLink } from "./BodyLink";
import { KingLink } from "../../../components/Links/KingLink";
import { MapLink } from "./MapLink";

export const Header1388 = "French comeback";

export const DataUrl1388 = "/maps/HundredYearsWar/1388a.json";

export const Legends1388 = [
  {
    party: Party.ENGLAND,
    label: "Kingdom of England",
  },
  {
    party: Party.ENGLAND_EXP,
    label: "English expansion",
  },
  {
    party: Party.FRANCE,
    label: "Kingdom of France",
  },
  {
    party: Party.SCOTLAND,
    label: "Kingdom of Scotland",
    tabletOnly: true,
  },
  {
    party: Party.BURGUNDY_ALLY,
    label: "Burgundy within French domain",
  },
  {
    party: Party.BURGUNDY_INDEPENDENT,
    label: "Burgundy outside of French influence",
  },
];

export const Highlights1388 = {
  la_rochelle: {
    coords: [-1.2279496613822138, 46.15690612967585],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Battle_of_La_Rochelle",
    id: "la_rochelle",
    info: "1372 - La Rochelle",
    direction: TooltipDirection.ON_BOTTOM,
    mapAreas: [],
    mapIds: ["la_rochelle"],
  },
  duchy_of_burgundy: {
    coords: [3.877295948311428, 47.307229205044895],
    type: InterestType.AREA,
    link: "https://en.wikipedia.org/wiki/Duchy_of_Burgundy",
    id: "burgundy",
    info: "1363 - Duchy of Burgundy",
    direction: TooltipDirection.ON_LEFT,
    mapAreas: ["duchy_of_burgundy", "county_of_burgundy"],
    mapIds: ["burgundy"],
  },
  low_countries: {
    coords: [2.9858836823193694, 50.91016566711501],
    type: InterestType.AREA,
    id: "low_countries",
    info: "1384 - Flanders",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: ["low_countries"],
    mapIds: ["low_countries"],
  },
  calais: {
    coords: [1.5, 50.95820149350909],
    type: InterestType.POINT,
    id: "calais",
    info: "Calais",
    direction: TooltipDirection.ON_LEFT,
    mapAreas: ["calais"],
    mapIds: ["calais"],
  },
  all: {
    id: "all",
    type: InterestType.ALL,
    mapAreas: [
      "gasgony_upper",
      "gasgony_lower",
      "calais",
      "low_countries",
      "duchy_of_burgundy",
      "county_of_burgundy",
    ],
    mapIds: ["la_rochelle", "burgundy", "calais", "low_countries"],
  },
};

export const Audio1388 = [
  {
    type: AudioType.SPOTIFY,
    src: "https://open.spotify.com/embed/playlist/2VWw9PCNaQoUD7pKsHtsFL",
    title: "History of Europe - Hundred Years' War, 1360 - 1413",
  },
];

export const Sources1388 = [
  {
    link: "https://www.youtube.com/watch?v=B7ENUtP_22g",
    label: "Extra history (video), part 4",
  },
  {
    link: "https://en.wikipedia.org/wiki/War_of_the_Breton_Succession",
    label: "Wikipedia, War of Breton succession",
  },
  {
    link: "https://en.wikipedia.org/wiki/Duchy_of_Burgundy",
    label: "Wikipedia, Duchy of Burgundy",
  },
  {
    link:
      "https://en.wikipedia.org/wiki/Hundred_Years%27_War#/media/File:Apanages.svg",
    label: "Wikipedia, Appanages 1388 (map)",
  },
  {
    link:
      "https://pages.uoregon.edu/mapplace/EU/EU20_France/Maps/EU20_71HundredYrsWar.jpg",
    label: "Univ. of Oregon, France 1377 (map)",
  },
  {
    link:
      "https://pages.uoregon.edu/mapplace/EU/EU20_France/Maps/EU20_59HundredYrsWar.jpg",
    label: "Univ. of Oregon, France 1377 (map)",
  },
  {
    link: "http://www.emersonkent.com/map_archive/great_schism_1378.htm",
    label: "Emerson Kent, Papal affinity 1378 (map)",
  },
];

interface Props {
  onHover?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  offHover?: () => void;
}

export const Year1388: React.FunctionComponent<Props> = ({
  onHover = () => {},
  offHover = () => {},
}) => (
  <>
    <p>
      The Black Prince, now ruler of Aquitaine and Gascony, introduced taxes and
      let{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Free_company">
        free companies
      </BodyLink>{" "}
      roam the French countryside. The French lords were dissatisfied and in
      1369 they rebelled with backing of French king{" "}
      <KingLink node={<FrenchReigns />}>Charles V</KingLink>, who had inherited
      the throne after John II. The treaty of Brétigny was abandoned. War was
      resumed.
    </p>
    <p>
      This time the French applied a{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Fabian_strategy">
        Fabian strategy
      </BodyLink>{" "}
      , avoiding pitch battles and instead waging a war of attrition. The
      English were unable to counter. Under the leadership of{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Bertrand_du_Guesclin">
        Bertrand du Guesclin
      </BodyLink>{" "}
      French forces pushed their adversary back. An English attempt in 1372 to
      land a relieving force at the port of{" "}
      <MapLink id="la_rochelle" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_La_Rochelle">
          La Rochelle
        </BodyLink>
      </MapLink>{" "}
      failed. By 1380 the English held only{" "}
      <MapLink id="calais" onMouseEnter={onHover} onMouseLeave={offHover}>
        Calais
      </MapLink>{" "}
      and a few other ports. Edward III died in 1377 preceded by the death of
      his son, the Black Prince, in 1376. They had seen all their gains vanish.
    </p>
    <p>
      Philip the Bold, brother of Charles V, had married{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Margaret_III,_Countess_of_Flanders">
        Margaret III
      </BodyLink>
      , countess of{" "}
      <MapLink
        id="low_countries"
        onMouseEnter={onHover}
        onMouseLeave={offHover}
      >
        Flanders
      </MapLink>
      , and ruled her lands when her father died in 1384.
    </p>
    <p>
      In 1376, pope Gregory XI abandoned Avignon and moved his court to Rome.
    </p>
  </>
);
