import React from "react";
import {
  AudioType,
  InterestType,
  TooltipDirection,
} from "../../../data/dataMaps";
import { BodyLink } from "../../HundredYearsWar/years/BodyLink";
import { MapLink } from "../../HundredYearsWar/years/MapLink";

export const Header1905 = "Russo-Japanese War";

export const DataUrl1905 = "/maps/SupernovaInTheEast/1905/1905a.json";

export const Legends1905 = [
  {
    party: "japan",
    label: "Empire of Japan",
  },
  {
    party: "japan-exp",
    label: "Japanese expansion",
  },
  {
    party: "manchuria",
    label: "Chinese Manchuria",
  },
  {
    party: "korea",
    label: "Korea",
  },
];

export const Highlights1905 = {
  liaodong: {
    coords: [121.2502, 38.8534],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Siege_of_Port_Arthur",
    id: "liaodong",
    info: "Port Arthur",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: ["liaodong"],
    mapIds: ["liaodong"],
  },
  taiwan: {
    coords: [121.9131146856705, 24.280371004902324],
    type: InterestType.AREA,
    id: "taiwan",
    info: "Taiwan",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: ["taiwan"],
    mapIds: ["taiwan"],
  },
  russia: {
    coords: [135.6418819794674, 49.26331788720473],
    type: InterestType.AREA,
    id: "russia",
    info: "Russia",
    direction: TooltipDirection.ON_TOP,
    mapAreas: ["russia"],
    mapIds: ["russia"],
  },
  sakhalin: {
    coords: [142.61055854532327, 48.60065206658299],
    type: InterestType.AREA,
    id: "sakhalin",
    info: "Sakhalin",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: ["sakhalin"],
    mapIds: ["sakhalin"],
  },
  tsushima: {
    coords: [130.1514291534614, 34.56887740457882],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Battle_of_Tsushima",
    id: "tsushima",
    info: "Battle of Tsushima",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: [],
    mapIds: ["tsushima"],
  },
  all: {
    id: "all",
    type: InterestType.ALL,
    mapAreas: ["taiwan", "liaodong", "sakhalin"],
    mapIds: ["liaodong", "taiwan", "russia", "sakhalin", "tsushima"],
  },
};

export const Audio1905 = [
  {
    type: AudioType.SPOTIFY,
    src:
      "https://open.spotify.com/embed-podcast/episode/2lqNevhLx08QPmvCLjhS4g",
    title: "Dan Carlin, Supernova in the East I",
  },
];

export const Sources1905 = [
  {
    link: "https://en.wikipedia.org/wiki/First_Sino-Japanese_War",
    label: "Wikipedia, First Sino-Japanese war",
  },
  {
    link: "https://www.britannica.com/event/First-Sino-Japanese-War-1894-1895",
    label: "Britannica, First Sino-Japanese war",
  },
  {
    link: "https://en.wikipedia.org/wiki/Russo-Japanese_War",
    label: "Wikipedia, Russo-Japanese War",
  },
  {
    link: "https://www.britannica.com/event/Russo-Japanese-War",
    label: "Britannica, Russo-Japanese War",
  },
  {
    link:
      "https://www.reddit.com/r/MapPorn/comments/cutqea/the_russo_japanese_war_and_peace_19041905/",
    label: "Reddit MapPorn, Russo-Japanese War (map)",
  },
  {
    link: "https://www.youtube.com/watch?v=vS-N_IPCRSc",
    label: "Feature history, Russo-Japanese war (video)",
  },
  {
    link: "https://www.youtube.com/watch?v=jm4w2n1KjfQ&t=48s",
    label: "Kings and Generals, Russo-Japanese War (video)",
  },
  {
    link:
      "https://artsourceinternational.com/wp-content/uploads/2018/05/RM-1905-CHINA.jpg",
    label: "artsourceinternational.com, China 1902 (map)",
  },
  {
    link: "http://www.portsmouthpeacetreaty.org/uploads/CHINA.PDF",
    label: "portsmouthpeacetreaty.org, China 1905, influence (map)",
  },
];

interface Props {
  onHover?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  offHover?: () => void;
}

export const Year1905: React.FunctionComponent<Props> = ({
  onHover = () => {},
  offHover = () => {},
}) => (
  <>
    <p>
      The first{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/First_Sino-Japanese_War">
        Sino-Japanese war
      </BodyLink>{" "}
      (1894 - 1895) was fought over the influence in Korea. Korea posed a threat
      to Japan with its proximity and Japan now feared Korea might fall prey to
      foreign domination. Japan, to the suprise of most foreign observers, won
      decisive battles against China and in the{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Treaty_of_Shimonoseki">
        Treaty of Shimonoseki
      </BodyLink>{" "}
      the ruling Chinese{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Qing_dynasty">
        Qing dynasty
      </BodyLink>{" "}
      ceded{" "}
      <MapLink id="taiwan" onMouseEnter={onHover} onMouseLeave={offHover}>
        Taiwan
      </MapLink>{" "}
      and the{" "}
      <MapLink id="liaodong" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Liaodong_Peninsula">
          Liaodong Peninsula
        </BodyLink>
      </MapLink>{" "}
      to Japan. Japans annexation of Port Arthur on the Liaodong Peninsula
      ruined Russia's plans for an ice free port in the east (Vladivostok was
      frozen in the winter months). Russia, therefore, with allies France, and
      Germany, forced Japan to give up the peninsula which in turn was leased by
      Russia.
    </p>
    <p>
      Between 1899 and 1901 an alliance of countries crushed the{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Boxer_Rebellion">
        Boxer Rebellion
      </BodyLink>{" "}
      in China. Russia, however, would not withdraw their troops from Manchuria
      but instead sent more troops and tried to connect Port Arthur to the
      Siberian railroad. Japan disapproved and diplomatic relations between the
      countries eventually broke down.
    </p>
    <p>
      In 1904 Japan declared war on Russia (a couple of hours after launching a
      suprise attack on Russia's Pacific fleet anchored in{" "}
      <MapLink id="liaodong" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Liaodong_Peninsula">
          Port Arthur
        </BodyLink>
      </MapLink>
      ). When Russia's Baltic fleet arrived, it too was defeated at the{" "}
      <MapLink id="tsushima" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_Tsushima">
          battle of Tsushima
        </BodyLink>
      </MapLink>
      . Russia faced internal revolt and Japan was financially exhausted so
      negotiations opened. In the{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Treaty_of_Portsmouth">
        treaty of Portsmouth
      </BodyLink>{" "}
      Japan gained the lease to the{" "}
      <MapLink id="liaodong" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Liaodong_Peninsula">
          Liaodong Peninsula
        </BodyLink>
      </MapLink>
      , a part of{" "}
      <MapLink id="sakhalin" onMouseEnter={onHover} onMouseLeave={offHover}>
        Sakhalin
      </MapLink>{" "}
      and their claim to Korea was recognized.
    </p>
  </>
);
