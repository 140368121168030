import React from "react";
import { TimelineListItem } from "../TimelineListItem";
import { Anchor } from "./Anchor";

interface Props {
  onHover?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  offHover?: () => void;
}

export const Year1942TimelineMay: React.FunctionComponent<Props> = ({
  onHover = () => {},
  offHover = () => {},
}) => {
  return (
    <div className="timeline timeline-february">
      <div className="center">1942 May</div>

      <div className="relative">
        <div
          className="timeline-date"
          style={{
            top: "27px",
          }}
        >
          May. 04
        </div>

        <div
          className="timeline-date"
          style={{
            top: "97px",
          }}
        >
          May. 11
        </div>

        <div
          className="timeline-date"
          style={{
            top: "167px",
          }}
        >
          May. 18
        </div>

        <div
          className="timeline-date"
          style={{
            top: "237px",
          }}
        >
          May. 25
        </div>
      </div>
      <div className="year-1942-timeline-wrapper">
        <svg viewBox="0 0 90 330">
          <linearGradient
            id="vertical-timeline"
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            <stop offset="0%" stopColor="#fafafa" stopOpacity="0" />
            <stop offset="45%" stopColor="#fafafa" stopOpacity="1" />
            <stop offset="55%" stopColor="#fafafa" stopOpacity="1" />
            <stop offset="100%" stopColor="#fafafa" stopOpacity="0" />
          </linearGradient>

          <line x1="54.5" x2="54.5" y1="-10" y2="330" stroke="#999" />

          {/** Tulagi - 3 May */}
          <Anchor timelineY={30} anchorY={14} />

          {/** Coral - 7-8 May */}
          <Anchor timelineY={70} anchorY={40} />

          <rect x="53" width="3" y="-20" height="40" />
          <rect x="53" width="3" y="310" height="40" />
        </svg>
        <div className="timeline-links">
          <ul>
            <TimelineListItem
              name="Invasion of Tulagi"
              number="22"
              id="tulagi"
              onHover={onHover}
              offHover={offHover}
              link="https://en.wikipedia.org/wiki/Invasion_of_Tulagi_(May_1942)"
            />
            <TimelineListItem
              name="Battle of the Coral Sea"
              id="coral"
              onHover={onHover}
              offHover={offHover}
              number="23"
              link="https://en.wikipedia.org/wiki/Battle_of_the_Coral_Sea"
            />
          </ul>
        </div>
      </div>
    </div>
  );
};
