import React, { useCallback, useMemo } from "react";
import { useSetRecoilState } from "recoil";
import { AnimateHeight } from "../../../components/AnimateHeight";
import { Action } from "../../../components/Buttons/Action";
import { ChevronLeft } from "../../../components/Icons/ChevronLeft";
import { ChevronRight } from "../../../components/Icons/ChevronRight";
import { PointOfInterest } from "../../../data/dataMaps";
import { mapActiveState } from "../../../state/mapState";
import { TimelineMonths } from "../SupernovaInTheEast";
import { Year1942TimelineApril } from "./Timelines/Year1942TimelineApril";
import { Year1942TimelineDec } from "./Timelines/Year1942TimelineDec";
import { Year1942TimelineFeb } from "./Timelines/Year1942TimelineFeb";
import { Year1942TimelineJan } from "./Timelines/Year1942TimelineJan";
import { Year1942TimelineMarch } from "./Timelines/Year1942TimelineMarch";
import { Year1942TimelineMay } from "./Timelines/Year1942TimelineMay";
import {
  Highlights1942April,
  Highlights1942Dec,
  Highlights1942Feb,
  Highlights1942Jan,
  Highlights1942March,
  Highlights1942May,
} from "./Year1942";
import "./Year1942Timeline.scss";

interface Props {
  month: string;
  setMonth: (month: string) => void;
}

export const Year1942Timeline: React.FunctionComponent<Props> = ({
  month,
  setMonth,
}) => {
  const setActive = useSetRecoilState(mapActiveState);

  const onHover = useCallback(
    (ev) => {
      const { id } = ev.currentTarget.dataset;
      const mq = window.matchMedia("(pointer: fine)");
      if (id && mq.matches) {
        let pointOfInterest: unknown;
        if (month === TimelineMonths.DEC) {
          pointOfInterest =
            Highlights1942Dec[id as keyof typeof Highlights1942Dec];
        } else if (month === TimelineMonths.JAN) {
          pointOfInterest =
            Highlights1942Jan[id as keyof typeof Highlights1942Jan];
        } else if (month === TimelineMonths.FEB) {
          pointOfInterest =
            Highlights1942Feb[id as keyof typeof Highlights1942Feb];
        } else if (month === TimelineMonths.MARCH) {
          pointOfInterest =
            Highlights1942March[id as keyof typeof Highlights1942March];
        } else if (month === TimelineMonths.APRIL) {
          pointOfInterest =
            Highlights1942April[id as keyof typeof Highlights1942April];
        } else {
          pointOfInterest =
            Highlights1942May[id as keyof typeof Highlights1942May];
        }

        setActive(pointOfInterest as PointOfInterest);
      }
    },
    [setActive, month]
  );

  const offHover = useCallback(() => {
    const mq = window.matchMedia("(pointer: fine)");
    if (mq.matches) {
      setActive(null);
    }
  }, [setActive]);

  const elem = useMemo(() => {
    if (month === TimelineMonths.DEC) {
      return <Year1942TimelineDec onHover={onHover} offHover={offHover} />;
    }

    if (month === TimelineMonths.JAN) {
      return <Year1942TimelineJan onHover={onHover} offHover={offHover} />;
    }

    if (month === TimelineMonths.FEB) {
      return <Year1942TimelineFeb onHover={onHover} offHover={offHover} />;
    }

    if (month === TimelineMonths.MARCH) {
      return <Year1942TimelineMarch onHover={onHover} offHover={offHover} />;
    }

    if (month === TimelineMonths.APRIL) {
      return <Year1942TimelineApril onHover={onHover} offHover={offHover} />;
    }

    return <Year1942TimelineMay onHover={onHover} offHover={offHover} />;
  }, [month, onHover, offHover]);

  const onIncrement = useCallback(() => {
    if (month === TimelineMonths.DEC) {
      setMonth(TimelineMonths.JAN);
    } else if (month === TimelineMonths.JAN) {
      setMonth(TimelineMonths.FEB);
    } else if (month === TimelineMonths.FEB) {
      setMonth(TimelineMonths.MARCH);
    } else if (month === TimelineMonths.MARCH) {
      setMonth(TimelineMonths.APRIL);
    } else {
      setMonth(TimelineMonths.MAY);
    }
  }, [month, setMonth]);

  const onDecrement = useCallback(() => {
    if (month === TimelineMonths.JAN) {
      setMonth(TimelineMonths.DEC);
    } else if (month === TimelineMonths.FEB) {
      setMonth(TimelineMonths.JAN);
    } else if (month === TimelineMonths.MARCH) {
      setMonth(TimelineMonths.FEB);
    } else if (month === TimelineMonths.APRIL) {
      setMonth(TimelineMonths.MARCH);
    } else {
      setMonth(TimelineMonths.APRIL);
    }
  }, [month, setMonth]);

  const incrementElem = useMemo(() => {
    if (month === TimelineMonths.DEC) {
      return (
        <div className="disabled" data-id="year-slider-disabled">
          <ChevronLeft />
        </div>
      );
    } else {
      return (
        <div data-id="year-slider-increment">
          <ChevronLeft />
        </div>
      );
    }
  }, [month]);

  const decrementElem = useMemo(() => {
    if (month === TimelineMonths.MAY) {
      return (
        <div className="disabled" data-id="year-slider-disabled">
          <ChevronRight />
        </div>
      );
    } else {
      return (
        <div data-id="year-slider-increment">
          <ChevronRight />
        </div>
      );
    }
  }, [month]);

  return (
    <div className="year-1942-timeline">
      <div className="timeline-header">
        <strong>Timeline</strong>{" "}
        <div className="timeline-interval">
          <div>
            <span>07 Dec. 1941</span>, Pearl Harbor <i>-</i>
          </div>
          <div>
            <span>31 May 1942</span>
          </div>
        </div>
      </div>

      <div className="timeline-box">
        <div className="relative">
          <div className="timeline-buttons">
            <Action className="on-decrement" onClick={onDecrement}>
              {incrementElem}
            </Action>

            <Action className="on-increment" onClick={onIncrement}>
              {decrementElem}
            </Action>
          </div>
        </div>

        <AnimateHeight name={month}>{elem}</AnimateHeight>
      </div>
    </div>
  );
};
