import React, { useEffect } from "react";
import cx from "classnames";
import { MapSide } from "../data/dataMaps";
import { Footer } from "../components/Footer/Footer";
import "./Page.scss";

export interface PageProps {
  children: React.ReactNode;
  className?: string;
  title?: string;
  mapSide?: MapSide;
  canonical?: string;
  description?: string;
}

export const Page: React.FunctionComponent<PageProps> = ({
  children,
  className,
  title,
  mapSide,
  canonical,
  description,
}) => {
  useEffect(() => {
    if (canonical) {
      const elem = document.getElementById("canonical") as HTMLAnchorElement;
      if (elem) {
        elem.setAttribute("href", canonical);
      }
    }

    if (description) {
      const elem = document.getElementById("description") as HTMLMetaElement;
      if (elem) {
        elem.setAttribute("content", description);
      }
    }

    if (!title) {
      document.title = "Interactive History";
      return;
    }
    document.title = `${title} | Interactive History`;
  }, [title, description, canonical]);

  return (
    <div className="page-outer">
      <div className={cx("page", className)}>{children}</div>
      <Footer mapSide={mapSide || MapSide.NONE} />
    </div>
  );
};
