import React from "react";
import {
  AudioType,
  InterestType,
  Party,
  TooltipDirection,
} from "../../../data/dataMaps";
import { EnglishReigns } from "../EnglishReigns";
import { BodyLink } from "./BodyLink";
import { KingLink } from "../../../components/Links/KingLink";
import { MapLink } from "./MapLink";

export const Header1453 = "End of the war";

export const DataUrl1453 = "/maps/HundredYearsWar/1453.json";

export const Legends1453 = [
  {
    party: Party.ENGLAND,
    label: "Kingdom of England",
  },
  {
    party: Party.ENGLAND_EXP,
    label: "English expansion",
  },
  {
    party: Party.FRANCE,
    label: "Kingdom of France",
  },
  {
    party: Party.SCOTLAND,
    label: "Kingdom of Scotland",
    tabletOnly: true,
  },
  {
    party: Party.BURGUNDY_ALLY,
    label: "Burgundy within French domain",
  },
  {
    party: Party.BURGUNDY_INDEPENDENT,
    label: "Burgundy outside of French influence",
  },
];

export const Highlights1453 = {
  orleans: {
    coords: [1.9038501585335819, 47.903190480175226],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Siege_of_Orl%C3%A9ans",
    id: "orleans",
    info: "1428 - Orléans",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: [],
    mapIds: ["orleans"],
  },
  patay: {
    coords: [1.6958, 48.0483],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Battle_of_Patay",
    id: "patay",
    info: "1429 - Patay",
    direction: TooltipDirection.ON_LEFT,
    mapAreas: [],
    mapIds: ["patay"],
  },
  formigny: {
    coords: [-0.8902468991825869, 49.33876595331344],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Battle_of_Formigny",
    id: "formigny",
    info: "1450 - Formigny",
    direction: TooltipDirection.ON_TOP,
    mapAreas: [],
    mapIds: ["formigny"],
  },
  castillon: {
    coords: [-0.04879574634626896, 44.85652950012706],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Battle_of_Castillon",
    id: "castillon",
    info: "1453 - Castillon",
    direction: TooltipDirection.ON_TOP,
    mapAreas: [],
    mapIds: ["castillon"],
  },
  calais: {
    coords: [1.9, 51.0682],
    type: InterestType.POINT,
    id: "calais",
    info: "Calais",
    direction: TooltipDirection.ON_TOP,
    mapAreas: ["calais"],
    mapIds: ["calais"],
  },
  all: {
    type: InterestType.ALL,
    id: "all",
    mapIds: ["orleans", "patay", "formigny", "castillon", "calais"],
    mapAreas: [
      "luxembourg",
      "low_countries",
      "duchy_of_burgundy",
      "county_of_burgundy",
      "rethel",
      "calais",
    ],
  },
};

export const Audio1453 = [
  {
    type: AudioType.SPOTIFY,
    src: "https://open.spotify.com/embed/playlist/2phmCUFohF2YrmF28f0nG2",
    title: "History of Europe - Hundred Years' War, 1420 - 1453",
  },
];

export const Sources1453 = [
  {
    link: "https://www.youtube.com/watch?v=SuidVI9ENN8",
    label: "Extra history, part 6 (video)",
  },
  {
    link:
      "https://en.wikipedia.org/wiki/Hundred_Years%27_War_(1415%E2%80%931453)",
    label: "Wikipedia, Lancastrian War",
  },
  {
    link: "http://www.emersonkent.com/map_archive/france_1453.htm",
    label: "Emerson Kent, France 1453 (map)",
  },
  {
    link:
      "https://pages.uoregon.edu/mapplace/EU/EU20_France/Maps/EU20_67HundredYrsWar.jpg",
    label: "Univ. of Oregon, France 1453 (map)",
  },
  {
    link:
      "http://www.emersonkent.com/map_archive/burgundian_lands_1361_1543.htm",
    label: "Emerson Kent, Burgundy 1435 (map)",
  },
  {
    link:
      "https://en.wikipedia.org/wiki/Charles_the_Bold#/media/File:Map_France_1477-en.svg",
    label: "Wikipedia, France 1477 (map)",
  },
  {
    link:
      "https://en.wikipedia.org/wiki/Charles_the_Bold#/media/File:Karte_Haus_Burgund_4_EN.png",
    label: "Wikipedia, Burgundy 1465 - 1477 (map)",
  },
];

interface Props {
  onHover?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  offHover?: () => void;
}

export const Year1453: React.FunctionComponent<Props> = ({
  onHover = () => {},
  offHover = () => {},
}) => (
  <>
    <p>
      Henry V died in 1422, probably from dysentery, at the age of 35. His
      capable brother{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/John_of_Lancaster,_1st_Duke_of_Bedford">
        John, Duke of Bedford
      </BodyLink>
      , was named regent of France until Henrys son,{" "}
      <KingLink node={<EnglishReigns />}>Henry VI</KingLink>, just a few months
      old at the time, came of age.
    </p>
    <p>
      With their Burgundian ally, John held on to English conquered territory.
      But in 1428, a peasant girl reversed the fortunes of war. The girl,{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Joan_of_Arc">
        Joan of Arc
      </BodyLink>
      , claimed that God had sent her a vision to lead the French King to
      victory. At the siege of{" "}
      <MapLink id="orleans" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Siege_of_Orl%C3%A9ans">
          Orléans
        </BodyLink>
      </MapLink>{" "}
      she rallied the French troops and drove off the attackers. Next year the
      French won the battle of{" "}
      <MapLink id="patay" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_Patay">
          Patay
        </BodyLink>
      </MapLink>
      . At Patay the English veteran force of longbowmen were decimated. From
      now on the English and Burgundian forces were on the defensive. Joan of
      Arc was captured in 1430. She was burned as a heretic in Rouen.
    </p>
    <p>
      The{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Congress_of_Arras#Treaty_of_Arras">
        Treaty of Arras
      </BodyLink>{" "}
      in 1435 saw France and Burgundy make a separate peace, and John, Duke of
      Bedford, die during the negotiations. England was alone.
    </p>
    <p>
      King Henry VI was unlike his father,{" "}
      <i>
        "timid, shy, passive, well-intentioned, and averse to warfare and
        violence; he was also at times mentally unstable"
      </i>
      . He handed over provinces for vague promises of peace (and a wife). But
      King Charles VII had no intentions to offer peace.
    </p>
    <p>
      The French victory at{" "}
      <MapLink id="formigny" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_Formigny">
          Formigny
        </BodyLink>
      </MapLink>
      , 1450, ended English possessions in Normandy. In 1453,{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/John_Talbot,_1st_Earl_of_Shrewsbury">
        John Talbot
      </BodyLink>
      , <i>"the English Achilles"</i>, was killed at{" "}
      <MapLink id="castillon" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_Formigny">
          Castillon
        </BodyLink>
      </MapLink>{" "}
      in Gascony. Save{" "}
      <MapLink id="calais" onMouseEnter={onHover} onMouseLeave={offHover}>
        Calais
      </MapLink>
      , the English had lost all territory on the European mainland. The Hundred
      years war had come to an end.
    </p>
    <p>
      <strong>Aftermath</strong>
      <br />
      France went back to war with Burgundy in 1465. When ruler{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Charles_the_Bold">
        Charles the Bold
      </BodyLink>{" "}
      died in 1477 most of the Burgundian lands would end up in French hands.
    </p>
    <p>
      The weak rule of Henry VI fragmented the English court. It would errupt in{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Wars_of_the_Roses">
        the Wars of the roses
      </BodyLink>
      .
    </p>
  </>
);
