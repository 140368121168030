import React, { useCallback, useEffect, useRef, useState } from "react";
import cx from "classnames";
import { Page } from "../Page";
import { dataMaps, MapSide, SimpleStory, Transform } from "../../data/dataMaps";
import {
  deviceWidthState,
  isWideDevice,
  NAMED_DEVICE_WIDTH,
} from "../../state/uiState";
import { useRecoilState, useRecoilValue } from "recoil";
import { Retry, Status } from "../../components/Lazy/Retry";
import { dataLibs } from "../../data/dataLibs";
import { Map } from "./Map";
import { Redirect, useParams } from "react-router-dom";
import {
  getDynamicLink,
  getFormattedTextFromYear,
  getUrlFromYear,
  getYearFromUrl,
} from "../../data/routeUtils";
import { MobileHeader } from "../../components/Header/MobileHeader";
import { MapDesktopHint } from "../../components/Map/MapDesktopHint";
import { mapActiveState } from "../../state/mapState";
import { Info } from "./Info";
import { Highlights264, Legends264, Sources264 } from "./years/Year264";
import "./PunicWars.scss";
import "./Legends.scss";
import {
  Audio237,
  Highlights237,
  Legends237,
  Sources237,
} from "./years/Year237";
import {
  Audio218,
  Highlights218,
  Legends218,
  Sources218,
} from "./years/Year218";
import {
  Audio213,
  Highlights213,
  Legends213,
  Sources213,
} from "./years/Year213";
import {
  Audio201,
  Highlights201,
  Legends201,
  Sources201,
} from "./years/Year201";
import {
  Audio146,
  Sources146,
  Legends146,
  Highlights146,
} from "./years/Year146";

interface Props {}

export const ROUTE = "/the-punic-wars/:year?";

const punicWarsStory: SimpleStory = {
  dataUrl: "/maps/PunicWars/padded_sea_52_-13.5_24_29.json",
  imageUrl: "/maps/PunicWars/final_52_-13.5_24_29.webp",
  years: {
    "-264": {
      year: -264,
      header: "Two great powers",
      territoriesUrl: "/maps/PunicWars/264/264c.json",
      audio: [],
      sources: Sources264,
      highlights: Highlights264,
      legends: Legends264,
      transform: [
        {
          scale: 1.2,
          transformX: -0.1,
          transformY: -0.15,
        },
        {
          scale: 1,
          transformX: 0.15,
          transformY: 0,
        },
      ],
    },
    "-237": {
      year: -237,
      header: "The First Punic War",
      territoriesUrl: "/maps/PunicWars/237/237a.json",
      audio: Audio237,
      sources: Sources237,
      highlights: Highlights237,
      legends: Legends237,
      transform: [
        {
          scale: 3.2,
          transformX: -1.8,
          transformY: -1.4,
        },
        {
          scale: 2,
          transformX: -0.7,
          transformY: -0.8,
        },
      ],
    },
    "-218": {
      year: -218,
      header: "The Second Punic War",
      territoriesUrl: "/maps/PunicWars/218/218c.json",
      audio: Audio218,
      sources: Sources218,
      highlights: Highlights218,
      legends: Legends218,
      transform: [
        {
          scale: 1.2,
          transformX: -0.1,
          transformY: -0.15,
        },
        {
          scale: 1,
          transformX: 0.2,
          transformY: 0,
        },
      ],
      boundaries: [
        {
          name: "hannibal_girona",
          bounds: ["M", [39.677, -0.28], "Q", [41.131, 0], [41.888, 2.621]],
        },
        {
          name: "hannibal_ticinus",
          bounds: ["M", [41.888, 2.621], "Q", [44, 2], [45.25, 8.866667]],
        },
      ],
    },
    "-213": {
      year: -213,
      header: "Cannae",
      territoriesUrl: "/maps/PunicWars/213/213c.json",
      audio: Audio213,
      sources: Sources213,
      highlights: Highlights213,
      legends: Legends213,
      transform: [
        {
          scale: 2.4,
          transformX: -1.2,
          transformY: -0.8,
        },
        {
          scale: 2,
          transformX: -0.85,
          transformY: -0.6,
        },
      ],
      boundaries: [
        {
          name: "carthage_in_rome",
          bounds: [
            "M",
            [40.938, 13.632],
            "Q",
            [41.4, 14.5],
            [41.05, 14.7363],
            "Q",
            [40.97, 14.8],
            [41, 15.05],
            "Q",
            [41.05, 15.3],
            [41.4, 15.1],
            "Q",
            [41.7, 14.9],
            [41.88954879133545, 16.51046928709588],
            "L",
            [41.409837464764755, 15.997587038656313],
            "Q",
            [41.0659430145916, 15.605378000488096],
            [40.80659430145916, 15.9],
            "Q",
            [40.4, 16.3],
            [40.60659430145916, 16.505378000488096],
            "Q",
            [40.85, 16.8],
            [40.7, 17.35],
            "Q",
            [40.48, 18.1],
            [40.104465771257914, 17.81783782484162],
            "Q",
            [40.2914133603854, 18.15897923311765],
            [39.93526368044971, 18.546748994647118],
            "L",
            [39.558116445154795, 18.3858634605969],
            "L",
            [38.23178960705606, 16.379654290826732],
            "Q",
            [38.07, 15.756792610474424],
            [38.351839226104896, 15.820729360940231],
            "Q",
            [40.5, 15.783987583379566],
            [40.36008849925343, 14.989768602071672],
            "L",
            [40.66495533413553, 14.744161084043586],
            "Q",
            [41.15, 14.4],
            [40.83423210452178, 13.93167260819129],
            "Z",
          ],
        },
      ],
    },
    "-201": {
      year: -201,
      header: "A peace",
      territoriesUrl: "/maps/PunicWars/201/201c.json",
      audio: Audio201,
      sources: Sources201,
      highlights: Highlights201,
      legends: Legends201,
      transform: [
        {
          scale: 1.3,
          transformX: -0.06,
          transformY: -0.25,
        },
        {
          scale: 1,
          transformX: 0.15,
          transformY: 0,
        },
      ],
    },
    "-146": {
      year: -146,
      header: "Carthage destroyed",
      territoriesUrl: "/maps/PunicWars/146/146b.json",
      audio: Audio146,
      sources: Sources146,
      highlights: Highlights146,
      legends: Legends146,
      transform: [
        {
          scale: 1.3,
          transformX: -0.06,
          transformY: -0.25,
        },
        {
          scale: 1,
          transformX: 0,
          transformY: 0,
        },
      ],
    },
  },
};

const fetchedYears: { [key: string]: any } = {};

const loadImage = (url: string) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.addEventListener("load", () => resolve(img));
    img.addEventListener("error", (err) => reject(err));
    img.src = url;
  });

function getMapSide(namedWidth: NAMED_DEVICE_WIDTH) {
  if (
    !isWideDevice(namedWidth) ||
    window.matchMedia("(orientation:portrait)").matches
  ) {
    return MapSide.NONE;
  }

  return MapSide.RIGHT;
}

function getPromises(story: SimpleStory) {
  return [
    dataLibs.initD3(),
    dataLibs.initTopojson(),
    dataMaps.getTopo(story.dataUrl),
    loadImage(story.imageUrl),
  ];
}

export const PunicWars: React.FunctionComponent<Props> = () => {
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const [loading, setLoading] = useState<boolean>(true);
  const namedWidth = useRecoilValue(deviceWidthState);
  const mapSide = getMapSide(namedWidth);
  const [active, setActive] = useRecoilState(mapActiveState);
  const [data, setData] = useState<any[]>();
  const hoveredBeforeLoaded = useRef<boolean>(false);
  const [dynamic, setDynamic] = useState<{
    territories: any;
    year: string;
    transforms: Transform[];
  }>();

  const { year: yearFromUrl } = useParams<{
    year: string;
  }>();

  const load = useCallback(() => {
    const promises = getPromises(punicWarsStory);
    Promise.all(promises)
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        console.error(err);
        setStatus(Status.ERROR);
      });
  }, []);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    setTimeout(load, 1000);
  }, [load]);

  useEffect(load, [load]);

  useEffect(() => {
    if (data) {
      setStatus(Status.SUCCESS);
    }
  }, [data]);

  const year = getYearFromUrl(yearFromUrl);

  useEffect(() => {
    if (!year || !punicWarsStory.years[year]) {
      return;
    }

    setLoading(true);
    let animationDone = false;
    let fetchDone = false;
    let territories: any;

    window.setTimeout(() => {
      animationDone = true;
      if (fetchDone) {
        if (hoveredBeforeLoaded.current) {
          hoveredBeforeLoaded.current = false;
          setActive(punicWarsStory.years[year].highlights.all);
        }
        setDynamic({
          year,
          territories,
          transforms: punicWarsStory.years[year].transform || [],
        });
        return;
      }
    }, 350);

    dataMaps
      .getTopo(punicWarsStory.years[year].territoriesUrl)
      .then((territoriesResponse) => {
        fetchedYears[year] = territoriesResponse;
        fetchDone = true;
        if (animationDone) {
          if (hoveredBeforeLoaded.current) {
            hoveredBeforeLoaded.current = false;
            setActive(punicWarsStory.years[year].highlights.all);
          }
          setDynamic({
            year,
            territories: territoriesResponse,
            transforms: punicWarsStory.years[year].transform || [],
          });
        } else {
          territories = territoriesResponse;
        }
      })
      .catch((err) => {
        console.error(err);
        // Todo, retry?
      });
  }, [year, setActive]);

  const onHover = useCallback(() => {
    const mq = window.matchMedia("(pointer: fine)");
    if (mq.matches) {
      const dynamicValues = punicWarsStory.years[dynamic?.year || -1];
      if (dynamicValues) {
        setActive(dynamicValues.highlights.all);
      } else {
        hoveredBeforeLoaded.current = true;
      }
    }
  }, [setActive, dynamic]);

  const offHover = useCallback(() => {
    hoveredBeforeLoaded.current = false;
    const mq = window.matchMedia("(pointer: fine)");
    if (mq.matches) {
      setActive(null);
    }
  }, [setActive]);

  useEffect(() => {
    if (dynamic) {
      window.setTimeout(() => {
        setLoading(false);
      }, 50);
    }
  }, [dynamic]);

  if (!year || !punicWarsStory.years[year]) {
    return (
      <Redirect
        to={getDynamicLink(ROUTE, {
          year:
            getUrlFromYear(Object.values(punicWarsStory.years)[0]?.year) ||
            "264ce",
        })}
      />
    );
  }

  const yearItem = punicWarsStory.years[dynamic?.year || -1] ?? {};

  return (
    <Page
      canonical={`https://interactive-history.app/the-punic-wars/${yearFromUrl}`}
      title="Interactive map of the Punic Wars"
      description="Interactive map showing the advances of Rome and Carthage in the Punic Wars"
      className={cx("the-punic-wars", {
        "is-loading": loading,
      })}
      mapSide={mapSide}
    >
      <Retry status={status} retry={retry}>
        <>
          <MobileHeader
            year={getFormattedTextFromYear(parseInt(year, 10)) || ""}
            title="The Punic Wars"
          />

          <div
            className="map-wrapper"
            onMouseEnter={onHover}
            onMouseLeave={offHover}
          >
            <Map
              sea={data && data[2]}
              image={data && data[3]}
              year={year}
              mapSide={mapSide}
              transforms={dynamic?.transforms}
              territories={dynamic?.territories}
              highlights={yearItem.highlights}
              boundaries={yearItem.boundaries}
              isActive={active}
            />
            <MapDesktopHint />
          </div>

          <div className="info-wrapper">
            <Info
              year={dynamic?.year || Object.keys(punicWarsStory.years)[0]}
              story={punicWarsStory}
            />
          </div>
        </>
      </Retry>
    </Page>
  );
};
