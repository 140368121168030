import { Boundary } from "../../data/dataMaps";

export function getBoundary(boundary: Boundary, projection: any) {
  if (!boundary) {
    return null;
  }

  if (!projection) {
    return null;
  }

  let data = "";

  boundary.bounds.forEach((pointOrType, idx) => {
    if (typeof pointOrType === "string") {
      if (idx === 0) {
        data = pointOrType;
      } else {
        data = data + " " + pointOrType;
      }
    } else {
      const coords = projection([pointOrType[1], pointOrType[0]]);
      const [x, y] = coords;
      data = `${data} ${x} ${y}`;
    }
  });

  return data;
}
