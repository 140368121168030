import React, { useCallback, useEffect, useRef, useState } from "react";
import cx from "classnames";
import { Copy as Icon } from "../Icons/Copy";
import { Status } from "../Lazy/Retry";
import "./Copy.scss";

interface Props {
  className?: string;
}

export const Copy: React.FunctionComponent<Props> = ({ className }) => {
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const mounted = useRef<boolean>(true);
  const timer = useRef<number>();

  useEffect(() => {
    return () => {
      if (mounted.current) {
        mounted.current = false;
      }
    };
  }, []);

  const copy = useCallback(() => {
    window.clearTimeout(timer.current);
    navigator.clipboard
      .writeText(window.location.toString())
      .then(() => {
        setStatus(Status.SUCCESS);

        timer.current = window.setTimeout(() => {
          if (mounted.current) {
            setStatus(Status.PENDING);
          }
        }, 2000);
      })
      .catch((err) => {
        setStatus(Status.ERROR);

        timer.current = window.setTimeout(() => {
          if (mounted.current) {
            setStatus(Status.PENDING);
          }
        }, 2000);
      });
  }, []);

  if (!(navigator.clipboard && navigator.clipboard.writeText)) {
    return null;
  }

  return (
    <div className={cx("copy-field", status)}>
      <button className="copy-field-text" onClick={copy}>
        https://interactive-history.app
        <div className="copy-field-icon">
          <Icon />
        </div>
      </button>

      <div className="copy-field-status copy-field-success">
        <b>Copied!</b> Thanks.
      </div>

      <div className="copy-field-status copy-field-error">
        <b>Ouch!</b> Couldn't copy.
      </div>
    </div>
  );
};
