import React from "react";

interface Props {
  timelineY: number;
  anchorY: number;
}

export const Anchor: React.FunctionComponent<Props> = ({
  timelineY,
  anchorY,
}) => (
  <g className="timeline-anchor">
    <path d={`M54.5 ${timelineY} L82 ${anchorY}`} className="anchor-path" />
    <circle cx="54.5" r="3" cy={timelineY} className="anchor-outer" />
    {/* <circle cx="54.5" r="2" cy={timelineY} className="anchor-inner" /> */}
    <circle cx="82" r="2" cy={anchorY} className="text-anchor" />
  </g>
);
