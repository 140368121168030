import React from "react";
import { BodyLink } from "../../HundredYearsWar/years/BodyLink";
import "../../../components/Overlay/Battles.scss";

export const Ilipa: React.FunctionComponent = () => (
  <div className="reigns-trasimene battle-overlay">
    <div className="battle-body">
      <h5>
        <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_Ilipa">
          Battle of Ilipa
        </BodyLink>
      </h5>
      <i className="battle-date">Spring, 206 BCE</i>
      <p>
        Carthaginian generals{" "}
        <BodyLink link="https://en.wikipedia.org/wiki/Mago_Barca">
          Mago Barca
        </BodyLink>{" "}
        (Hannibals brother) and{" "}
        <BodyLink link="https://en.wikipedia.org/wiki/Hasdrubal_Gisco">
          Hasdrubal Gisco
        </BodyLink>{" "}
        joined forces and met Scipio at Ilipa.{" "}
        <BodyLink link="https://en.wikipedia.org/wiki/Polybius">
          Polybius
        </BodyLink>{" "}
        says Carthage had about 74,000 troops to Rome's 48,000.{" "}
        <BodyLink link="https://en.wikipedia.org/wiki/Livy">Livy</BodyLink> says
        the sides had almost equal numbers, about 55,000 each.
      </p>
      <p>
        For a few days, nothing happened. The Carthaginians would always march
        out into battle formation and Scipio would follow, traditionally placing
        his legions in the center with the Iberian auxiliaries on the wings.
      </p>
      <p>
        On the fourth day at dawn, Scipio attacked the Carthaginian outposts
        with light troops and cavalry. Gisco was caught off guard. His troops
        were ordered into battle formation without breakfast. Meanwhile Scipio
        had rearranged his formation so his weaker Iberian detachments were in
        the center and his strong legions were split and placed on the wings.
        Also, Scipio had advanced his troops so that Gisco could not rearrange
        his side accordingly. Scipio now waited. His own troops were fed and
        prepared. The Carthaginians were not.
      </p>
      <p>
        After a couple of hours, Scipio ordered his army forwards. Both Scipio's
        wings were ordered into march column. A column formation can advance
        faster than a line formation, but is more vunerable. Once reaching the
        enemy, Scipio's wings again reformed into a line and flanked the
        opponent. The strong Carthaginian center were not engaged but held in
        place by the Romans weaker auxiliaries and could do little while their
        wings were destroyed.
      </p>
      <p>
        Ca 48,500 Carthaginians were either killed or captured compared to
        Rome's 7,000 killed. The defeat was devestating to the Carthaginian
        cause in the Iberian peninsula. At Ilipa, Scipio combined the strengths
        of his diciplined army with careful planning and innovation.
      </p>
    </div>
  </div>
);
