import React, { ReactNode } from "react";
import "./MapLink.scss";

interface Props {
  id: string;
  onMouseEnter: (event: React.MouseEvent<HTMLSpanElement>) => void;
  onMouseLeave: () => void;
  children: ReactNode;
}

export const MapLink: React.FunctionComponent<Props> = ({
  id,
  onMouseEnter,
  onMouseLeave,
  children,
}) => {
  return (
    <span
      className="map-link"
      data-id={id}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <span>{children}</span>

      <i className="arrow">&rarr;</i>
    </span>
  );
};
