import React from "react";
import cx from "classnames";
import "./TimelineListItem.scss";

interface Props {
  link: string;
  id?: string;
  number: string;
  name: string;
  date?: string;
  onHover?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  offHover?: () => void;
}

export const TimelineListItem: React.FunctionComponent<Props> = ({
  onHover = () => {},
  offHover = () => {},
  link,
  name,
  id,
  number,
}) => {
  const props: { [key: string]: any } = {};

  if (id) {
    props["data-id"] = id;
    props["onMouseEnter"] = onHover;
    props["onMouseLeave"] = offHover;
  }

  return (
    <li
      className={cx("timeline-list-item", {
        "is-map-ref": !!id,
      })}
    >
      <a href={link} target="_blank" rel="noreferrer" {...props}>
        <div className="list-item-icon">{number}</div>
        <div className="list-item-name">
          <div className="ellipsis">{name}</div>
          <i>&rarr;</i>
        </div>
      </a>
    </li>
  );
};
