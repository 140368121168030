import React, { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { mapIconInfoState } from "../../state/mapState";
import { Button } from "../Buttons/Button";
import { Store, STORE_KEY } from "../../Store";
import { AnimateHeight } from "../AnimateHeight";
import { External } from "../Icons/External";
import { Crown } from "../Icons/Crown";
import { Photo } from "../Icons/Photo";
import "./IconInfo.scss";

interface Props {}

const IconInfoInner: React.FunctionComponent<Props> = () => {
  const [hasAcknowledged, setHasAcknowledged] = useState<boolean>(false);
  const setHasShownIconInfo = useSetRecoilState(mapIconInfoState);
  const unMounted = useRef<boolean>(false);
  const onClick = useCallback(() => {
    setHasAcknowledged(true);
    Store.setValue(STORE_KEY.STORE_ICON_INFO_HINT_SHOWN, new Date());
    window.setTimeout(() => {
      if (unMounted.current) {
        return;
      }
      setHasShownIconInfo(new Date());
    }, 1000);
  }, [setHasShownIconInfo]);

  useEffect(() => {
    return () => {
      unMounted.current = true;
    };
  }, []);

  return (
    <div className="icon-info-wrapper">
      <AnimateHeight
        name={hasAcknowledged ? "has-acknowledged" : "should-acknowledged"}
      >
        {hasAcknowledged ? null : (
          <div className="icon-info">
            <h5>Iconography</h5>
            <ul>
              <li>
                <External />

                <div className="link-legend">External link</div>
              </li>
              <li>
                <span>&rarr;</span>

                <div className="link-legend">
                  Site/region represented in the map
                </div>
              </li>
              <li>
                <span>
                  <Crown />
                </span>
                <div className="link-legend">
                  List or info of regents/rulers
                </div>
              </li>
              <li>
                <span>
                  <Photo />
                </span>
                <div className="link-legend">Images/photos of an event</div>
              </li>
              <li>
                <span>
                  <i>
                    <b>i</b>
                  </i>
                </span>
                <div className="link-legend">Additional info</div>
              </li>
            </ul>
            <div className="icon-info-button">
              <Button onClick={onClick}>
                <span data-id="got-it">Got it!</span>
              </Button>
            </div>
          </div>
        )}
      </AnimateHeight>
    </div>
  );
};

export const IconInfo: React.FunctionComponent<Props> = () => {
  const hasShownTooltip = !!useRecoilValue(mapIconInfoState);
  if (hasShownTooltip) {
    return null;
  }

  return <IconInfoInner />;
};
