import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import cx from "classnames";
import { useRecoilState, useSetRecoilState } from "recoil";
import { backdropState, overlayState } from "../../state/uiState";
import { Action } from "../Buttons/Action";
import { Close } from "../Icons/Close";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import "./Overlay.scss";

const ESC = 27;

export const Overlay: React.FunctionComponent = () => {
  const setShowBackrop = useSetRecoilState(backdropState);
  const [overlay, setOverlay] = useRecoilState(overlayState);
  const [body, setBody] = useState<ReactNode>(overlay);
  const dialogRef = useRef<HTMLDialogElement>(null);

  const onTransitionEnd = useCallback(
    (ev: TransitionEvent) => {
      if ((ev.target as HTMLElement).classList.contains("overlay")) {
        if (ev.propertyName !== "opacity") {
          return;
        }
        if (!overlay) {
          setBody(null);
        }
      }
    },
    [overlay]
  );

  useEffect(() => {
    if (!dialogRef.current) {
      return;
    }
    const ref = dialogRef.current;
    ref.addEventListener("transitionend", onTransitionEnd);

    return () => {
      ref.removeEventListener("transitionend", onTransitionEnd);
    };
  }, [onTransitionEnd]);

  useEffect(() => {
    if (overlay) {
      setShowBackrop(true);
    } else {
      setShowBackrop(false);
    }
  }, [overlay, setShowBackrop]);

  const handler = useCallback(
    (ev) => {
      if (ev.keyCode === ESC) {
        setOverlay(null);
      }
    },
    [setOverlay]
  );

  useEffect(() => {
    window.addEventListener("keydown", handler, false);
    return () => {
      window.removeEventListener("keydown", handler, false);
    };
  }, [handler]);

  const onClose = useCallback(
    (ev) => {
      if ((ev.target as HTMLDialogElement).classList.contains("overlay")) {
        setOverlay(null);
      }
    },
    [setOverlay]
  );

  const onActionClose = useCallback(() => setOverlay(null), [setOverlay]);

  useEffect(() => {
    if (overlay) {
      if (dialogRef.current) {
        disableBodyScroll(dialogRef.current);
      }
      setBody(overlay);
    } else {
      clearAllBodyScrollLocks();
    }
  }, [overlay]);

  const classes = cx("overlay", {
    active: !!overlay,
  });

  return (
    <dialog className={classes} onClick={onClose} ref={dialogRef}>
      <div className="relative">
        {body && (
          <Action onClick={onActionClose} className="overlay-close">
            <Close data-id="close" />
          </Action>
        )}
        <div className="overlay-body">{body}</div>
      </div>
    </dialog>
  );
};
