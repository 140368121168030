import React from "react";
import { InfoLink } from "../../../components/Links/InfoLink";
import { KingLink } from "../../../components/Links/KingLink";
import {
  AudioType,
  InterestType,
  TooltipDirection,
} from "../../../data/dataMaps";
import { BodyLink } from "../../HundredYearsWar/years/BodyLink";
import { MapLink } from "../../HundredYearsWar/years/MapLink";
import { Hannibal } from "../battles/Hannibal";
import { Trebia } from "../battles/Trebia";

interface BodyProps {
  onHover?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  offHover?: () => void;
}

export const Year218: React.FunctionComponent<BodyProps> = ({
  onHover = () => {},
  offHover = () => {},
}) => (
  <>
    <p>
      After the First Punic war the Carthaginian general{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Hamilcar_Barca">
        Hamilcar Barca
      </BodyLink>{" "}
      left for the{" "}
      <MapLink id="iberia" onMouseEnter={onHover} onMouseLeave={offHover}>
        Iberian peninsula
      </MapLink>
      . There Hamilcar restored and expanded Carthaginian authority.{" "}
      {/** Conquests were
      made in Carthage's name but in reality the peninsula was now a
      semi-independent state of the Barcids, outside of the senate's reach. */}
      Hamilcar died in 228 BCE and was succeeded by his son-in-law{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Hasdrubal_the_Fair">
        Hasdrubal the Fair
      </BodyLink>
      . Hasdrubal, founded{" "}
      <MapLink id="nova" onMouseEnter={onHover} onMouseLeave={offHover}>
        Carthage Nova
      </MapLink>
      , modern day Cartagena. He was assassinated in 221 BCE.
    </p>
    <p>
      Hamilcar's eldest son <KingLink node={<Hannibal />}>Hannibal</KingLink>,
      now 26 years old, had the respect of the Carthaginian forces and was
      elevated to commander.
    </p>
    <p>
      The terms of the{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Ebro_Treaty">
        Ebro treaty
      </BodyLink>{" "}
      are unclear. In the Carthaginian interpretation, Rome had acknowledged
      everything south of{" "}
      <MapLink id="ebro" onMouseEnter={onHover} onMouseLeave={offHover}>
        the Ebro
      </MapLink>{" "}
      as part of the Carthaginian sphere of influence. The city of{" "}
      <MapLink id="saguntum" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Siege_of_Saguntum">
          Saguntum
        </BodyLink>
      </MapLink>
      , however, had an informal relationship with Rome, so when Hannibal laid
      siege to Saguntum, Rome protested. When Saguntum fell, Rome declared war.
      The Second Punic War (218 BCE – 201 BCE) had begun.
    </p>
    <p>
      <MapLink id="route" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Hannibal%27s_crossing_of_the_Alps">
          Hannibals march
        </BodyLink>
      </MapLink>{" "}
      over the alps with his African war elephants is one of the most famous
      events in world history.{" "}
      <BodyLink link="https://www.reddit.com/r/AskHistorians/comments/1t69ts/why_did_hannibal_cross_the_alps_instead_of_taking/">
        Without a fleet
      </BodyLink>
      , the long hard road through the alpine passes to Rome was the only way.
      Many men deserted, others were killed by hostile tribes or the harsh
      terrain. But in November 218 BCE Hannibal had reached the Po valley and
      skirmished with Roman forces at{" "}
      <MapLink id="ticinus" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_Ticinus">
          Ticinus
        </BodyLink>
      </MapLink>
      .
    </p>
    <p>
      Rome had presumed the war was to be fought in foreign territory. Hannibal
      suddenly turning up this side of the Alps was unexpected. At{" "}
      <InfoLink node={<Trebia />}>
        <MapLink id="trebia" onMouseEnter={onHover} onMouseLeave={offHover}>
          Trebia
        </MapLink>
      </InfoLink>{" "}
      in late December the same year, Hannibal delt the Romans a heavy defeat.
      The Gallic tribes in the Po valley had recently been conquered by the
      Romans. They now flocked to Hannibals banner.
    </p>
  </>
);

export const Highlights218 = {
  ebro: {
    coords: [-1.4376953191112015, 42.128526445646344],
    type: InterestType.AREA,
    id: "ebro",
    info: "Ebro",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: ["ebro"],
    mapIds: ["ebro"],
  },
  rome: {
    coords: [12.502017074584536, 41.88767751335361],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Roman_Republic",
    id: "rome",
    info: "Rome",
    direction: TooltipDirection.ON_TOP,
    mapAreas: [
      "rome",
      "rome_po",
      "rome_sicily",
      "rome_corsica",
      "rome_sardinia",
    ],
    mapIds: ["rome"],
  },
  nova: {
    coords: [-0.983333, 37.6],
    type: InterestType.POINT,
    id: "nova",
    info: "Carthago Nova",
    direction: TooltipDirection.ON_BOTTOM,
    mapAreas: [],
    mapIds: ["nova"],
  },
  iberia: {
    coords: [-3, 39],
    type: InterestType.AREA,
    id: "iberia",
    info: "Iberia",
    direction: TooltipDirection.ON_LEFT,
    mapAreas: ["carthage_iberia", "carthage_ebro"],
    mapIds: ["iberia"],
  },
  carthage: {
    coords: [10.3233, 36.8528],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Carthage",
    id: "carthage",
    info: "Carthage",
    direction: TooltipDirection.ON_BOTTOM,
    mapIds: ["carthage"],
    mapAreas: [
      "carthage",
      "carthage_corsica",
      "carthage_iberia",
      "carthage_mallorca",
      "carthage_sardinia",
      "carthage_sicily",
      "carthage_egypt",
      "carthage_africa",
      "carthage_malta",
      "carthage_ebro",
    ],
  },
  syracuse: {
    coords: [15.2875, 37.069167],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Syracuse,_Sicily",
    id: "syracuse",
    info: "Syracuse",
    direction: TooltipDirection.ON_LEFT,
    mapAreas: ["syracuse"],
    mapIds: ["syracuse"],
  },
  saguntum: {
    coords: [-0.28, 39.677],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Siege_of_Saguntum",
    id: "saguntum",
    info: "Saguntum",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: [],
    mapIds: ["saguntum"],
  },
  ticinus: {
    coords: [8.866667, 45.25],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Battle_of_Ticinus",
    id: "ticinus",
    info: "Ticinus",
    direction: TooltipDirection.ON_TOP,
    mapAreas: [],
    mapIds: ["ticinus"],
  },
  trebia: {
    coords: [9.6, 45.05],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Battle_of_the_Trebia",
    id: "trebia",
    info: "Trebia",
    direction: TooltipDirection.ON_BOTTOM,
    mapAreas: [],
    mapIds: ["trebia"],
  },
  route: {
    coords: [2, 44],
    type: InterestType.POINT,
    id: "route",
    info: "route",
    direction: TooltipDirection.ON_LEFT,
    mapAreas: ["hannibal_girona", "hannibal_ticinus"],
    mapIds: ["saguntum", "ticinus"],
  },
  all: {
    id: "all",
    type: InterestType.ALL,
    mapAreas: ["ebro", "hannibal_girona", "hannibal_ticinus"],
    mapIds: ["ebro", "iberia", "nova", "saguntum", "ticinus", "trebia"],
  },
};

export const Audio218 = [
  {
    type: AudioType.SPOTIFY,
    src: "https://open.spotify.com/embed/playlist/64zRVrVkHqKoueMWBcPNDe",
    title: "History of Rome - Second Punic War",
  },
];

export const Sources218 = [
  {
    link: "https://en.wikipedia.org/wiki/Second_Punic_War",
    label: "Wikipedia, The Second Punic War",
  },
  {
    link: "https://www.britannica.com/event/Second-Punic-War",
    label: "Britannica, Second Punic War",
  },
  {
    link: "https://www.worldhistory.org/Second_Punic_War/",
    label: "World History Encyclopedia",
  },
  {
    link: "https://en.wikipedia.org/wiki/Illyrian_Wars",
    label: "Wikipedia, The Illyrian Wars",
  },
  {
    link:
      "https://www.deviantart.com/cyowari/art/Before-the-Second-Punic-War-220-BC-814008710",
    label: "Cyowari, 220 BCE (map)",
  },
  {
    link:
      "https://www.deviantart.com/cyowari/art/Second-Punic-War-Part-I-219-216-BC-814124574",
    label: "Cyowari, 219 - 216 BCE (map)",
  },
];

export const Legends218 = [
  {
    party: "rome",
    label: "Roman Republic",
    mapId: "rome",
  },
  {
    party: "carthage",
    label: "Republic of Carthage",
    mapId: "carthage",
  },
  {
    party: "syracuse",
    label: "Syracuse",
    mapId: "syracuse",
  },
  {
    party: "route",
    label: "Hannibals march",
    mapId: "route",
  },
];
