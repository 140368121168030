import React, { ReactNode } from "react";
import { Crown } from "../Icons/Crown";
import { IconLink } from "./IconLink";

interface Props {
  node: ReactNode;
  children: ReactNode;
}

export const KingLink: React.FunctionComponent<Props> = ({
  node,
  children,
}) => (
  <IconLink node={node} icon={<Crown />}>
    {children}
  </IconLink>
);
