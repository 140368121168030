import React from "react";
import { KingLink } from "../../../components/Links/KingLink";
import {
  AudioType,
  InterestType,
  TooltipDirection,
} from "../../../data/dataMaps";
import { BodyLink } from "../../HundredYearsWar/years/BodyLink";
import { MapLink } from "../../HundredYearsWar/years/MapLink";
import { Hannibal } from "../battles/Hannibal";
import { Scipio } from "../battles/Scipio";

interface BodyProps {
  onHover?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  offHover?: () => void;
}

export const Year146: React.FunctionComponent<BodyProps> = ({
  onHover = () => {},
  offHover = () => {},
}) => (
  <>
    <p>
      The Second Punic War had been a struggle. Yet, the Romans would not rest,
      and instead went on to conquer the states and kingdoms of Greece in the{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Macedonian_Wars">
        Macedonian Wars
      </BodyLink>
      .
    </p>
    <p>
      By the peace treaty of the Second Punic War, Carthage was forbidden to
      wage war without Rome's consent.{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Masinissa">
        Masinissa
      </BodyLink>{" "}
      of{" "}
      <MapLink id="numidia" onMouseEnter={onHover} onMouseLeave={offHover}>
        Numidia
      </MapLink>{" "}
      was Rome's ally and had fought with Scipio at Zama. Masinissa exploited
      the terms of the treaty and repeatedly seized Carthaginian territory.
      When, finally, Carthage raised an army in defense, the pro war faction of
      the Roman senate, e.g.{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Cato_the_Elder">
        Cato the Elder,
      </BodyLink>{" "}
      had their war.
    </p>
    <p>
      The{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Third_Punic_War">
        Third Punic War
      </BodyLink>{" "}
      (149 BCE - 146 BCE) was a punitive expedition in order to eradicate
      Carthage. The Carthagians fought on bravely. It would take the Roman
      Republic four years before victory could be declared. But in the end,
      Carthage was destroyed.
    </p>

    <p>
      It is, perhaps, inevtable to compare{" "}
      <KingLink node={<Hannibal />}>Hannibal</KingLink> and{" "}
      <KingLink node={<Scipio />}>Scipio</KingLink> - they fought in the same
      war at opposing sides. They where both brilliant commanders who respected
      one another. They were worthy opponents. But for now, we'll leave them be.
    </p>
  </>
);

export const Highlights146 = {
  rome: {
    coords: [12.502017074584536, 41.88767751335361],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Roman_Republic",
    id: "rome",
    info: "Rome",
    direction: TooltipDirection.ON_TOP,
    mapAreas: [
      "rome",
      "rome_po",
      "rome_sicily",
      "rome_corsica",
      "rome_sardinia",
      "rome_malta",
      "rome_islands",
      "rome_ebro_upper",
      "rome_ebro_lower",
      "rome_macedon",
      "rome_iberia",
    ],
    mapIds: ["rome"],
  },
  numidia: {
    coords: [3.5, 35.49977525331348],
    type: InterestType.AREA,
    id: "numidia",
    info: "Numidia",
    direction: TooltipDirection.ON_TOP,
    mapAreas: [],
    mapIds: ["numidia"],
  },
  all: {
    id: "all",
    type: InterestType.ALL,
    mapAreas: [],
    mapIds: ["rome", "numidia"],
  },
};

export const Audio146 = [
  {
    type: AudioType.SPOTIFY,
    src: "https://open.spotify.com/embed/playlist/47qx4bTzP5ecpltlkawsCA",
    title: "History of Rome - Third Punic War",
  },
];

export const Sources146 = [
  {
    link: "https://en.wikipedia.org/wiki/Third_Punic_War",
    label: "Wikipedia, Third Punic War",
  },
  {
    link: "https://www.youtube.com/watch?v=w5zYpWcz1-E&t=126s",
    label: "History of the Romans (video) ",
  },
  {
    link:
      "https://www.deviantart.com/cyowari/art/Before-the-Third-Punic-War-150-BC-816496591",
    label: "Cyowari, 150 BCE (map)",
  },
  {
    link:
      "https://www.deviantart.com/cyowari/art/Third-Punic-War-149-146-BC-816496672",
    label: "Cyowari, 149 - 146 BCE (map)",
  },
  {
    link:
      "https://www.deviantart.com/cyowari/art/After-the-Third-Punic-War-145-BC-816496734",
    label: "Cyowari, 145 BCE (map)",
  },
];

export const Legends146 = [
  {
    party: "rome",
    label: "Roman Republic",
    mapId: "rome",
  },
];
