import React from "react";
import cx from "classnames";
import "./Icon.scss";

interface Props {
  className?: string;
}

export const ArrowUp: React.FunctionComponent<Props> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={cx("icon", "arrow-up", className)}
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z" />
  </svg>
);
