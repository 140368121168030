import React from "react";
import { Image } from "../../components/Images/Image";
import "./Reigns.scss";

export const FrenchReigns: React.FunctionComponent = () => (
  <div className="french-reigns">
    <Image src="/images/joa_700x390.jpg" ratio={700 / 390} alt="Joan of Arc" />
    <div className="reigns-image-attributes">
      <i>Joan of Arc enters Orléans</i>
      <span>Jean-Jacques Scherrer</span>
    </div>

    <div className="reigns-names">
      <svg viewBox="0 0 300 250">
        <defs>
          <linearGradient
            id="francereignstoright"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="0%"
          >
            <stop offset="0%" stopColor="#72757b" stopOpacity="1" />
            <stop offset="100%" stopColor="#72757b" stopOpacity="0" />
          </linearGradient>

          <linearGradient
            id="francereignstoleft"
            x1="100%"
            y1="0%"
            x2="0%"
            y2="0%"
          >
            <stop offset="0%" stopColor="#72757b" stopOpacity="1" />
            <stop offset="100%" stopColor="#72757b" stopOpacity="0" />
          </linearGradient>

          <linearGradient
            id="francereignstobottom"
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            <stop offset="0%" stopColor="#72757b" stopOpacity="1" />
            <stop offset="100%" stopColor="#72757b" stopOpacity="0" />
          </linearGradient>

          <linearGradient
            id="francereignstotop"
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            <stop offset="0%" stopColor="#72757b" stopOpacity="0" />
            <stop offset="100%" stopColor="#72757b" stopOpacity="1" />
          </linearGradient>
        </defs>

        <line x1="0" x2="300" y1="50" y2="50" className="dotted" />
        <rect
          x="0"
          y="44"
          width="92"
          height="250"
          className="burgundians-bg"
          rx="5"
        />

        <text className="burgundians-text" x="47" y="58">
          Burgundy
        </text>

        <text className="reign-time-legend" x="2" y="247">
          Reign in parentheses
        </text>

        <text className="reign-time-label" x="300" y="58">
          1350
        </text>
        <line x1="0" x2="300" y1="100" y2="100" className="dotted" />
        <text className="reign-time-label" x="300" y="158">
          1400
        </text>
        <line x1="0" x2="300" y1="150" y2="150" className="dotted" />
        <line x1="0" x2="300" y1="200" y2="200" className="dotted" />
        <text className="reign-time-label" x="300" y="258">
          1450
        </text>
        <line x1="0" x2="300" y1="250" y2="250" className="dotted" />

        <rect
          x="80"
          y="8"
          width="60"
          height="16"
          rx="3"
          className="reigns-link-bg"
        />

        <a
          href="https://en.wikipedia.org/wiki/Philip_VI_of_France"
          target="_blank"
          rel="noreferrer"
        >
          <text x="110" y="20">
            Philip VI
          </text>
        </a>
        <text x="110" y="34">
          <tspan>(1328 - 1350)</tspan>
        </text>

        <rect
          x="166"
          y="28"
          width="48"
          height="16"
          rx="3"
          className="reigns-link-bg"
        />

        <a
          href="https://en.wikipedia.org/wiki/John_II_of_France"
          target="_blank"
          rel="noreferrer"
        >
          <text x="190" y="40">
            John II
          </text>
        </a>
        <text x="190" y="54">
          <tspan>(1350 - 1364)</tspan>
        </text>

        <rect
          x="149.5"
          y="0"
          width="1"
          height="78"
          fill="url(#francereignstotop)"
        />

        <circle cx="150" cy="50" r="2" />
        <path d="M100 78 L200 78" />

        <circle cx="100" cy="78" r="2" />

        <rect
          x="-3"
          y="69"
          width="98"
          height="16"
          rx="3"
          className="reigns-link-bg"
        />

        <a
          href="https://en.wikipedia.org/wiki/Philip_the_Bold"
          target="_blank"
          rel="noreferrer"
        >
          <text x="45" y="81">
            Philip the Bold
          </text>
        </a>
        <text x="45" y="94">
          <tspan>(1363 - 1404)</tspan>
        </text>

        <path d="M100 78 L100 158" />

        <circle cx="100" cy="158" r="2" />
        <rect
          x="100"
          y="157.5"
          width="20"
          height="1"
          fill="url(#francereignstoright)"
        />

        <rect
          x="-3"
          y="147"
          width="98"
          height="16"
          rx="3"
          className="reigns-link-bg"
        />

        <a
          href="https://en.wikipedia.org/wiki/John_the_Fearless"
          target="_blank"
          rel="noreferrer"
        >
          <text x="45" y="159">
            John the Fearless
          </text>
        </a>
        <text x="45" y="173">
          <tspan>(1404 - 1419)</tspan>
        </text>

        <path d="M100 158 L100 188" />
        <circle cx="100" cy="188" r="2" />

        <rect
          x="100"
          y="187.5"
          width="20"
          height="1"
          fill="url(#francereignstoright)"
        />

        <rect
          x="-3"
          y="183"
          width="98"
          height="16"
          rx="3"
          className="reigns-link-bg"
        />

        <a
          href="https://en.wikipedia.org/wiki/Philip_the_Good"
          target="_blank"
          rel="noreferrer"
        >
          <text x="45" y="195">
            Philip the Good
          </text>
        </a>
        <text x="45" y="209">
          <tspan>(1419 - 1467)</tspan>
        </text>

        <rect
          x="99.5"
          y="188"
          width="1"
          height="62"
          fill="url(#francereignstobottom)"
        />

        <circle cx="200" cy="78" r="2" />

        <rect
          x="200"
          y="77.5"
          width="100"
          height="1"
          fill="url(#francereignstoright)"
        />

        <rect
          x="210"
          y="69"
          width="66"
          height="16"
          rx="3"
          className="reigns-link-bg"
        />

        <a
          href="https://en.wikipedia.org/wiki/Charles_V_of_France"
          target="_blank"
          rel="noreferrer"
        >
          <text x="243" y="81">
            Charles V
          </text>
        </a>
        <text x="243" y="95">
          <tspan>(1364 - 1380)</tspan>
        </text>

        <path d="M200 78 L200 110" />
        <circle cx="200" cy="110" r="2" />

        <path d="M200 110 L170 110" />
        <circle cx="170" cy="110" r="2" />

        <rect
          x="148"
          y="88"
          width="45"
          height="16"
          rx="3"
          className="reigns-link-bg"
        />

        <a
          href="https://en.wikipedia.org/wiki/Louis_I,_Duke_of_Orl%C3%A9ans"
          target="_blank"
          rel="noreferrer"
        >
          <text x="170" y="100">
            Louis I
          </text>
        </a>

        <rect
          x="150"
          y="109.5"
          width="20"
          height="1"
          fill="url(#francereignstoleft)"
        />

        <rect
          x="210"
          y="104"
          width="66"
          height="16"
          rx="3"
          className="reigns-link-bg"
        />

        <a
          href="https://en.wikipedia.org/wiki/Charles_VI_of_France"
          target="_blank"
          rel="noreferrer"
        >
          <text x="243" y="116">
            Charles VI
          </text>
        </a>
        <text x="243" y="130">
          <tspan>(1380 - 1422)</tspan>
        </text>

        <path d="M200 110 L200 194" />
        <circle cx="200" cy="194" r="2" />

        <rect
          x="180"
          y="193.5"
          width="20"
          height="1"
          fill="url(#francereignstoleft)"
        />

        <rect
          x="210"
          y="186"
          width="66"
          height="16"
          rx="3"
          className="reigns-link-bg"
        />

        <a
          href="https://en.wikipedia.org/wiki/Charles_VII_of_France"
          target="_blank"
          rel="noreferrer"
        >
          <text x="243" y="198">
            Charles VII
          </text>
        </a>
        <text x="243" y="212">
          <tspan>(1422 - 1461)</tspan>
        </text>

        <rect
          x="199.5"
          y="194"
          width="1"
          height="56"
          fill="url(#francereignstobottom)"
        />
      </svg>
    </div>
  </div>
);
