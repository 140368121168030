import { atom } from "recoil";
import { AreasOfInterest, MapSide, PointOfInterest } from "../data/dataMaps";
import { Store, STORE_KEY } from "../Store";
// import { Store, STORE_KEY } from "../Store";

export const mapActiveState = atom<null | PointOfInterest | AreasOfInterest>({
  key: "mapActiveState",
  default: null,
});

export const mapShowMouseHintState = atom<Date | null>({
  key: "mapShowMouseHintState",
  default: null,
  // default: Store.getValue(STORE_KEY.STORE_GRAPH_HINT_SHOWN),
});

export const mapSideState = atom<MapSide>({
  key: "mapSideState",
  default: MapSide.NONE,
});

export const mapIconInfoState = atom<Date | null>({
  key: "iconInfoState",
  default: Store.getValue(STORE_KEY.STORE_ICON_INFO_HINT_SHOWN),
});
