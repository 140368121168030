import React, { ReactElement, ReactNode } from "react";
import cx from "classnames";
import "./Button.scss";

const DURATION = 500;

function inOutQuad(n: number) {
  n *= 2;
  if (n < 1) {
    return 0.5 * n * n;
  }
  return -0.5 * (--n * (n - 2) - 1);
}

interface Props {
  children: React.ReactNode;
  className?: string;
  data?: any;
  onClick: (data: any) => void;
  block?: boolean;
  attrs?: { [k: string]: string };
  action?: boolean;
}

interface State {
  child: React.ReactNode;
  flip: string | undefined;
}

function getKey(node: ReactNode) {
  if (React.isValidElement(node)) {
    const propChild = React.Children.only(node);
    return (propChild as ReactElement).props["data-id"];
  }
  return node;
}

export class Button extends React.PureComponent<Props, State> {
  isAnimating: boolean = false;
  aborted: boolean = false;
  button = React.createRef<HTMLButtonElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      child: props.children,
      flip: undefined,
    };
  }

  componentWillUnmount() {
    this.aborted = true;
  }

  componentDidUpdate() {
    if (this.isAnimating) {
      return;
    }

    const propId = getKey(this.props.children);
    const stateId = getKey(this.state.child);

    if (propId === stateId) {
      return;
    }

    this.isAnimating = true;
    this.flip();
  }

  animate = () => {
    const self = this;

    return new Promise<void>((resolve, reject) => {
      const startRotate = 0;
      const endRotate = 180;
      let start = 0;
      let end = 0;
      let stop = false;
      let diff = 0;
      let timeOffset = 0;
      let rotate = 0;
      let hasRotated = false;

      function draw(now: number) {
        if (self.aborted) {
          reject();
          return;
        }

        if (stop) {
          self.setState(
            {
              flip: self.props.action ? "rotateY(0deg)" : "rotateX(0deg)",
            },
            resolve
          );
          return;
        }

        if (now >= end) {
          stop = true;
        }

        diff = now - start;
        timeOffset = inOutQuad(diff / DURATION);
        rotate = startRotate + (endRotate - startRotate) * timeOffset;
        const isAction = self.props.action;

        if (hasRotated) {
          self.setState({
            flip: `rotate${isAction ? "Y" : "X"}(${180 - rotate}deg)`,
          });
        } else if (rotate > 90) {
          hasRotated = true;
          self.setState({
            child: self.props.children,
            flip: `rotate${isAction ? "Y" : "X"}(${180 - rotate}deg)`,
          });
        } else {
          self.setState({
            flip: `rotate${isAction ? "Y" : "X"}(${rotate}deg)`,
          });
        }

        requestAnimationFrame(draw);
      }

      function startAnim(timeStamp: number) {
        start = timeStamp;
        end = start + DURATION;
        draw(timeStamp);
      }

      requestAnimationFrame(startAnim);
    });
  };

  flip = () => {
    this.animate()
      .then(() => {
        const propId = getKey(this.props.children);
        const stateId = getKey(this.state.child);
        if (propId === stateId) {
          this.isAnimating = false;
        } else {
          this.flip();
        }
      })
      .catch(() => {});
  };

  onClick = () => {
    if (this.isAnimating) {
      return;
    }

    this.isAnimating = true;
    this.props.onClick(this.props.data);
    this.flip();
  };

  render() {
    const { className, block, attrs, action } = this.props;

    let surfaceClasses;
    if (React.isValidElement(this.state.child)) {
      surfaceClasses = this.state.child.props.className;
    } else if (!this.state.child) {
      surfaceClasses = "empty";
    }

    const classes = cx("button", className, surfaceClasses, {
      action,
      block,
    });

    return (
      <button
        className={classes}
        onClick={this.onClick}
        {...attrs}
        ref={this.button}
        style={{
          transform: this.state.flip,
        }}
      >
        <div className="button-surface">{this.state.child}</div>
      </button>
    );
  }
}
