import React from "react";
import "./Logo.scss";

export function Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="logo"
      viewBox="0 0 60 70"
    >
      <path className="cube-bg" d="M30 2 L58 20 L58 50 L30 68 L2 50 L2 20 Z" />
      <path className="cube-left" d="M2 20 L30 37 L30 68 L2 50 Z" />
      <path className="cube-right" d="M58 20 L30 37 L30 68 L58 50 Z" />

      <path
        d="M30 2 L58 20 L58 50 L30 68 L2 50 L2 20 Z"
        className="logo-border"
      />
    </svg>
  );
}
