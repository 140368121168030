import { ReactElement, ReactNode } from "react";
import { API } from "../network/API";

export enum Party {
  ENGLAND = "england",
  ENGLAND_EXP = "england-exp",
  FRANCE = "france",
  SCOTLAND = "scotland",
  AVIGNON = "papacy",
  BURGUNDY_ALLY = "ally-burgundy",
  BURGUNDY_INDEPENDENT = "burgundy",
}

export enum MapSide {
  RIGHT = "map-on-right",
  LEFT = "map-on-left",
  NONE = "",
}

export enum InterestType {
  POINT = "point",
  AREA = "area",
  ALL = "all",
}

export enum TooltipDirection {
  ON_TOP = "on-top",
  ON_LEFT = "on-left",
  ON_RIGHT = "on-right",
  ON_BOTTOM = "on-bottom",
}

export interface Source {
  link: string;
  label: string;
}

export enum AudioType {
  SPOTIFY,
  DOWNLOAD,
}

export interface Audio {
  src: string;
  title: string;
  type: AudioType;
}

export interface Legend {
  label: string | ReactNode;
  party: string;
  tabletOnly?: boolean;
  mapId?: string;
}

export interface YearMap {
  [key: string]: ReactElement;
}

export interface Transform {
  scale: number;
  transformX: number;
  transformY: number;
}

export interface Year {
  [key: string]: {
    year: number;
    territoriesUrl: string;
    sources: Source[];
    highlights: {
      [key: string]: PointOfInterest | AreasOfInterest;
    };
    header: string | ReactNode;
    audio: Audio[];
    legends: Legend[];
    additional?: Additional[];
    boundaries?: Boundary[];
    scale?: number[];
    transform?: Transform[];
  };
}

export interface Story {
  dataUrls: string[];
  imageUrl: string;
  wideDeviceDataUrls: string[];
  wideDeviceImageUrl: string;
  years: Year;
}

export interface SimpleStory {
  dataUrl: string;
  imageUrl: string;
  years: Year;
}

export interface Boundary {
  name: string;
  bounds: (number[] | string)[];
}

export interface Additional {
  coords: number[][];
  className?: string;
}

export interface PointOfInterest {
  coords: number[];
  type: InterestType;
  link?: string;
  direction: TooltipDirection;
  id: string;
  info: string | ReactNode;
  mapAreas: string[];
  mapIds: string[];
  className?: string;
}

export interface AreasOfInterest {
  id: string;
  type: InterestType;
  mapAreas: string[];
  mapIds: string[];
}

export const dataMaps = {
  // getCountries: (str: string): (() => Promise<Country[]>) => {
  //   return () => API.get<Country[]>("/maps/countries?" + str);
  // },

  // getCountries: (): (() => Promise<Country[]>) => () =>
  //   API.get<Country[]>("/maps/countries"),

  // getTopo: (year: string) => API.fetch(`/maps/${year}_desktop_vv2.json`),

  getTopoPromise: (url: string): (() => Promise<any>) => () => API.fetch(url),

  getTopo: (url: string) => API.fetch(url),
};
