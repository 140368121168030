import React from "react";
import { KingLink } from "../../../components/Links/KingLink";
import { InterestType, TooltipDirection } from "../../../data/dataMaps";
import { BodyLink } from "../../HundredYearsWar/years/BodyLink";
import { MapLink } from "../../HundredYearsWar/years/MapLink";
import { Hannibal } from "../battles/Hannibal";
import { Scipio } from "../battles/Scipio";

interface BodyProps {
  onHover?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  offHover?: () => void;
}

export const Year264: React.FunctionComponent<BodyProps> = ({
  onHover = () => {},
  offHover = () => {},
}) => (
  <>
    <p>
      The word <i>Punic</i> is derived from the Latin word <i>Punicus</i> and
      refers to Carthage's Phoenician roots. The Punic Wars (264 BCE - 146 BCE)
      was a series of conflicts between the two greatest powers of the ancient
      Mediterranean,{" "}
      <MapLink id="rome" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Roman_Republic">
          Rome
        </BodyLink>
      </MapLink>{" "}
      and{" "}
      <MapLink id="carthage" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Carthage">
          Carthage
        </BodyLink>
      </MapLink>
      , and the two most brilliant commanders of the era,{" "}
      <KingLink node={<Hannibal />}>Hannibal Barca</KingLink> and{" "}
      <KingLink node={<Scipio />}>Scipio Africanus</KingLink>.
    </p>
    <hr />
    <b>Rome:</b>
    <p>
      In 264 BCE Rome had gained control of the{" "}
      <MapLink id="apennine" onMouseEnter={onHover} onMouseLeave={offHover}>
        Apennine Peninsula
      </MapLink>
      . Continuous warfare with the{" "}
      <BodyLink link="https://commons.wikimedia.org/wiki/File:Italy_IV_century_BC_-_Latina.svg">
        local tribes
      </BodyLink>{" "}
      had caused Roman politics to center around martial values.
    </p>

    <ol>
      <li>
        <span className="number">1</span> A{" "}
        <BodyLink link="https://en.wikipedia.org/wiki/Roman_consul">
          consul
        </BodyLink>{" "}
        held the highest political office in the Roman Republic. Each year two
        consuls were elected. In wartime they acted as supreme commanders.
      </li>
      <li>
        <span className="number">2</span> The army was raised from the{" "}
        <BodyLink link="https://www.reddit.com/r/AskHistorians/comments/21000l/military_service_in_the_roman_empire/">
          citizens
        </BodyLink>{" "}
        of the Roman Republic and of its allied states. It was considered a
        civic duty to serve when called upon.
      </li>
      <li>
        <span className="number">3</span>Up until the First Punic War the Romans
        had had no experience in naval warfare.
      </li>
    </ol>
    <hr />
    <b>Carthage:</b>
    <p>
      Carthage had established a network of trading colonies throughout the
      Mediterranean. Their main focus was commerce, but they had recently fought{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Sicilian_Wars">
        numerous wars
      </BodyLink>{" "}
      with the Greeks for control over trade routes.
    </p>
    <ol>
      <li>
        <span className="number">1</span> A{" "}
        <BodyLink link="https://www.ancient.eu/Carthaginian_Government/">
          suffete
        </BodyLink>{" "}
        held the highest political office in the Carthaginian government. Unlike
        their Roman counterparts they seldom got involved in military operations
        but instead had religious, financial or judicial duties. Professional
        military generals were appointed directly by the Carthaginian Senate.
      </li>
      <li>
        <span className="number">2</span> The bulk of army was made up of{" "}
        <BodyLink link="https://en.wikipedia.org/wiki/Military_of_Carthage">
          mercenaries
        </BodyLink>{" "}
        e.g{" "}
        <MapLink id="libya" onMouseEnter={onHover} onMouseLeave={offHover}>
          Libyans
        </MapLink>
        ,{" "}
        <MapLink id="iberia" onMouseEnter={onHover} onMouseLeave={offHover}>
          Iberians
        </MapLink>{" "}
        and the famous{" "}
        <MapLink id="numidia" onMouseEnter={onHover} onMouseLeave={offHover}>
          <BodyLink link="https://en.wikipedia.org/wiki/Numidian_cavalry">
            Numidian cavalry
          </BodyLink>
        </MapLink>
        .
      </li>
      <li>
        <span className="number">3</span> The Carthaginians was a seafaring
        people. Contrary to the composition of the army, the majority of the
        fleet was manned by Carthaginian citizens.
      </li>
    </ol>
  </>
);

export const Legends264 = [
  {
    party: "rome",
    label: "Roman Republic",
    mapId: "rome",
  },
  {
    party: "carthage",
    label: "Republic of Carthage",
    mapId: "carthage",
  },
  {
    party: "syracuse",
    label: "Syracuse",
    mapId: "syracuse",
  },
  {
    party: "messana",
    label: "Messana",
    mapId: "messana",
  },
];

export const Highlights264 = {
  sicily: {
    coords: [14.162282573271211, 37.62669515009474],
    type: InterestType.AREA,
    id: "sicily",
    info: "Sicily",
    direction: TooltipDirection.ON_LEFT,
    mapAreas: [],
    mapIds: ["sicily"],
  },
  rome: {
    coords: [12.502017074584536, 41.88767751335361],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Roman_Republic",
    id: "rome",
    info: "Rome",
    direction: TooltipDirection.ON_TOP,
    mapAreas: ["rome"],
    mapIds: ["rome"],
  },
  apennine: {
    coords: [15.062038299226435, 40.738488668723285],
    type: InterestType.AREA,
    id: "apennine",
    info: "Apennine Peninsula",
    direction: TooltipDirection.ON_LEFT,
    mapAreas: ["rome"],
    mapIds: ["apennine"],
  },
  libya: {
    coords: [13.76291575146398, 32.29879651269623],
    type: InterestType.AREA,
    id: "libya",
    info: "Libya",
    direction: TooltipDirection.ON_TOP,
    mapAreas: [],
    mapIds: ["libya"],
  },
  iberia: {
    coords: [-4, 38],
    type: InterestType.AREA,
    id: "iberia",
    info: "Iberia",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: [],
    mapIds: ["iberia"],
  },
  numidia: {
    coords: [3.5, 35.49977525331348],
    type: InterestType.AREA,
    id: "numidia",
    info: "Numidia",
    direction: TooltipDirection.ON_BOTTOM,
    mapAreas: [],
    mapIds: ["numidia"],
  },
  messana: {
    coords: [15.5614, 38.18],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Mamertines",
    id: "messana",
    info: "Messana",
    direction: TooltipDirection.ON_TOP,
    mapAreas: ["messana"],
    mapIds: ["messana"],
  },
  syracuse: {
    coords: [15.2875, 37.069167],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Syracuse,_Sicily",
    id: "syracuse",
    info: "Syracuse",
    direction: TooltipDirection.ON_BOTTOM,
    mapAreas: ["syracuse"],
    mapIds: ["syracuse"],
  },
  carthage: {
    coords: [10.3233, 36.8528],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Carthage",
    id: "carthage",
    info: "Carthage",
    direction: TooltipDirection.ON_LEFT,
    mapIds: ["carthage"],
    mapAreas: [
      "carthage",
      "carthage_corsica",
      "carthage_iberia",
      "carthage_mallorca",
      "carthage_sardinia",
      "carthage_sicily",
      "carthage_egypt",
      "carthage_africa",
      "carthage_malta",
    ],
  },
  all: {
    id: "all",
    type: InterestType.ALL,
    mapIds: [
      "syracuse",
      "sicily",
      "carthage",
      "messana",
      "numidia",
      "iberia",
      "apennine",
      "rome",
      "libya",
    ],
    mapAreas: [],
  },
};

export const Sources264 = [
  {
    link: "https://en.wikipedia.org/wiki/Punic_Wars",
    label: "Wikipedia, The Punic Wars",
  },
  {
    link: "http://www.thelatinlibrary.com/imperialism/notes/punicwars.html",
    label: "The Latin Library, The Punic war",
  },
  {
    link: "https://www.britannica.com/event/Punic-Wars",
    label: "Britannica, The Punic Wars",
  },
  {
    link: "https://www.worldhistory.org/Punic_Wars",
    label: "World History Encyclopedia, The Punic Wars",
  },
  {
    link: "https://www.history.com/topics/ancient-history/punic-wars",
    label: "History.com, The Punic Wars",
  },
];
