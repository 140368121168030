import React, { useCallback, useEffect, useRef, useState } from "react";
import cx from "classnames";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { mapShowMouseHintState } from "../../state/mapState";
import { deviceWidthState, isWideDevice } from "../../state/uiState";
import { Store, STORE_KEY } from "../../Store";
import { Pointer } from "../Icons/Pointer";
import "./MapDesktopHint.scss";

export const MapDesktopHintInner: React.FunctionComponent = () => {
  const [hasAcknowleged, setHasAcknowleged] = useState<boolean>(false);
  const setHasShownTooltip = useSetRecoilState(mapShowMouseHintState);
  const namedWidth = useRecoilValue(deviceWidthState);
  const unMounted = useRef<boolean>(false);
  const onHover = useCallback(() => {
    setHasAcknowleged(true);
    Store.setValue(STORE_KEY.STORE_GRAPH_HINT_SHOWN, new Date());
    window.setTimeout(() => {
      if (unMounted.current) {
        return;
      }
      setHasShownTooltip(new Date());
    }, 1000);
  }, [setHasShownTooltip]);

  useEffect(() => {
    return () => {
      unMounted.current = true;
    };
  }, []);

  if (!isWideDevice(namedWidth)) {
    return null;
  }

  return (
    <div
      className={cx("map-desktop-hint", {
        "has-acknowledged": hasAcknowleged,
      })}
      onMouseEnter={onHover}
    >
      <div>
        <Pointer />
      </div>
    </div>
  );
};

export const MapDesktopHint: React.FunctionComponent = () => {
  const hasShownTooltip = !!useRecoilValue(mapShowMouseHintState);
  if (hasShownTooltip) {
    return null;
  }

  return <MapDesktopHintInner />;
};
