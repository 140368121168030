import React from "react";
import { Image } from "../../components/Images/Image";
import { Page } from "../Page";
import { ROUTE as HomeRoute } from "../../pages/Home";
import "./About.scss";
import { Link } from "react-router-dom";

export const ROUTE = "/about";

export const About: React.FunctionComponent = () => {
  return (
    <Page title="About" className="about">
      <section>
        <article>
          <div className="page-breadcrumbs">
            <Link to={HomeRoute}>Home</Link>
            <span>&rsaquo;</span>
            About
          </div>

          <h1>About</h1>

          <div className="quoute">
            <figure>
              <blockquote>
                &ldquo;Discipline is choosing between what you want now and what
                you want most&rdquo;
              </blockquote>
              <figcaption>
                <cite>- Abraham Lincoln</cite>
              </figcaption>
            </figure>
          </div>

          <div className="paragraphs-and-image">
            <div>
              <p>
                We probably all have a preferred way of learning - by reading a
                book, listening to a podcast, watching a video, etc. Someone
                might like to start with a visual historical overview, i.e. a
                map and a summary, before plunging into to the details. This
                site is an attempt to convey such an overview.
              </p>
            </div>

            <div className="image-wrapper">
              <Image src="/images/me_maps.jpg" ratio={6 / 8} alt="Me" />
            </div>
          </div>

          <div className="paragraphs">
            <p>
              Like many others I discovered history through <b>Mike Duncan's</b>{" "}
              <a
                href="https://thehistoryofrome.typepad.com/"
                target="_blank"
                rel="noreferrer"
              >
                History of Rome
              </a>{" "}
              podcast series. By the time of the Punic wars I was hooked. It was
              a triplet of events - the consultation of the sacred chickens
              <sup>*</sup>, the rise of Hannibal and the description of Scipio
              Africanus as{" "}
              <i>
                "an incredibly magnetic cross between Jim Morrison, Alexander
                the Great and Jesus"
              </i>{" "}
              <sup>**</sup>.
            </p>

            <p>
              From Rome I took the obvious step - <b>Dan Carlin's</b>{" "}
              <a
                href="https://www.dancarlin.com/"
                target="_blank"
                rel="noreferrer"
              >
                Hardcore History
              </a>
              . The outcome was never in doubt. <i>"Wrath of the Khans"</i>,{" "}
              <i>"Blueprint for Armageddon"</i>,<i>"King of Kings"</i>,... I
              even thought I saw Erich Ludendorff at the grocery store at one
              point.
            </p>
            <p>
              I listen to a ton of great podcasts. But <b>History of Rome</b>{" "}
              and <b>Hardcore history</b> will always have a special place in my
              heart.
            </p>
            <p>
              If you choose to wander outside of history I liked episodes 108 -
              114 (<i>The Frankfurt School</i>) of{" "}
              <a
                href="https://www.philosophizethis.org/"
                target="_blank"
                rel="noreferrer"
              >
                Philosophize this!
              </a>
              . Another good starting point is perhaps episode 137 - 139 (Rawls,
              Nozick, Von Hayek).
            </p>
            <p>
              I'd also like to recommend{" "}
              <a
                href="http://www.pmsutter.com/shows/askaspaceman"
                target="_blank"
                rel="noreferrer"
              >
                Ask a spaceman!
              </a>
              . Sometimes it seems, you can ask a person about almost any
              historical event and get an accurate description back. But when
              asked e.g.{" "}
              <i>which is the most abundant element in the Universe</i>, a
              rather fundamental question, he cannot answer. Episodes 115 - 122
              are investigating string theory. I'm not even qualified to have an
              opinion on string theory itself but the episodes touches on
              several important and interesting concepts.
            </p>
            <ul className="footnote">
              <li>
                <sup>*</sup> 13:37 in episode <i>"20b - The First Punic War"</i>
              </li>
              <li>
                <sup>**</sup> 13:19 in episode{" "}
                <i>"23d - The War With Hannibal"</i>
              </li>
            </ul>
          </div>
        </article>
      </section>
    </Page>
  );
};
