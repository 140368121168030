import React from "react";
import cx from "classnames";
import { External } from "../Icons/External";
import {
  AreasOfInterest,
  InterestType,
  PointOfInterest,
} from "../../data/dataMaps";
import "./MapTooltip.scss";

interface Props {
  pointOfInterest: PointOfInterest | AreasOfInterest;
  projection?: any;
  isActive: null | PointOfInterest | AreasOfInterest;
  scale?: number;
}

const MapPoint = React.memo(() => (
  <svg viewBox="0 0 8 8" className="map-point">
    <circle className="map-point-outer" cx="4" cy="4" r="4" />
    <circle className="map-point-inner" cx="4" cy="4" r="2" />
  </svg>
));

export const MapTooltip: React.FunctionComponent<Props> = ({
  pointOfInterest,
  projection,
  isActive,
  scale = 1,
}) => {
  if (!projection) {
    return null;
  }

  if (pointOfInterest.type === InterestType.ALL) {
    return null;
  }

  const point = pointOfInterest as PointOfInterest;
  const coords = projection(point.coords);
  let x;
  let y;

  try {
    [x, y] = coords;
  } catch {
    console.log("coords", coords);
    return null;
  }

  let isExclusive = false;
  if (isActive) {
    isExclusive = isActive.mapIds.indexOf(point.id) > -1;
  }

  if (!point.link) {
    return (
      <div
        className={cx("map-tooltip", point.direction, point.type, {
          "is-active": isExclusive,
        })}
        style={{
          left: x * scale,
          top: y * scale,
        }}
      >
        <MapPoint />
        <span className="map-tooltip-arrow" />
        <div className="map-tooltip-info">{point.info}</div>
      </div>
    );
  }

  return (
    <a
      href={point.link}
      target="_blank"
      rel="noreferrer"
      className={cx("map-tooltip", point.direction, point.type, {
        "with-link": !!point.link,
        "is-active": isExclusive,
      })}
      style={{
        left: x * scale,
        top: y * scale,
      }}
    >
      <MapPoint />
      <span className="map-tooltip-arrow" />
      <div className="map-tooltip-info">{point.info}</div>
      <div className="map-tooltip-info-icon">
        <External />
      </div>
    </a>
  );
};
