import React, { useCallback, useMemo, useState } from "react";
import { Questions } from "../../components/Quiz/Questions";
import { QuestionComplete } from "./QuestionComplete";
import { ResultIndicator } from "./ResultIndicator";
import { Level } from "../../components/Quiz/Question";
import { Link } from "react-router-dom";
import { ROUTE } from "./Bounce";
import { Action } from "../../components/Buttons/Action";
import "./QuizComplete.scss";
import { ArrowUp } from "../../components/Icons/ArrowUp";
import {
  Choice,
  ChoiceType,
} from "../../components/Interactions/Choice/Choice";
import { v4 as uid } from "uuid";

interface Props {
  questions?: Questions;
}

export enum ANSWER_FILTER {
  ALL = "ALL",
  INCORRECT = "INCORRECT",
}

function getTextByResult(result: number, level: Level) {
  if (result < 0.15) {
    <>
      <strong>Done!</strong>
      {level === Level.HARD ? (
        <p>Without a doubt, those are hard questions.</p>
      ) : (
        <p>
          If you want to learn more there are a lot of available resources, on
          the web or as podcasts, videos.
        </p>
      )}
    </>;
  }

  if (result < 0.5) {
    return (
      <>
        <strong>Done!</strong>
        {level === Level.HARD && (
          <p>Some of those questions are indeed very hard.</p>
        )}
        {level === Level.MEDIUM && <p>Some of those questions are tricky.</p>}
        {level === Level.EASY && (
          <p>
            If you want to learn more there are a lot of available resources, on
            the web or as podcasts, videos.
          </p>
        )}
      </>
    );
  }

  if (result < 0.95) {
    return (
      <>
        <strong>Well done!</strong>
        {level === Level.HARD ? (
          <p>Some of those questions are tricky.</p>
        ) : (
          <p>Perhaps try even harder questions?</p>
        )}
      </>
    );
  }

  return (
    <>
      <strong>Wow!</strong>
      {level === Level.HARD ? (
        <p>Not even the oracle at Delphi could have done that.</p>
      ) : (
        <p>Fantastic, you are definitely ready for harder questions.</p>
      )}
    </>
  );
}

export const QuizComplete: React.FunctionComponent<Props> = ({ questions }) => {
  const scrollTo = useCallback(
    () => window.scrollTo({ top: 0, behavior: "smooth" }),
    []
  );

  const [value, setValue] = useState<string[]>([ANSWER_FILTER.ALL]);

  const options = useMemo(() => {
    return [
      {
        value: ANSWER_FILTER.INCORRECT,
        label: (
          <>
            Show only <b>incorrect</b> questions
          </>
        ),
      },
    ];
  }, []);

  const onChange = useCallback((ev) => {
    if (ev.checked) {
      setValue([ANSWER_FILTER.INCORRECT]);
    } else {
      setValue([ANSWER_FILTER.ALL]);
    }
  }, []);

  if (!questions) {
    return null;
  }

  const result = questions.getNumberOfCorrectQuestions();
  const total = questions.getNumberOfQuestions();
  const allCorrect = result === total;
  const noCorrect = result === 0;

  return (
    <div className="quiz-complete">
      <div className="quiz-complete-top">
        <div className="quiz-complete-top-indicator">
          <ResultIndicator result={result} total={total} />
        </div>
        <div className="quiz-complete-top-text">
          {getTextByResult(result / total, questions.getMaxLevel())}
          <Link to={ROUTE}>Try again?</Link>
        </div>
      </div>

      {!allCorrect && !noCorrect && (
        <Choice
          options={options}
          id={uid()}
          type={ChoiceType.CHECKBOX}
          onChange={onChange}
          value={value}
        />
      )}

      {questions.getQuestions().map((batch, idx) => (
        <React.Fragment key={`question-complete-${idx}`}>
          <QuestionComplete
            batch={batch}
            index={idx + 1}
            showOnlyIncorrect={value[0] === ANSWER_FILTER.INCORRECT}
          />
        </React.Fragment>
      ))}

      <Action onClick={scrollTo}>
        <ArrowUp />
      </Action>
    </div>
  );
};
