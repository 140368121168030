import React, { ReactNode } from "react";
import "./MobileHeader.scss";

interface Props {
  year?: string;
  title?: ReactNode;
}

export const MobileHeader: React.FunctionComponent<Props> = ({
  year = "",
  title = "",
}) => {
  return (
    <section className="mobile-header">
      <div className="map-name">{title}</div>
      <div>
        <span>Year</span> {year}
      </div>
    </section>
  );
};
