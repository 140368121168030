import React from "react";
import {
  AudioType,
  InterestType,
  TooltipDirection,
} from "../../../data/dataMaps";
import { BodyLink } from "../../HundredYearsWar/years/BodyLink";
import { MapLink } from "../../HundredYearsWar/years/MapLink";

export const Header1941 = "Pearl Harbor";

export const DataUrl1941 = "/maps/SupernovaInTheEast/1941/1941i.json";

export const Legends1941 = [
  {
    party: "japan",
    label: "Empire of Japan",
  },
  {
    party: "japan-exp",
    label: "Japanese expansion",
  },
  {
    party: "us",
    label: "United States",
  },
  {
    party: "uk",
    label: "United Kingdom",
  },
  {
    party: "guinea",
    label: "Australia",
  },
  {
    party: "dutch",
    label: "Dutch East Indies",
  },
  {
    party: "timor",
    label: "Portugal",
  },
];

export const Highlights1941 = {
  shanghai: {
    coords: [121.469, 31.2323],
    type: InterestType.POINT,
    id: "shanghai",
    link: "https://en.wikipedia.org/wiki/Battle_of_Shanghai",
    info: "Shanghai",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: [],
    mapIds: ["shanghai"],
  },
  nanjing: {
    coords: [118.75619565567463, 32.05261892044562],
    type: InterestType.POINT,
    id: "nanjing",
    link: "https://en.wikipedia.org/wiki/Battle_of_Nanking",
    info: "Nanjing",
    direction: TooltipDirection.ON_LEFT,
    mapAreas: [],
    mapIds: ["nanjing"],
  },
  indochina: {
    coords: [109.4503801876056, 15.053723769308185],
    type: InterestType.AREA,
    id: "indochina",
    link: "https://en.wikipedia.org/wiki/Japanese_invasion_of_French_Indochina",
    info: "Indochina",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: ["indochina"],
    mapIds: ["indochina"],
  },
  jehol: {
    coords: [118.03315910981699, 40.948851601530144],
    type: InterestType.AREA,
    id: "jehol",
    info: "Jehol",
    direction: TooltipDirection.ON_TOP,
    mapAreas: [],
    mapIds: ["jehol"],
  },
  polo: {
    coords: [116.21869936794582, 39.85025426106565],
    type: InterestType.POINT,
    id: "polo",
    link: "https://en.wikipedia.org/wiki/Marco_Polo_Bridge_Incident",
    info: "Marco Polo Bridge",
    direction: TooltipDirection.ON_BOTTOM,
    mapAreas: [],
    mapIds: ["polo"],
  },
  sumatra: {
    coords: [102.17823303950728, -0.4724860676098821],
    type: InterestType.AREA,
    id: "sumatra",
    info: "Sumatra",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: [],
    mapIds: ["sumatra"],
  },

  all: {
    id: "all",
    type: InterestType.ALL,
    mapAreas: [
      "beijing",
      "shanghai",
      "indochina",
      "macao",
      "fuzhou",
      "shantou",
    ],
    mapIds: ["shanghai", "nanjing", "indochina", "jehol", "polo", "sumatra"],
  },
};

export const Additional1941 = [];

export const Audio1941 = [
  {
    type: AudioType.SPOTIFY,
    src:
      "https://open.spotify.com/embed-podcast/episode/1lkQ10FIzzATYqr2TCtXow",
    title: "Dan Carlin, Supernova in the East II",
  },
];

export const Sources1941 = [
  {
    link: "https://en.wikipedia.org/wiki/Second_Sino-Japanese_War",
    label: "Wikipedia, Second Sino-Japanese war",
  },
  {
    link: "https://www.britannica.com/event/Second-Sino-Japanese-War/Stalemate",
    label: "Britannica, Second Sino-Japanese war",
  },
  {
    link: "https://en.wikipedia.org/wiki/Battle_of_Shanghai",
    label: "Wikipedia, Battle of Shanghai",
  },
  {
    link: "https://www.youtube.com/watch?v=ivQ9O-yB0dw",
    label: "The Pacific War, Armchair historian (video)",
  },
  {
    link: "https://www.youtube.com/watch?v=tS-BWXfFkVY",
    label: "World War II - Pacific War (video)",
  },
  {
    link: "https://www.youtube.com/watch?v=2PAJ9m4uGRY&t=28s",
    label: "History of the Japanese Empire (video)",
  },
  {
    link:
      "https://i0.wp.com/amti2016.wpengine.com/wp-content/uploads/2015/08/2.png",
    label: "Asia maritime transparency initiativ (map)",
  },
  {
    link:
      "https://www.reddit.com/r/MapPorn/comments/6fpoml/southeast_asia_during_the_high_colonial_age/",
    label: "Reddit, Southeast Asia Colonial powers (map)",
  },
];

interface Props {
  onHover?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  offHover?: () => void;
}

export const Year1941: React.FunctionComponent<Props> = ({
  onHover = () => {},
  offHover = () => {},
}) => (
  <>
    <p>
      After conquering Manchuria Japanese troops, formally still at peace,
      continued to push the frontier into the demilitarised zone of{" "}
      <MapLink id="jehol" onMouseEnter={onHover} onMouseLeave={offHover}>
        Jehol
      </MapLink>{" "}
      1933. China was without a central government, but in 1936{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Chiang_Kai-shek">
        Chiang Kai-shek
      </BodyLink>{" "}
      of the Kuomintang agreed to a truce with the communists in order to repel
      Japanese forces. With the{" "}
      <MapLink id="polo" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Marco_Polo_Bridge_Incident">
          Marco Polo Bridge incident
        </BodyLink>
      </MapLink>
      , 1937, Japan launched an invasion of China.
    </p>
    <p>
      A seperate front opened in{" "}
      <MapLink id="shanghai" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_Shanghai">
          Shanghai
        </BodyLink>
      </MapLink>
      . Both sides suffered heavy losses in what was to be know as{" "}
      <i>Stalingrad on the Yangtze</i>. Japan decided to advance towards{" "}
      <MapLink id="nanjing" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_Nanking">
          Nanjing
        </BodyLink>
      </MapLink>{" "}
      (Nanking), at the time the capital of the{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Republic_of_China_(1912%E2%80%931949)">
        Republic of China
      </BodyLink>
      . The seizure of{" "}
      <MapLink id="nanjing" onMouseEnter={onHover} onMouseLeave={offHover}>
        Nanjing
      </MapLink>{" "}
      resulted in tragedy. The atrocities conducted by the Japanese troops are
      known as the <i>The rape of Nanking</i> or the{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Nanjing_Massacre">
        Nanjing Massacre
      </BodyLink>
      . The capture of the capital did not have the intended effect, i.e. the
      breakdown of Chinese resistance, instead China went on fighting.
    </p>
    <p>
      France had fell to the Germans in 1940 and the installed government at{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Vichy_France">
        Vichy
      </BodyLink>{" "}
      was now technically a client state of Germany and therefore, due to the{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Tripartite_Pact">
        Tripartite Pact
      </BodyLink>
      , an ally to Japan. Japan wanted to cut off Chinese supply lines from the
      south. France agreed to letting Japan station troops in{" "}
      <MapLink id="indochina" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Japanese_invasion_of_French_Indochina">
          Indochina
        </BodyLink>{" "}
      </MapLink>
      .
    </p>
    <p>
      When Japan refused to back out of China the United States responded with
      an embargo in the summer of 1941. 80 % of Japan's oil imports came from
      the United States. However, the Dutch held{" "}
      <MapLink id="sumatra" onMouseEnter={onHover} onMouseLeave={offHover}>
        Sumatra
      </MapLink>{" "}
      had oil.{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Hirohito">
        Emperor Hirohito
      </BodyLink>{" "}
      now agreed with the ministers that argued that war with the United States,
      United Kingdom and the Netherlands (Dutch East Indies) was inevitable.
      Preperations were made for multiple attacks. In December 7th 1941{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Attack_on_Pearl_Harbor">
        Pearl Harbor
      </BodyLink>{" "}
      (
      <BodyLink link="https://maps.google.com/?q=21.37862488439049,-157.95177908275963&ll=21.37862488439049,-157.95177908275963&z=3">
        map
      </BodyLink>
      ) was hit.
    </p>
  </>
);
