import { ReactNode } from "react";
import { atom } from "recoil";

export enum NAMED_DEVICE_WIDTH {
  mobile = "mobile",
  landscape = "landscape",
  tablet = "tablet",
  desktop = "desktop",
  desktopMid = "desktop-mid",
  desktopPlus = "desktop-plus",
  wide = "wide",
  super = "super",
}

export function getNamedDeviceWidth(width: number) {
  if (width < 480) {
    return NAMED_DEVICE_WIDTH.mobile;
  }

  if (width < 768) {
    return NAMED_DEVICE_WIDTH.landscape;
  }

  if (width < 1024) {
    return NAMED_DEVICE_WIDTH.tablet;
  }

  if (width < 1280) {
    return NAMED_DEVICE_WIDTH.desktop;
  }

  if (width < 1440) {
    return NAMED_DEVICE_WIDTH.desktopMid;
  }

  if (width < 1600) {
    return NAMED_DEVICE_WIDTH.desktopPlus;
  }

  if (width < 2048) {
    return NAMED_DEVICE_WIDTH.wide;
  }

  return NAMED_DEVICE_WIDTH.super;
}

export function isWideDevice(width: number | NAMED_DEVICE_WIDTH) {
  const namedWidth =
    typeof width === "number" ? getNamedDeviceWidth(width) : width;
  return !(
    namedWidth === NAMED_DEVICE_WIDTH.mobile ||
    namedWidth === NAMED_DEVICE_WIDTH.landscape
  );
}

export const deviceWidthState = atom({
  key: "namedDeviceWidth",
  default: getNamedDeviceWidth(window.innerWidth || document.body.clientWidth),
});

export const backdropState = atom<boolean>({
  key: "backdrop",
  default: false,
});

export const overlayState = atom<ReactNode>({
  key: "overlay",
  default: null,
});
