import React from "react";
import { InfoLink } from "../../../components/Links/InfoLink";
import { KingLink } from "../../../components/Links/KingLink";
import {
  AudioType,
  InterestType,
  TooltipDirection,
} from "../../../data/dataMaps";
import { BodyLink } from "../../HundredYearsWar/years/BodyLink";
import { MapLink } from "../../HundredYearsWar/years/MapLink";
import { Cannae } from "../battles/Cannae";
import { Hannibal } from "../battles/Hannibal";
import { Trasimene } from "../battles/Trasimene";

interface BodyProps {
  onHover?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  offHover?: () => void;
}

export const Year213: React.FunctionComponent<BodyProps> = ({
  onHover = () => {},
  offHover = () => {},
}) => (
  <>
    <p>
      When{" "}
      <BodyLink link="https://www.reddit.com/r/AskHistorians/comments/1tvvdr/campaign_season/">
        spring of 217 BCE
      </BodyLink>{" "}
      arrived <KingLink node={<Hannibal />}>Hannibal</KingLink> broke camp and
      headed south. He took the unexpected route west of the Apennines. In June
      Hannibal set a trap and nearly oblitarated a pursuiting Roman army at lake{" "}
      <InfoLink node={<Trasimene />}>
        <MapLink id="trasimene" onMouseEnter={onHover} onMouseLeave={offHover}>
          Trasimene
        </MapLink>
      </InfoLink>
      .
    </p>
    <p>
      A consul held office for a year. This had the unintended effect that, in
      order to achieve martial glory, a consul had to be aggressive (there were
      exceptions, see{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Quintus_Fabius_Maximus_Verrucosus">
        Quintus Fabius Maximus
      </BodyLink>
      ). This aggressive approach was repeatedly exploited by Hannibal. A consul
      had individual command of an army. In 216 BCE the elected consuls combined
      their forces and met Hannibal at{" "}
      <InfoLink node={<Cannae />}>
        <MapLink id="cannae" onMouseEnter={onHover} onMouseLeave={offHover}>
          Cannae
        </MapLink>
      </InfoLink>
      . This was Hannibal's finest moment. Cannae is considered one of the worst
      defeats in Roman history. Hannibal's battle plan is regarded as a tactical
      masterpiece.
    </p>
    <p>
      After Cannae Rome was in panic. But they did not sue for peace. Instead
      they lowered the draft age and allowed criminals and slaves into the army.
    </p>
    <p>
      Hannibal did not march on Rome. This decision is one of{" "}
      <BodyLink link="https://www.reddit.com/r/AskHistorians/comments/2xk0x1/why_didnt_hannibal_march_on_rome/">
        history's great mysteries
      </BodyLink>
      . Instead, Hannibal sought allies and{" "}
      <MapLink
        id="carthage_in_rome"
        onMouseEnter={onHover}
        onMouseLeave={offHover}
      >
        <BodyLink link="https://en.wikipedia.org/wiki/Second_Punic_War#/media/File:Second_Punic_war_(cropped).png">
          several cities
        </BodyLink>
      </MapLink>{" "}
      defected to the Carthaginians.{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Philip_V_of_Macedon">
        Philip V
      </BodyLink>{" "}
      of Macedon allied with Carthage and so did{" "}
      <MapLink id="syracuse" onMouseEnter={onHover} onMouseLeave={offHover}>
        Syracuse
      </MapLink>
      , but these allegiances had little effect. Hannibal would not be recalled
      to Carthage until 203 BCE but after Cannae the war entered a new phase.
      Hannibal now had to defend his allies and react to Roman actions, in a
      way, he lost the initiative. He was not without victories though, e.g. at{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_the_Silarus">
        Silarus
      </BodyLink>
      , at{" "}
      <MapLink id="herdonia" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_Herdonia_(212_BC)">
          Herdonia in 212 BCE
        </BodyLink>
      </MapLink>{" "}
      and again in{" "}
      <MapLink id="herdonia" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_Herdonia_(210_BC)">
          210 BCE
        </BodyLink>
      </MapLink>
      . In 208 BCE the Numidian cavalry manage to{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Marcus_Claudius_Marcellus">
        kill both consuls
      </BodyLink>{" "}
      for the year.
    </p>
  </>
);

export const Highlights213 = {
  herdonia: {
    coords: [15.6333, 41.3167],
    type: InterestType.POINT,
    id: "herdonia",
    info: "Herdonia",
    direction: TooltipDirection.ON_TOP,
    mapAreas: [],
    mapIds: ["herdonia"],
  },
  rhegium: {
    coords: [15.661944, 38.111389],
    type: InterestType.POINT,
    id: "rhegium",
    info: "Rhegium",
    direction: TooltipDirection.ON_BOTTOM,
    mapAreas: [],
    mapIds: ["rhegium"],
  },
  capua: {
    coords: [14.213889, 41.105556],
    type: InterestType.POINT,
    id: "capua",
    info: "Capua",
    direction: TooltipDirection.ON_LEFT,
    mapAreas: [],
    mapIds: ["capua"],
  },
  syracuse: {
    coords: [15.2875, 37.069167],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Syracuse,_Sicily",
    id: "syracuse",
    info: "Syracuse",
    direction: TooltipDirection.ON_LEFT,
    mapAreas: ["syracuse"],
    mapIds: ["syracuse"],
  },
  trasimene: {
    coords: [12.085, 43.1975],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Battle_of_Lake_Trasimene",
    id: "trasimene",
    info: "Lake Trasimene",
    direction: TooltipDirection.ON_TOP,
    mapAreas: [],
    mapIds: ["trasimene"],
  },
  cannae: {
    coords: [16.1325, 41.306389],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Battle_of_Cannae",
    id: "cannae",
    info: "Cannae",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: [],
    mapIds: ["cannae"],
  },
  rome: {
    coords: [12.502017074584536, 41.88767751335361],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Roman_Republic",
    id: "rome",
    info: "Rome",
    direction: TooltipDirection.ON_TOP,
    mapAreas: [
      "rome",
      "rome_po",
      "rome_sicily",
      "rome_corsica",
      "rome_sardinia",
    ],
    mapIds: ["rome"],
  },
  carthage: {
    coords: [10.3233, 36.8528],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Carthage",
    id: "carthage",
    info: "Carthage",
    direction: TooltipDirection.ON_BOTTOM,
    mapIds: ["carthage"],
    mapAreas: [
      "carthage",
      "carthage_corsica",
      "carthage_iberia",
      "carthage_mallorca",
      "carthage_sardinia",
      "carthage_sicily",
      "carthage_egypt",
      "carthage_africa",
      "carthage_malta",
      "carthage_ebro",
    ],
  },
  tarentum: {
    coords: [17.239, 40.4169],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Battle_of_the_Trebia",
    id: "tarentum",
    info: "Tarentum",
    direction: TooltipDirection.ON_BOTTOM,
    mapAreas: [],
    mapIds: ["tarentum"],
  },
  carthage_in_rome: {
    coords: [15.661944, 40.8169],
    type: InterestType.AREA,
    id: "carthage_in_rome",
    info: "Allied with Hannibal",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: ["carthage_in_rome"],
    mapIds: ["capua", "tarentum"],
  },
  all: {
    id: "all",
    type: InterestType.ALL,
    mapAreas: ["carthage_in_rome"],
    mapIds: [
      "tarentum",
      "cannae",
      "trasimene",
      "capua",
      "neapolis",
      "rhegium",
      "rome",
      "carthage",
      "herdonia",
    ],
  },
};

export const Audio213 = [
  {
    type: AudioType.SPOTIFY,
    src: "https://open.spotify.com/embed/playlist/64zRVrVkHqKoueMWBcPNDe",
    title: "History of Rome - Second Punic War",
  },
];

export const Sources213 = [
  {
    link: "https://www.youtube.com/watch?v=Beg3fo2pSEs",
    label: "Biographics, Hannibal (video)",
  },
  {
    link:
      "https://www.reddit.com/r/AskHistorians/comments/5s9uy1/what_was_hannibal_doing_in_italy_from_cannae_till/",
    label: "Reddit, Hannibals war in Italy",
  },
  {
    link:
      "https://www.deviantart.com/cyowari/art/Second-Punic-War-Part-II-215-212-BC-814797858",
    label: "Cyowari, 215 - 212 BCE (map)",
  },
  {
    link:
      "https://www.deviantart.com/cyowari/art/Before-the-Third-Punic-War-150-BC-816496591",
    label: "Cyowari, 150 BCE (map)",
  },
  {
    link:
      "https://www.deviantart.com/cyowari/art/Third-Punic-War-149-146-BC-816496672",
    label: "Cyowari, 149 - 146 BCE (map)",
  },
  {
    link:
      "https://www.deviantart.com/cyowari/art/After-the-Third-Punic-War-145-BC-816496734",
    label: "Cyowari, 145 BCE (map)",
  },
];

export const Legends213 = [
  {
    party: "rome",
    label: "Roman Republic",
    mapId: "rome",
  },
  {
    party: "carthage",
    label: "Republic of Carthage",
    mapId: "carthage",
  },
  {
    party: "syracuse",
    label: "Syracuse",
    mapId: "syracuse",
  },
  {
    party: "carthagian-rome",
    label: "Territory allied with Hannibal",
    mapId: "carthage_in_rome",
  },
];
