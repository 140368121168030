import React from "react";
import {
  AudioType,
  InterestType,
  TooltipDirection,
} from "../../../data/dataMaps";
import { BodyLink } from "../../HundredYearsWar/years/BodyLink";
import { MapLink } from "../../HundredYearsWar/years/MapLink";

export const Header1870 = "The Meiji Restoration";

export const DataUrl1870 = "/maps/SupernovaInTheEast/1870/1870.json";

export const Legends1870 = [
  {
    party: "japan",
    label: "Empire of Japan",
  },
];

export const Highlights1870 = {
  tokyo: {
    coords: [139.691667, 35.689444],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Edo",
    id: "tokyo",
    info: "Tokyo / Edo",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: [],
    mapIds: ["tokyo"],
  },
  kyoto: {
    coords: [135.768333, 35.011667],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Kyoto",
    id: "kyoto",
    info: "Kyoto",
    direction: TooltipDirection.ON_LEFT,
    mapAreas: [],
    mapIds: ["kyoto"],
  },
  nagasaki: {
    coords: [129.866667, 32.783333],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Sakoku",
    id: "nagasaki",
    info: "Nagasaki",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: [],
    mapIds: ["nagasaki"],
  },
  all: {
    id: "all",
    type: InterestType.ALL,
    mapAreas: [],
    mapIds: ["tokyo", "kyoto", "nagasaki"],
  },
};

export const Additional1870 = [
  {
    coords: [
      [45.4554023665625, 140.20200877987705],
      [45.87603178421769, 141.4156716156988],
      [45.24737589378069, 144.2376843884226],
      [46.06798837643948, 148.88111949568622],
      [44.79768303651446, 149.27884198605233],
    ],
    className: "sea-border",
  },
  {
    coords: [
      [35.62463807619924, 130.68018189263552],
      [34.79426909735798, 129.0619647782065],
      [33.298177692579884, 128.05551267045186],
    ],
    className: "sea-border",
  },
];

export const Audio1870 = [
  {
    type: AudioType.SPOTIFY,
    src:
      "https://open.spotify.com/embed-podcast/episode/2lqNevhLx08QPmvCLjhS4g",
    title: "Dan Carlin, Supernova in the East I",
  },
];

export const Sources1870 = [
  {
    link: "https://en.wikipedia.org/wiki/Empire_of_Japan",
    label: "Wikipedia, Empire of Japan",
  },
  {
    link:
      "https://www.britannica.com/place/Japan/The-emergence-of-imperial-Japan",
    label: "Britannica, Emergence of imperial Japan",
  },
  {
    link: "https://www.youtube.com/watch?v=Y_b58Rpg2YY",
    label: "Feature history, Meiji Restoration (video)",
  },
  {
    link: "https://en.wikipedia.org/wiki/Boshin_War",
    label: "Wikipedia, the Boshin War",
  },
  {
    link: "https://www.youtube.com/watch?v=tS-BWXfFkVY",
    label: "World War II - Pacific War (1931-1945) (video)",
  },
];

interface Props {
  onHover?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  offHover?: () => void;
}

export const Year1870: React.FunctionComponent<Props> = ({
  onHover = () => {},
  offHover = () => {},
}) => (
  <>
    <p>
      In <b>1603</b>, after a time of civil war where local commanders (
      <BodyLink link="https://en.wikipedia.org/wiki/Daimyo">Daimyo</BodyLink>)
      fought over the title as{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Shogun">Shogun</BodyLink>{" "}
      (military dictator), the{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Tokugawa_shogunate">
        Tokugawa shogunate
      </BodyLink>{" "}
      was established. The Emperor was a figurehead. Japan chose to isolate and
      forbade all foreign trade except with China, Korea and, through the port
      of Dejima in{" "}
      <MapLink id="nagasaki" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Sakoku">
          Nagasaki
        </BodyLink>
      </MapLink>
      , the{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Dutch_Empire">
        Dutch colonial empire
      </BodyLink>
      .
    </p>
    <p>
      In <b>1853</b> American navy commander{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Matthew_C._Perry">
        Matthew Perry
      </BodyLink>{" "}
      forced Japan to open up to foreign trade. Through a series of internal
      rebellion the Emperor (
      <BodyLink link="https://en.wikipedia.org/wiki/Emperor_Meiji">
        Meiji
      </BodyLink>
      ) was restored to full imperial powers in <b>1868</b>. The capital was
      moved from{" "}
      <MapLink id="kyoto" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Kyoto">Kyoto</BodyLink>
      </MapLink>{" "}
      to{" "}
      <MapLink id="tokyo" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Edo">Edo</BodyLink>{" "}
      </MapLink>{" "}
      (Tokyo).
    </p>
    <p>
      Due to the self imposed isolation Japan had not industrialized like the
      western states. Many of the{" "}
      <BodyLink link="https://imgur.com/jfEliod">
        surrounding countries
      </BodyLink>{" "}
      (map) had come under colonial rule. In order to prevent a similar fate the
      Meiji cabinet started to adopt foreign technologies, production methods
      and political and social structures -{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Meiji_Restoration">
        the Meiji restoration
      </BodyLink>
      . With a more efficient industry came a need for resources. European
      countries had taken colonies rich in resources in order to supplement
      their shortage. This did not go unnoticed.
    </p>
  </>
);
