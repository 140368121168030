import React from "react";
import { YearSlider } from "../../components/YearSlider/YearSlider";
import { SimpleStory, Story, YearMap } from "../../data/dataMaps";
import { MapLegends } from "../../components/Legends/MapLegends";
import { References } from "../../components/References/References";
import { Audio } from "../../components/Audio/Audio";
import { Body } from "../../components/Body/Body";
import { ROUTE } from "../../pages/PunicWars/PunicWars";
import { GridHeader } from "../../components/Header/GridHeader";
import { IconInfo } from "../../components/IconInfo/IconInfo";
import { Year237 } from "./years/Year237";
import { Year264 } from "./years/Year264";
import { Year218 } from "./years/Year218";
import { Year213 } from "./years/Year213";
import { Year201 } from "./years/Year201";
import { Year146 } from "./years/Year146";
import "./Info.scss";

interface Props {
  year: string;
  story: Story | SimpleStory;
}

const yearMap: YearMap = {
  "-264": <Year264 />,
  "-237": <Year237 />,
  "-218": <Year218 />,
  "-213": <Year213 />,
  "-201": <Year201 />,
  "-146": <Year146 />,
};

export const Info: React.FunctionComponent<Props> = ({ year, story }) => (
  <section>
    <div className="grid">
      <div className="grid-slider-input-and-legend">
        <YearSlider story={story} year={year} route={ROUTE} />

        <MapLegends story={story} year={year} />

        <IconInfo />
      </div>

      <div className="grid-header ellipsis">
        <GridHeader
          initialYear="-264"
          year={year}
          story={story}
          header="The Punic Wars"
          route={ROUTE}
        />
      </div>

      <div className="grid-body">
        <Body year={year} story={story} yearMap={yearMap} />
      </div>

      <div className="grid-sounds">
        <Audio year={year} story={story} />
      </div>

      <div className="grid-references">
        <References year={year} story={story} />
      </div>
    </div>
  </section>
);
