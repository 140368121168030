import React from "react";
import {
  AudioType,
  InterestType,
  TooltipDirection,
} from "../../../data/dataMaps";
import { BodyLink } from "../../HundredYearsWar/years/BodyLink";
import { MapLink } from "../../HundredYearsWar/years/MapLink";

export const Header1933 = "The Manchurian incident";

export const DataUrl1933 = "/maps/SupernovaInTheEast/1933/1933test.json";

export const Legends1933 = [
  {
    party: "japan",
    label: "Empire of Japan",
  },
  {
    party: "japan-exp",
    label: "Japanese expansion",
  },
];

export const Highlights1933 = {
  manchukuo: {
    coords: [125.1870287923152, 47.545473169271446],
    type: InterestType.AREA,
    link: "https://en.wikipedia.org/wiki/Manchukuo",
    id: "manchukuo",
    info: "Manchukuo",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: ["liaodong"],
    mapIds: ["manchukuo"],
  },
  mukden: {
    coords: [123.44572795687884, 41.87501373190125],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Mukden_Incident",
    id: "mukden",
    info: "Mukden",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: ["liaodong"],
    mapIds: ["mukden"],
  },
  korea: {
    coords: [128, 38.11306484597434],
    type: InterestType.AREA,
    link: "https://en.wikipedia.org/wiki/Japan%E2%80%93Korea_Treaty_of_1910",
    id: "korea",
    info: "Korea",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: ["korea_exp"],
    mapIds: ["korea"],
  },
  marshall: {
    coords: [168.95413216788262, 7.388501523651205],
    type: InterestType.AREA,
    id: "marshall",
    info: "Marshall islands",
    direction: TooltipDirection.ON_LEFT,
    mapAreas: [],
    mapIds: ["marshall"],
  },
  caroline: {
    coords: [147.59889124854027, 6.0165475204740515],
    type: InterestType.AREA,
    id: "caroline",
    info: "Caroline islands",
    direction: TooltipDirection.ON_LEFT,
    mapAreas: [],
    mapIds: ["caroline"],
  },
  mariana: {
    coords: [145.6243917622072, 16.61699598511138],
    type: InterestType.AREA,
    id: "mariana",
    info: "Mariana islands",
    direction: TooltipDirection.ON_LEFT,
    mapAreas: [],
    mapIds: ["mariana"],
  },
  tsingtao: {
    coords: [120.43717224858845, 36.10077787998848],
    type: InterestType.POINT,
    id: "tsingtao",
    info: "Tsingtao",
    direction: TooltipDirection.ON_BOTTOM,
    mapAreas: [],
    mapIds: ["tsingtao"],
  },

  all: {
    id: "all",
    type: InterestType.ALL,
    mapAreas: ["liaodong", "shanghai", "korea_exp"],
    mapIds: [
      "manchukuo",
      "mukden",
      "korea",
      "marshall",
      "caroline",
      "mariana",
      "tsingtao",
    ],
  },
};

export const Additional1933 = [];

export const Audio1933 = [
  {
    type: AudioType.SPOTIFY,
    src:
      "https://open.spotify.com/embed-podcast/episode/2lqNevhLx08QPmvCLjhS4g",
    title: "Dan Carlin, Supernova in the East I",
  },
];

export const Sources1933 = [
  {
    link: "https://www.youtube.com/watch?v=tS-BWXfFkVY",
    label: "World War II - Pacific War (1931-1945) (video)",
  },
  {
    link: "https://www.youtube.com/watch?v=2PAJ9m4uGRY&t=28s",
    label: "History of the Japanese Empire (video)",
  },
  {
    link: "https://en.wikipedia.org/wiki/Siege_of_Tsingtao",
    label: "Wikipedia, Siege of Tsingtao",
  },
];

interface Props {
  onHover?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  offHover?: () => void;
}

export const Year1933: React.FunctionComponent<Props> = ({
  onHover = () => {},
  offHover = () => {},
}) => (
  <>
    <p>
      With victories in two succeeding wars the idea of a greater Japan grew. In{" "}
      <b>1910</b> Japan annexed{" "}
      <MapLink id="korea" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Japan%E2%80%93Korea_Treaty_of_1910">
          Korea
        </BodyLink>
      </MapLink>
      . The Quing dynasty (China) fell apart in 1911 and local warlords governed
      the fragmented country. Later on (1927) it would errupt into a full scale
      civil war between the{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Kuomintang">
        Kuomintang
      </BodyLink>{" "}
      and the communists.
    </p>
    <p>
      During the first world war Japan sided with the Allies and took possesion
      of Germany's island colonies in the Pacific -{" "}
      <MapLink id="mariana" onMouseEnter={onHover} onMouseLeave={offHover}>
        the Mariana
      </MapLink>
      ,{" "}
      <MapLink id="caroline" onMouseEnter={onHover} onMouseLeave={offHover}>
        Caroline
      </MapLink>
      , and{" "}
      <MapLink id="marshall" onMouseEnter={onHover} onMouseLeave={offHover}>
        Marshall
      </MapLink>{" "}
      Islands. Japan also joined Britain for the siege of{" "}
      <MapLink id="tsingtao" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Siege_of_Tsingtao">
          Tsingtao
        </BodyLink>
      </MapLink>
      , held by Germany, in China.
    </p>
    <p>
      The Japanese army and navy had been radicalized and acted more or less
      independently of the government. In 1931 an infantry unit exploded a bomb
      close to the South Manchuria Railway near{" "}
      <MapLink id="mukden" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Mukden_Incident">
          Mukden
        </BodyLink>
      </MapLink>
      . The army blamed Chinese dissidents and launched an invasion. By 1932
      Manchuria was conquered and the puppet state of{" "}
      <MapLink id="manchukuo" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Manchukuo">
          Manchukuo
        </BodyLink>
      </MapLink>{" "}
      was installed. Manchuria had lots of coal, iron and wheat.
    </p>
  </>
);
