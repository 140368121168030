import React, { ReactNode } from "react";

interface Props {
  name: ReactNode;
  chance: number;
  inverted?: boolean;
}

export const QueueItem: React.FunctionComponent<Props> = ({
  name,
  chance,
  inverted,
}) => {
  return (
    <li className="queue-item">
      {name}
      <div className="queue-item-chance">
        <div className="queue-item-progress">
          <div className="relative">
            <div className="queue-item-bg">
              <div
                className="queue-item-fg"
                style={{
                  width: `${inverted ? 100 : chance}%`,
                  left: `${inverted ? chance : 0}%`,
                }}
              />
            </div>
          </div>
        </div>
        <div className="queue-item-percentage">{chance} %</div>
      </div>
    </li>
  );
};
