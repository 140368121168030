import React from "react";
import { Image } from "../../../components/Images/Image";
import { BodyLink } from "../../HundredYearsWar/years/BodyLink";
import "../../../components/Overlay/Battles.scss";

export const Trebia: React.FunctionComponent = () => (
  <div className="reigns-trebia battle-overlay">
    <Image
      src="/images/punic/trebia_740x560.png"
      ratio={740 / 560}
      alt="Battle of Trebia"
    />
    <div className="battle-image-attributes">
      <i>
        Frank Martini. Cartographer, Department of History, United States
        Military Academy
      </i>
    </div>

    <div className="battle-body">
      <h5>
        <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_the_Trebia">
          Battle of the Trebia
        </BodyLink>
      </h5>
      <i className="battle-date">22 or 23 December, 218 BCE</i>
      <p>
        Trebia was the first real encounter of the Second Punic War. Hannibal
        flashed some of his tactical genius. The Numidian cavalry was Hannibals
        greatest asset. At Trebia he had them attack the Roman camp before
        daybreak. Roman consul{" "}
        <BodyLink link="https://en.wikipedia.org/wiki/Tiberius_Sempronius_Longus_(consul_218_BC)">
          Sempronius Longus
        </BodyLink>{" "}
        sent out his army in full force pursuing the Numidian cavalry. The
        Numidian cavalry fell back into formation on the other side of the
        river.
      </p>
      <p>
        Soaked and cold from crossing the winter river and without a breakfast,
        the Romans now faced a well prepared Carthaginian army. Both sides
        commanded about 40,000 troops. Hannibals cavalry won the encounters on
        both flanks of the battlefield. Also Hannibal's brother{" "}
        <BodyLink link="https://en.wikipedia.org/wiki/Mago_Barca">
          Mago
        </BodyLink>{" "}
        had been hidden with a small detachment and was now able to attack the
        Roman infantry in the rear.
      </p>
      <p>
        Sources differ regarding the casualties, it's generally estimated that
        10,000 romans were able to return to camp. Carthaginian losses amounts
        to several thousand.
      </p>
    </div>
  </div>
);
