import React from "react";
import {
  AudioType,
  InterestType,
  TooltipDirection,
} from "../../../data/dataMaps";
import { BodyLink } from "../../HundredYearsWar/years/BodyLink";
import { MapLink } from "../../HundredYearsWar/years/MapLink";

interface BodyProps {
  onHover?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  offHover?: () => void;
}

export const Year237: React.FunctionComponent<BodyProps> = ({
  onHover = () => {},
  offHover = () => {},
}) => (
  <>
    <p>
      The First Punic War (264 BCE – 241 BCE) was fought over the island of{" "}
      <MapLink id="sicily" onMouseEnter={onHover} onMouseLeave={offHover}>
        Sicily
      </MapLink>
      . The 23 year long war saw few pitch battles - the mountainous terrain is
      ill suited for large field armies. Coastal cities could be supplied by the
      sea which made them hard to besiege.
    </p>
    <p>
      <BodyLink link="https://en.wikipedia.org/wiki/Mamertines">
        The Mamertines
      </BodyLink>
      , an unemployed mercenary band, had beset the city of{" "}
      <MapLink id="messana" onMouseEnter={onHover} onMouseLeave={offHover}>
        Messana
      </MapLink>
      . They raided the surrounding land and sea. Eventually,{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Hiero_II_of_Syracuse">
        Hiero II
      </BodyLink>
      , ruler of{" "}
      <MapLink id="syracuse" onMouseEnter={onHover} onMouseLeave={offHover}>
        Syracuse
      </MapLink>
      , and Carthage would ally against the Mamertines. The Mamertines sought
      help from the always opportunistic Romans. The First Punic War had begun.
    </p>
    <p>
      The Romans, inexperienced in seamanship, managed not only to build a fleet
      but also to match the Carthaginians at sea. The battle of{" "}
      <MapLink id="ecnomus" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_Cape_Ecnomus">
          Cape Ecnomus
        </BodyLink>
      </MapLink>{" "}
      (256 BCE) is still regarded as one of the{" "}
      <BodyLink link="https://www.youtube.com/watch?v=jpGMSzgd8eg">
        largest naval encounters
      </BodyLink>{" "}
      in history. The Romans were victorious and could continue to land an army
      on Carthaginian soil but was defeated at the{" "}
      <MapLink id="bagradas" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_the_Bagradas_River_(255_BC)">
          Bagradas River
        </BodyLink>
      </MapLink>
      .
    </p>
    <p>
      By 241 BCE both sides were economically exhausted. When a Carthaginian
      fleet was defeated at{" "}
      <MapLink id="aegates" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_the_Aegates">
          Aegates
        </BodyLink>
      </MapLink>
      , Carthage ordered commander{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Hamilcar_Barca">
        Hamilcar Barca
      </BodyLink>{" "}
      to sue for peace. Carthage agreed to pay an indemnity and to evacuate
      Sicily. However, in the aftermath, Carthage refused to pay the mercenaries
      of their army. They rose in revolt. When the Carthaginians prepared an
      expedition to crush the rebellion on{" "}
      <MapLink id="sardinia" onMouseEnter={onHover} onMouseLeave={offHover}>
        Sardina
      </MapLink>{" "}
      in 237 BCE, Rome intervened stating it was an act of war. Rome unjustly
      forced Carthage to cede both Sardinia and{" "}
      <MapLink id="corsica" onMouseEnter={onHover} onMouseLeave={offHover}>
        Corsica
      </MapLink>{" "}
      in addition to Sicily.
    </p>
  </>
);

export const Highlights237 = {
  sardinia: {
    coords: [9.140894119090731, 40.156902917414854],
    type: InterestType.AREA,
    id: "sardinia",
    info: "Sardinia",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: ["rome_sardinia"],
    mapIds: ["sardinia"],
  },
  corsica: {
    coords: [9.14479878836627, 42.136331530569926],
    type: InterestType.AREA,
    id: "corsica",
    info: "Corsica",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: ["rome_corsica"],
    mapIds: ["corsica"],
  },
  rome: {
    coords: [12.502017074584536, 41.88767751335361],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Roman_Republic",
    id: "rome",
    info: "Rome",
    direction: TooltipDirection.ON_TOP,
    mapAreas: ["rome", "rome_sicily", "rome_sardinia", "rome_corsica"],
    mapIds: ["rome"],
  },
  carthage: {
    coords: [10.3233, 36.8528],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Carthage",
    id: "carthage",
    info: "Carthage",
    direction: TooltipDirection.ON_LEFT,
    mapIds: ["carthage"],
    mapAreas: [
      "carthage",
      "carthage_corsica",
      "carthage_iberia",
      "carthage_mallorca",
      "carthage_sardinia",
      "carthage_sicily",
      "carthage_egypt",
      "carthage_africa",
      "carthage_malta",
    ],
  },
  bagradas: {
    coords: [10.1659, 36.7975],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Battle_of_the_Bagradas_River_(255_BC)",
    id: "bagradas",
    info: "Bagradas River",
    direction: TooltipDirection.ON_BOTTOM,
    mapAreas: [],
    mapIds: ["bagradas"],
  },
  ecnomus: {
    coords: [13.9, 37.05],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Battle_of_Cape_Ecnomus",
    id: "ecnomus",
    info: "Cape Ecnomus",
    direction: TooltipDirection.ON_BOTTOM,
    mapAreas: [],
    mapIds: ["ecnomus"],
  },
  agrigentum: {
    coords: [13.5833, 37.3167],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Battle_of_Agrigentum",
    id: "agrigentum",
    info: "Agrigentum",
    direction: TooltipDirection.ON_LEFT,
    mapAreas: [],
    mapIds: ["agrigentum"],
  },
  aegates: {
    coords: [12.2, 37.97],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Battle_of_the_Aegates",
    id: "aegates",
    info: "Aegates",
    direction: TooltipDirection.ON_TOP,
    mapAreas: [],
    mapIds: ["aegates"],
  },
  messana: {
    coords: [15.5614, 38.18],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Mamertines",
    id: "messana",
    info: "Messana",
    direction: TooltipDirection.ON_TOP,
    mapAreas: [],
    mapIds: ["messana"],
  },
  syracuse: {
    coords: [15.2875, 37.069167],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Syracuse,_Sicily",
    id: "syracuse",
    info: "Syracuse",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: ["syracuse"],
    mapIds: ["syracuse"],
  },
  sicily: {
    coords: [14.162282573271211, 37.62669515009474],
    type: InterestType.AREA,
    id: "sicily",
    info: "Sicily",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: ["syracuse", "rome_sicily"],
    mapIds: ["sicily"],
  },
  all: {
    id: "all",
    type: InterestType.ALL,
    mapAreas: [],
    mapIds: [
      "sicily",
      "syracuse",
      "messana",
      "aegates",
      "agrigentum",
      "ecnomus",
      "bagradas",
      "sardinia",
      "corsica",
    ],
  },
};

export const Audio237 = [
  {
    type: AudioType.SPOTIFY,
    src: "https://open.spotify.com/embed/playlist/5eBvoYZB22Qs57JBcDQGIe",
    title: "History of Rome - First Punic War",
  },
];

export const Sources237 = [
  {
    link: "https://en.wikipedia.org/wiki/First_Punic_War",
    label: "Wikipedia, First Punic War",
  },
  {
    link: "https://www.britannica.com/event/First-Punic-War",
    label: "Britannica, First Punic War",
  },
  {
    link: "https://www.worldhistory.org/First_Punic_War/",
    label: "World History Encyclopedia",
  },
  {
    link: "http://dcc.dickinson.edu/nepos-hannibal/first-punic-war",
    label: "Dickinson College, First Punic War",
  },
  {
    link: "https://www.youtube.com/watch?v=HOk6ppoQrkw",
    label: "Invicta, Part 1/4 (video)",
  },
  {
    link: "https://www.youtube.com/watch?v=K2Qdf75r_3I",
    label: "Invicta, Part 2/4 (video)",
  },
  {
    link: "https://www.youtube.com/watch?v=f2WcrLnq_WQ",
    label: "Invicta, Part 3/4 (video)",
  },
  {
    link: "https://www.youtube.com/watch?v=8bny4hcoUq4",
    label: "Invicta, Part 4/4 (video)",
  },
];

export const Legends237 = [
  {
    party: "rome",
    label: "Roman Republic",
    mapId: "rome",
  },
  {
    party: "carthage",
    label: "Republic of Carthage",
    mapId: "carthage",
  },
  {
    party: "syracuse",
    label: "Syracuse",
    mapId: "syracuse",
  },
];
