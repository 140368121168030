import React from "react";
import { Image } from "../../components/Images/Image";
import "./Reigns.scss";

export const EnglishReigns: React.FunctionComponent = () => {
  return (
    <div className="english-reigns">
      <Image
        src="/images/bp_700x390.jpg"
        ratio={700 / 390}
        alt="the Black Prince"
      />
      <div className="reigns-image-attributes">
        <i>The Black Prince of Crecy</i>
        <span>Julian Russel Story</span>
      </div>

      <div className="reigns-names">
        <svg viewBox="0 0 300 250">
          <defs>
            <linearGradient
              id="reignstoright"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%"
            >
              <stop offset="0%" stopColor="#72757b" stopOpacity="1" />
              <stop offset="100%" stopColor="#72757b" stopOpacity="0" />
            </linearGradient>

            <linearGradient id="reignstoleft" x1="100%" y1="0%" x2="0%" y2="0%">
              <stop offset="0%" stopColor="#72757b" stopOpacity="1" />
              <stop offset="100%" stopColor="#72757b" stopOpacity="0" />
            </linearGradient>

            <linearGradient
              id="reignstobottom"
              x1="0%"
              y1="0%"
              x2="0%"
              y2="100%"
            >
              <stop offset="0%" stopColor="#72757b" stopOpacity="1" />
              <stop offset="100%" stopColor="#72757b" stopOpacity="0" />
            </linearGradient>

            <linearGradient id="reignstotop" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#72757b" stopOpacity="0" />
              <stop offset="100%" stopColor="#72757b" stopOpacity="1" />
            </linearGradient>
          </defs>

          <text className="reign-time-legend" x="0" y="240">
            Reign in parentheses
          </text>

          <text className="reign-time-label" x="300" y="52">
            1350
          </text>
          <line x1="0" x2="300" y1="44" y2="44" className="dotted" />
          <line x1="0" x2="300" y1="94" y2="94" className="dotted" />
          <text className="reign-time-label" x="300" y="152">
            1400
          </text>
          <line x1="0" x2="300" y1="144" y2="144" className="dotted" />
          <line x1="0" x2="300" y1="194" y2="194" className="dotted" />
          <text className="reign-time-label" x="300" y="252">
            1450
          </text>
          <line x1="0" x2="300" y1="244" y2="244" className="dotted" />

          <rect
            x="117"
            y="8"
            width="66"
            height="16"
            rx="3"
            className="reigns-link-bg"
          />

          <a
            href="https://en.wikipedia.org/wiki/Edward_III_of_England"
            target="_blank"
            rel="noreferrer"
          >
            <text x="150" y="20">
              Edward III
            </text>
          </a>
          <text x="150" y="34">
            <tspan>(1327 - 1377)</tspan>
          </text>

          {/* <path d="M150 40 L150 70" /> */}

          <rect
            x="149.5"
            y="35"
            width="1"
            height="35"
            fill="url(#reignstotop)"
          />

          {/* <circle cx="150" cy="70" r="2" /> */}
          <path d="M100 70 L200 70" />

          <rect
            x="47"
            y="50"
            width="93"
            height="16"
            rx="3"
            className="reigns-link-bg"
          />

          <a
            href="https://en.wikipedia.org/wiki/Edward_the_Black_Prince"
            target="_blank"
            rel="noreferrer"
          >
            <text x="94" y="62">
              the Black Prince
            </text>
          </a>

          <rect
            x="80"
            y="69.5"
            width="20"
            height="1"
            fill="url(#reignstoleft)"
          />

          <rect
            x="159"
            y="50"
            width="82"
            height="16"
            rx="3"
            className="reigns-link-bg"
          />

          <a
            href="https://en.wikipedia.org/wiki/John_of_Gaunt"
            target="_blank"
            rel="noreferrer"
          >
            <text x="200" y="62">
              John of Gaunt
            </text>
          </a>

          <rect
            x="200"
            y="69.5"
            width="20"
            height="1"
            fill="url(#reignstoright)"
          />

          <circle cx="100" cy="70" r="2" />
          <circle cx="200" cy="70" r="2" />

          <circle cx="100" cy="98" r="2" />
          <path d="M100 70 L100 142" />
          <path d="M97 142 L103 142" />

          <rect
            x="100"
            y="97.5"
            width="20"
            height="1"
            fill="url(#reignstoright)"
          />

          <rect
            x="28"
            y="90"
            width="63"
            height="16"
            rx="3"
            className="reigns-link-bg"
          />

          <a
            href="https://en.wikipedia.org/wiki/Richard_II_of_England"
            target="_blank"
            rel="noreferrer"
          >
            <text x="59" y="102">
              Richard II
            </text>
          </a>
          <text x="59" y="116">
            <tspan>(1377 - 1399)</tspan>
          </text>

          <path d="M200 70 L200 216" />
          {/* <path d="M200 216 L200 246" className="dotted" /> */}

          <rect
            x="199.5"
            y="216"
            width="1"
            height="30"
            fill="url(#reignstobottom)"
          />

          <circle cx="200" cy="142" r="2" />
          <circle cx="200" cy="170" r="2" />
          <circle cx="200" cy="188" r="2" />

          <rect
            x="180"
            y="141.5"
            width="20"
            height="1"
            fill="url(#reignstoleft)"
          />

          <rect
            x="211"
            y="133"
            width="58"
            height="16"
            rx="3"
            className="reigns-link-bg"
          />

          <a
            href="https://en.wikipedia.org/wiki/Henry_IV_of_England"
            target="_blank"
            rel="noreferrer"
          >
            <text x="240" y="145">
              Henry IV
            </text>
          </a>
          <text x="240" y="159">
            <tspan>(1399 - 1413)</tspan>
          </text>

          <rect
            x="200"
            y="169.5"
            width="20"
            height="1"
            fill="url(#reignstoright)"
          />

          <rect
            x="132"
            y="161"
            width="58"
            height="16"
            rx="3"
            className="reigns-link-bg"
          />

          <a
            href="https://en.wikipedia.org/wiki/Henry_V_of_England"
            target="_blank"
            rel="noreferrer"
          >
            <text x="161" y="173">
              Henry V
            </text>
          </a>
          <text x="161" y="187">
            <tspan>(1413 - 1422)</tspan>
          </text>

          <rect
            x="211"
            y="179"
            width="58"
            height="16"
            rx="3"
            className="reigns-link-bg"
          />
          <a
            href="https://en.wikipedia.org/wiki/Henry_VI_of_England"
            target="_blank"
            rel="noreferrer"
          >
            <text x="240" y="191">
              Henry VI
            </text>
          </a>
          <text x="240" y="205">
            <tspan>(1422 - 1461)</tspan>
          </text>
        </svg>
      </div>
    </div>
  );
};
