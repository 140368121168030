import React from "react";
import {
  AudioType,
  InterestType,
  TooltipDirection,
} from "../../../data/dataMaps";
import { BodyLink } from "../../HundredYearsWar/years/BodyLink";
import { MapLink } from "../../HundredYearsWar/years/MapLink";

export const Header1945 = "End of the war";

export const DataUrl1945 = "/maps/SupernovaInTheEast/1945/1945b.json";

export const Legends1945 = [
  {
    party: "japan",
    label: "Empire of Japan",
  },
  {
    party: "japan-exp",
    label: "Japanese expansion",
  },
  {
    party: "japan-ally",
    label: "Japanese ally",
  },
  {
    party: "us",
    label: "United States",
  },
  {
    party: "uk",
    label: "United Kingdom",
  },
  {
    party: "guinea",
    label: "Australia",
  },
];

export const Highlights1945 = {
  saipan: {
    coords: [145.75, 15.183333],
    type: InterestType.POINT,
    id: "saipan",
    info: "Saipan",
    link: "https://en.wikipedia.org/wiki/Battle_of_Saipan",
    direction: TooltipDirection.ON_TOP,
    mapAreas: [],
    mapIds: ["saipan"],
  },
  iwojima: {
    coords: [141.316667, 24.783333],
    type: InterestType.POINT,
    id: "iwojima",
    info: "Iwo Jima",
    link: "https://en.wikipedia.org/wiki/Battle_of_Iwo_Jima",
    direction: TooltipDirection.ON_TOP,
    mapAreas: [],
    mapIds: ["iwojima"],
  },
  peleliu: {
    coords: [134.25, 7],
    type: InterestType.POINT,
    id: "peleliu",
    info: "Peleliu",
    link: "https://en.wikipedia.org/wiki/Battle_of_Peleliu",
    direction: TooltipDirection.ON_BOTTOM,
    mapAreas: [],
    mapIds: ["peleliu"],
  },
  guam: {
    coords: [144.6509, 13.3693],
    type: InterestType.POINT,
    id: "guam",
    info: "Guam",
    link: "https://en.wikipedia.org/wiki/Battle_of_Guam_(1944)",
    direction: TooltipDirection.ON_BOTTOM,
    mapAreas: [],
    mapIds: ["guam"],
  },
  okinawa: {
    coords: [127.927778, 26.479444],
    type: InterestType.POINT,
    id: "okinawa",
    info: "Okinawa",
    link: "https://en.wikipedia.org/wiki/Battle_of_Okinawa",
    direction: TooltipDirection.ON_LEFT,
    mapAreas: [],
    mapIds: ["okinawa"],
  },
  hiroshima: {
    coords: [132.45, 34.383333],
    type: InterestType.POINT,
    id: "hiroshima",
    info: "Hiroshima",
    link: "https://en.wikipedia.org/wiki/Atomic_bombings_of_Hiroshima_and_Nagasaki",
    direction: TooltipDirection.ON_TOP,
    mapAreas: [],
    mapIds: ["hiroshima"],
  },
  nagasaki: {
    coords: [129.866667, 32.783333],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Atomic_bombings_of_Hiroshima_and_Nagasaki",
    id: "nagasaki",
    info: "Nagasaki",
    direction: TooltipDirection.ON_LEFT,
    mapAreas: [],
    mapIds: ["nagasaki"],
  },
  guadalcanal: {
    coords: [160.020278, -9.445556],
    type: InterestType.POINT,
    link: "https://en.wikipedia.org/wiki/Guadalcanal_campaign",
    id: "guadalcanal",
    info: "Guadalcanal",
    direction: TooltipDirection.ON_LEFT,
    mapAreas: [],
    mapIds: ["guadalcanal"],
  },
  turkey: {
    coords: [130, 20],
    type: InterestType.AREA,
    link: "https://en.wikipedia.org/wiki/Battle_of_the_Philippine_Sea",
    id: "turkey",
    info: "Philippine Sea",
    direction: TooltipDirection.ON_LEFT,
    mapAreas: [],
    mapIds: ["turkey"],
  },
  manchuria: {
    coords: [125.1870287923152, 47.545473169271446],
    type: InterestType.AREA,
    id: "manchuria",
    info: "Manchuria",
    link: "https://en.wikipedia.org/wiki/Soviet_invasion_of_Manchuria",
    direction: TooltipDirection.ON_RIGHT,
    mapAreas: [],
    mapIds: ["manchuria"],
  },
  leyte: {
    coords: [125.356, 10.371],
    type: InterestType.AREA,
    id: "leyte",
    info: "Leyte Gulf",
    link: "https://en.wikipedia.org/wiki/Battle_of_Leyte_Gulf",
    direction: TooltipDirection.ON_LEFT,
    mapAreas: [],
    mapIds: ["leyte"],
  },
  all: {
    id: "all",
    type: InterestType.ALL,
    mapAreas: [],
    mapIds: [
      "saipan",
      "iwojima",
      "guam",
      "okinawa",
      "hiroshima",
      "nagasaki",
      "guadalcanal",
      "turkey",
      "manchuria",
      "leyte",
      "peleliu",
    ],
  },
};

export const Additional1945 = [
  {
    coords: [
      [23.831764689141085, 126.9262571030205],
      [26.329869274986873, 126.26683001332616],
      [27.181571505778848, 127.72739387039351],
      [25.402569672791003, 142.07996421391167],
      [18.068746426372858, 142.51571268747495],
      [15.367904849010454, 146.36253617469416],
      [8.71563565332837, 146.84179909095985],
    ],
    className: "sea-border",
  },
];

export const Audio1945 = [
  {
    type: AudioType.SPOTIFY,
    src: "https://open.spotify.com/embed-podcast/episode/0JyPFxeMC3sZCT1qssDGqB",
    title: "Dan Carlin, Supernova in the East V",
  },
  {
    type: AudioType.SPOTIFY,
    src: "https://open.spotify.com/embed/episode/19XDKv5ZtfQJpRv3R61liH",
    title: "Dan Carlin, Supernova in the East VI",
  },
];

export const Sources1945 = [
  {
    link: "https://www.youtube.com/watch?v=ivQ9O-yB0dw",
    label: "The Pacific War, Armchair historian (video)",
  },
  {
    link: "https://www.youtube.com/watch?v=tS-BWXfFkVY",
    label: "World War II - Pacific War (video)",
  },
  {
    link: "https://omniatlas.com/maps/asia-pacific/19450815/",
    label: "Omniatlas - Asia Pacific 1945 (map)",
  },
  {
    link: "https://en.wikipedia.org/wiki/Leapfrogging_(strategy)#/media/File:Second_world_war_asia_1943-1945_map_de.png",
    label: "Wikipedia, Pacific Leapfrogging (map)",
  },
  {
    link: "https://www.nationalww2museum.org/war/articles/pacific-strategy-1941-1944",
    label: "The National WW2 Museum (map)",
  },
  {
    link: "http://www.emersonkent.com/map_archive/japan_1945.htm",
    label: "Emerson Kent, The Western Pacifc 1945 (map)",
  },
  {
    link: "https://www.history.com/this-day-in-history/soviets-declare-war-on-japan-invade-manchuria",
    label: "History, Soviets declare war on Japan",
  },
];

interface Props {
  onHover?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  offHover?: () => void;
}

export const Year1945: React.FunctionComponent<Props> = ({
  onHover = () => {},
  offHover = () => {},
}) => (
  <>
    <p>
      <BodyLink link="https://en.wikipedia.org/wiki/Fleet_carrier">
        Fleet carriers
      </BodyLink>{" "}
      had proven to be a crucial part of a naval fighting force. They were,
      however, hard to build, requiring time and resources.
    </p>
    <p>
      At the{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_Midway">
        Battle of Midway
      </BodyLink>{" "}
      (4–7 June 1942) the U.S., having deciphered Japanese attack plans, was
      able to sink four Japanese fleet carriers. Midway is considered to be a
      turning point in the war. Japanese air supremacy was lost. Their troop and
      supply transports as well as their merchant fleet were now vulnerable.
      Japan would launch additional offensives, e.g{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Operation_Ichi-Go">
        Operation Ichi-Go
      </BodyLink>
      , but the initiative and momentum was now with the allies.
    </p>
    <p>
      Unlike other powers, Japanese troops would not surrender even under
      hopeless circumstances. They would die on their post or charge suicidally
      into enemy lines. The Allies, therefore, adopted the{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Leapfrogging_(strategy)">
        leapfrogging
      </BodyLink>{" "}
      strategy. Heavily reinforced islands were bypassed and instead had their
      supply lines cut.
    </p>
    <p>
      Some fortified islands, e.g{" "}
      <MapLink id="iwojima" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_Iwo_Jima">
          Iwo Jima
        </BodyLink>
      </MapLink>{" "}
      and{" "}
      <MapLink id="okinawa" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_Okinawa">
          Okinawa
        </BodyLink>
      </MapLink>
      , were of great strategical value and so had to be taken. Although the
      U.S. had steadily improved on their combat technique, the fighting was
      fierce and both side suffered heavy losses. An invasion of the Japanese
      mainland would see many American deaths.{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Harry_S._Truman">
        Truman
      </BodyLink>
      , as American President, in order to avoid such a scenario, ordered
      nuclear bombs to be dropped on{" "}
      <MapLink id="nagasaki" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Atomic_bombings_of_Hiroshima_and_Nagasaki">
          Nagasaki
        </BodyLink>
      </MapLink>{" "}
      (6th August 1945) and{" "}
      <MapLink id="hiroshima" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Atomic_bombings_of_Hiroshima_and_Nagasaki">
          Hiroshima
        </BodyLink>
      </MapLink>{" "}
      (9th August 1945) .
    </p>
    <p>
      On the 8th August 1945 Russia declared war on Japan. A day later they
      invaded{" "}
      <MapLink id="manchuria" onMouseEnter={onHover} onMouseLeave={offHover}>
        <BodyLink link="https://en.wikipedia.org/wiki/Soviet_invasion_of_Manchuria">
          Manchuria
        </BodyLink>
      </MapLink>
      .{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Hirohito">
        Emperor Hirohito
      </BodyLink>{" "}
      announced{" "}
      <BodyLink link="https://en.wikipedia.org/wiki/Surrender_of_Japan">
        Japan's surrender
      </BodyLink>{" "}
      on the 15th August 1945. The peace treaty was formally signed on the 2th
      September.
    </p>
  </>
);
