import React from "react";
import { Link } from "react-router-dom";
import { SimpleStory, Story } from "../../data/dataMaps";
import {
  getDynamicLink,
  getFormattedTextFromYear,
  getUrlFromYear,
} from "../../data/routeUtils";
import { ROUTE as HomeRoute } from "../../pages/Home";
import "./GridHeader.scss";

interface Props {
  year: string;
  initialYear: string;
  header: string;
  route: string;
  story: Story | SimpleStory;
}

export const GridHeader: React.FunctionComponent<Props> = ({
  year,
  initialYear,
  header,
  route,
  story,
}) => {
  return (
    <>
      <div className="grid-name">
        <div className="map-name">
          <Link to={HomeRoute}>Home</Link>
          <span>&rsaquo;</span>
          {year === initialYear ? (
            header
          ) : (
            <Link
              to={getDynamicLink(route, {
                year: getUrlFromYear(parseInt(initialYear, 10)) || initialYear,
              })}
            >
              {header}
            </Link>
          )}

          <span>&rsaquo;</span>
          <span className="map-name-year">
            {getFormattedTextFromYear(parseInt(year, 10))}
          </span>
        </div>
      </div>

      <h1 className="ellipsis">{story.years[year].header}</h1>
    </>
  );
};
