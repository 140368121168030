import React from "react";
import { Image } from "../../../components/Images/Image";
import { BodyLink } from "../../HundredYearsWar/years/BodyLink";
import "../../../components/Overlay/Battles.scss";

export const Trasimene: React.FunctionComponent = () => (
  <div className="reigns-trasimene battle-overlay">
    <Image
      src="/images/punic/trasimene_742x570.svg"
      ratio={740 / 560}
      alt="Battle of Trasimene"
    />
    <div className="battle-image-attributes">
      <i>
        Frank Martini. Cartographer, Department of History, United States
        Military Academy
      </i>
    </div>

    <div className="battle-body">
      <h5>
        <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_Lake_Trasimene">
          Battle of the Trasimene
        </BodyLink>
      </h5>
      <i className="battle-date">21 June, 217 BCE</i>
      <p>
        Hannibal pillaged the countryside in order to draw the Romans into an
        ambush. At lake Trasimene Hannibal found a suitable place. North of the
        road there were hills and to the south there was the lake. In the cover
        of darkness Hannibal ordered the elements of the army to take position
        on the hills.
      </p>
      <p>
        On the morning of 21 June 217 BCE the Roman army, led by consul{" "}
        <BodyLink link="https://en.wikipedia.org/wiki/Gaius_Flaminius_(consul_223_BC)">
          Gaius Flaminius
        </BodyLink>
        , set out to catch up with the Carthaginians. When the Romans had
        entered the narrow road on the banks of the lake, the Carthaginians
        sealed of the entrance and attacked from the hills. The confused Romans
        were unable to regroup into battle formation.
      </p>
      <p>
        A handful of Romans managed to flee, the rest, ca 25,000 men, were
        either killed or captured. The body of Gaius Flaminius was never found.
      </p>
    </div>
  </div>
);
