import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import cx from "classnames";
import "./Slides.scss";
import { Action } from "../Buttons/Action";
import { ChevronLeft } from "../Icons/ChevronLeft";
import { ChevronRight } from "../Icons/ChevronRight";

interface Props {
  children: ReactNode;
  index: number;
  onNext?: () => void;
  onPrev?: () => void;
  prevDisabled?: boolean;
  nextDisabled?: boolean;
}

enum SlideDirection {
  NONE = "",
  PREV = "prev",
  NEXT = "next",
}

export const Slides: React.FunctionComponent<Props> = ({
  children,
  index,
  onNext,
  onPrev,
  prevDisabled = false,
  nextDisabled = false,
}) => {
  const [current, setCurrent] = useState<ReactNode>(children);
  const [prev, setPrev] = useState<ReactNode>(null);
  const [next, setNext] = useState<ReactNode>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(index);
  const [direction, setDirection] = useState<SlideDirection>(
    SlideDirection.NONE
  );
  const slidesRef = useRef<HTMLDivElement>(null);
  const [anim, setAnim] = useState<boolean>(false);

  const onTransitionEnd = useCallback(
    (ev: TransitionEvent) => {
      if (!(ev.target as HTMLElement).classList.contains("slides")) {
        return;
      }

      if (ev.propertyName !== "transform") {
        return;
      }

      setDirection(SlideDirection.NONE);
      setCurrentIndex(index);
      setPrev(null);
      setCurrent(children);
      setNext(null);
    },
    [children, index]
  );

  useEffect(() => {
    if (!slidesRef.current) {
      return;
    }
    const ref = slidesRef.current;
    ref.addEventListener("transitionend", onTransitionEnd);

    return () => {
      ref.removeEventListener("transitionend", onTransitionEnd);
    };
  }, [onTransitionEnd]);

  useEffect(() => {
    if (index > currentIndex) {
      setDirection(SlideDirection.NEXT);
      setNext(children);
      return;
    }

    if (index < currentIndex) {
      setDirection(SlideDirection.PREV);
      setPrev(children);
    }
  }, [index, currentIndex, children]);

  useEffect(() => {
    if (prev || next) {
      setTimeout(() => setAnim(true), 100);
    } else {
      setAnim(false);
    }
  }, [prev, next]);

  const nextButton = useMemo(() => {
    if (!onNext) {
      return null;
    }
    return (
      <Action className="slides-on-next" onClick={onNext}>
        {nextDisabled ? (
          <div className="disabled" data-id="next-action-disabled">
            <ChevronRight />
          </div>
        ) : (
          <div data-id="next-action">
            <ChevronRight />
          </div>
        )}
      </Action>
    );
  }, [nextDisabled, onNext]);

  const prevButton = useMemo(() => {
    if (!onPrev) {
      return null;
    }
    return (
      <Action className="slides-on-prev" onClick={onPrev}>
        {prevDisabled ? (
          <div className="disabled" data-id="prev-action-disabled">
            <ChevronLeft />
          </div>
        ) : (
          <div data-id="prev-action">
            <ChevronLeft />
          </div>
        )}
      </Action>
    );
  }, [prevDisabled, onPrev]);

  return (
    <div className="slides-wrapper">
      {prevButton}
      <div
        className={cx("slides", direction, {
          animation: anim,
        })}
        ref={slidesRef}
      >
        {prev && <div className="slides-prev">{prev}</div>}
        <div className="slides-current">{current}</div>
        {next && <div className="slides-next">{next}</div>}
      </div>
      {nextButton}
    </div>
  );
};
