import React, { useEffect, useRef, useState } from "react";
import cx from "classnames";
import { Audio } from "../../data/dataMaps";
import { useInView } from "react-intersection-observer";
import "./Iframe.scss";
import { isDev } from "../../data/routeUtils";

interface Props {
  item: Audio;
}

export const Iframe: React.FunctionComponent<Props> = ({ item }) => {
  const [src, setSrc] = useState<string>("about:blank");
  const [loaded, setLoaded] = useState(false);
  const hasUnmounted = useRef<boolean>(false);

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    return () => {
      hasUnmounted.current = true;
    };
  }, []);

  useEffect(() => {
    if (!iframeRef.current) {
      return;
    }

    if (!inView) {
      return;
    }

    iframeRef.current.onload = () => {
      if (hasUnmounted.current) {
        return;
      }
      setLoaded(true);
    };

    setTimeout(() => {
      if (hasUnmounted.current) {
        return;
      }
      setSrc(item.src);
    }, 500);
  }, [inView, item]);

  return (
    <div
      className={cx("iframe", {
        loaded,
      })}
      ref={ref}
    >
      <div className="iframe-overlay" />

      {isDev() ? (
        <div className="iframe-mock" />
      ) : (
        <iframe
          src={src}
          ref={iframeRef}
          title={item.title}
          frameBorder="0"
          allow="encrypted-media"
        />
      )}
    </div>
  );
};
