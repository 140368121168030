import React from "react";
import { AudioType, SimpleStory, Story } from "../../data/dataMaps";
import "./Audio.scss";
import { Iframe } from "./Iframe";

interface Props {
  year: string;
  story: Story | SimpleStory;
}

export const Audio: React.FunctionComponent<Props> = ({ year, story }) => {
  const audio = story.years[year].audio;

  return (
    <div className="audio">
      <ul>
        {audio.map((item) => {
          if (item.type === AudioType.SPOTIFY) {
            return (
              <li key={item.src}>
                <Iframe item={item} />
              </li>
            );
          }
          return <li key="iframe-list" />;
        })}
        <li />
      </ul>
    </div>
  );
};
