import React, { FunctionComponent } from "react";
import cx from "classnames";

import "./ResultIndicator.scss";

interface Props {
  result: number;
  total: number;
}

export const ResultIndicator: FunctionComponent<Props> = ({
  result,
  total,
}) => {
  const radius = 60;
  const padding = 0;
  const outer = radius + padding;
  const relativeResult = (100 * result) / total;
  const inner = 50;
  const digits = result > 9;
  const textOffsetY = 70;

  const alfa = (relativeResult * 3.6 * Math.PI) / 180; // 360 deg / 100

  if (result === null || result === undefined) {
    return null;
  }

  if (relativeResult === 0 || relativeResult === 100) {
    return (
      <div
        className={cx("result-indicator", {
          digits,
        })}
      >
        <svg viewBox="0 0 120 120">
          <circle
            r={radius}
            cx={outer}
            cy={outer}
            className={
              relativeResult === 100
                ? "result-indicator-all"
                : "result-indicator-none"
            }
          />
          <circle
            r={inner}
            cx={outer}
            cy={outer}
            className="result-indicator-empty"
          />
          <text className="result-indicator-text-result" x="60" y={textOffsetY}>
            {result}
          </text>
          <text className="result-indicator-text-total" x="60" y="91">
            <tspan>of</tspan> {total}
          </text>
        </svg>
      </div>
    );
  }

  let x = 0;
  let y = 0;
  let xOffset = 0;
  let yOffset = 0;
  let largeArcFlag = 0;
  let largeArcFlagInverse = 1;

  if (relativeResult < 25) {
    x = radius * Math.sin(alfa);
    y = Math.sqrt(radius * radius - x * x);
    xOffset = outer + x;
    yOffset = outer - y;
  } else if (relativeResult < 50) {
    x = radius * Math.sin(Math.PI - alfa);
    y = Math.sqrt(radius * radius - x * x);
    xOffset = outer + x;
    yOffset = outer + y;
  } else if (relativeResult < 75) {
    x = radius * Math.sin(alfa - Math.PI);
    y = Math.sqrt(radius * radius - x * x);
    xOffset = outer - x;
    yOffset = outer + y;
    largeArcFlag = 1;
    largeArcFlagInverse = 0;
  } else {
    x = radius * Math.sin(2 * Math.PI - alfa);
    y = Math.sqrt(radius * radius - x * x);
    xOffset = outer - x;
    yOffset = outer - y;
    largeArcFlag = 1;
    largeArcFlagInverse = 0;
  }

  return (
    <div
      className={cx("result-indicator", {
        digits,
      })}
    >
      <svg viewBox="0 0 120 120">
        <path
          className="result-indicator-all"
          d={
            "M " +
            outer +
            " " +
            outer +
            " " +
            "L" +
            outer +
            " " +
            padding +
            " " +
            "A" +
            radius +
            " " +
            radius +
            " 1 " +
            largeArcFlag +
            " 1 " +
            xOffset +
            " " +
            yOffset +
            " Z"
          }
        />
        <path
          className="result-indicator-none"
          d={
            "M " +
            outer +
            " " +
            outer +
            " " +
            "L" +
            xOffset +
            " " +
            yOffset +
            " " +
            "A" +
            radius +
            " " +
            radius +
            " 1 " +
            largeArcFlagInverse +
            " 1 " +
            outer +
            " " +
            padding +
            " Z"
          }
        />
        <circle
          r={inner}
          cx={outer}
          cy={outer}
          className="result-indicator-empty"
        />
        <text className="result-indicator-text-result" x="60" y={textOffsetY}>
          {result}
        </text>

        <text className="result-indicator-text-total" x="60" y="91">
          <tspan>of</tspan> {total}
        </text>
      </svg>
    </div>
  );
};
