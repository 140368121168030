import React from "react";
import "./IconCheckbox.scss";

export const IconCheckboxChecked: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className="checkbox-checked"
  >
    <path
      className="checkbox-checked-box"
      d="M19 3 Q21 3 21 5 L21 19 Q21 21 19 21 L5 21 Q3 21 3 19 L3 5 Q3 3 5 3 Z"
    />
    <path
      className="checkbox-checked-checkmark"
      transform="translate(-7, 8) rotate(-45)"
      d="M6 12 L6 18 L18 18"
    />
  </svg>
);
