export enum STORE_KEY {
  STORE_GRAPH_HINT_SHOWN = "store.graph.hint.shown",
  STORE_ICON_INFO_HINT_SHOWN = "store.icon.info.hint.shown",
}

interface StoredData {
  [STORE_KEY.STORE_GRAPH_HINT_SHOWN]: Date | null;
  [STORE_KEY.STORE_ICON_INFO_HINT_SHOWN]: Date | null;
}

const NAME = "maps";

const defaultValues = {
  [STORE_KEY.STORE_GRAPH_HINT_SHOWN]: null,
  [STORE_KEY.STORE_ICON_INFO_HINT_SHOWN]: null,
};

let storedData: StoredData = { ...defaultValues };
const storedStringData = window.localStorage.getItem(NAME);
if (storedStringData) {
  try {
    storedData = { ...defaultValues, ...JSON.parse(storedStringData) };
  } catch (err) {}
}

export const Store = {
  getValue(key: keyof StoredData) {
    return storedData[key];
  },

  setValue<Key extends keyof StoredData>(key: Key, value: StoredData[Key]) {
    storedData[key] = value;
    try {
      window.localStorage.setItem(NAME, JSON.stringify(storedData));
    } catch (err) {
      //Do not log
    }
  },

  getStorage() {
    return storedData;
  },
};
