const BCE = "bce";

export interface DynamicValues {
  [key: string]: string;
}

export function getFormattedTextFromYear(year: number) {
  if (isNaN(year)) {
    return "";
  }

  if (year < 0) {
    return `${Math.abs(year)} BCE`;
  }

  return year.toString();
}

export function getUrlFromYear(year: number) {
  if (isNaN(year)) {
    return null;
  }

  if (year < 0) {
    return `${Math.abs(year)}${BCE}`;
  }

  return year.toString();
}

export function getYearFromUrl(url: string) {
  if (!url) {
    return null;
  }

  if (url.endsWith(BCE)) {
    return (parseInt(url.slice(0, -3), 10) * -1).toString();
  }

  return url;
}

export function getDynamicLink(
  dynamicUrl: string,
  dynamicValues: DynamicValues
) {
  let url = dynamicUrl;
  Object.keys(dynamicValues).forEach((key) => {
    const regEx = new RegExp(`:${key}(\\?)?`, "gi");
    url = url.replace(regEx, dynamicValues[key]);
  });
  return url;
}

export function isDev() {
  return window.location.hostname === "localhost";
}
