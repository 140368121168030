import React from "react";
import { Button } from "./Button";

interface Props {
  children: React.ReactNode;
  className?: string;
  data?: any;
  onClick: (data: any) => void;
  block?: boolean;
  attrs?: { [k: string]: string };
}

export const Action: React.FunctionComponent<Props> = (props) => (
  <Button {...props} action>
    {props.children}
  </Button>
);
