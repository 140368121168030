import React from "react";
import { Page } from "../Page";
import { ROUTE } from "../Home";
import "./NotFound.scss";
import { Link } from "react-router-dom";

export function NotFound() {
  return (
    <Page title="Not found" className="not-found">
      <section>
        <article>
          <h1>Not found</h1>

          <div className="quoute">
            <figure>
              <blockquote>
                &ldquo;Me granfather used to get sent down into th' mines to
                make sure it was safe fer th' canaries.&rdquo;
              </blockquote>
              <figcaption>
                <cite>- Groundskeeper Willie, Simpsons</cite>
              </figcaption>
            </figure>
          </div>

          <p>
            <b>Sorry</b>, seems like this map does not exist.
          </p>
          <p>
            <Link to={ROUTE}>Go back</Link>
          </p>
        </article>
      </section>
    </Page>
  );
}
