import React from "react";
import cx from "classnames";
import "./Icon.scss";

interface Props {
  className?: string;
}

export const Crown: React.FunctionComponent<Props> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 70 70"
      className={cx("icon", "crown", className)}
    >
      <path
        d="
        M5 18 L25 33 L35 11 L45 33 L65 18 L55 50 L15 50 Z"
      />
      <line x1="16" x2="54" y1="58" y2="58" />
    </svg>
  );
};
