import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import cx from "classnames";
import "./Map.scss";
import { useWidth } from "../../hooks/useWidth";
import {
  AreasOfInterest,
  Boundary,
  InterestType,
  MapSide,
  PointOfInterest,
  Transform,
} from "../../data/dataMaps";
import { interpolatePath } from "d3-interpolate-path";
import { MapTooltip } from "../../components/Map/MapTooltip";
import { MapInfoToggler } from "../../components/Map/MapInfoToggler";
import { getBoundary } from "../../components/Markers/Boundary";

interface Props {
  sea: any;
  isActive: null | PointOfInterest | AreasOfInterest;
  image: HTMLImageElement;
  year: string;
  mapSide: MapSide;
  transforms?: Transform[];
  territories: any;
  boundaries?: Boundary[];
  highlights?: {
    [key: string]: PointOfInterest | AreasOfInterest;
  };
}

interface Area {
  w: number;
  h: number;
}

interface State {
  area: Area;
  pathFunc: any;
  unbound: any;
  projection: any;
}

interface Paths {
  [key: string]: string;
}

enum transformKeys {
  ON_TOP = 0,
  ON_RIGHT = 1,
}

enum Pattern {
  FILL = 0,
  STRIPES = 1,
  PATH = 2,
  DASHED = 3,
}

const mapCache = {
  topoData: undefined,
  meshData: undefined,
};

const PRECISION = 3.2;

interface AreaColor {
  [key: string]: {
    color: string | string[];
    pattern: Pattern;
  };
}

let scaleHighlightToSolid: (t: number) => number;
let scaleHighlightToTransparent: (t: number) => number;
// let scaleTransparentToSolid: (t: number) => number;
// let scaleSolidToTransparent: (t: number) => number;
let scaleSolidToHighlight: (t: number) => number;
const highlightToSolid: (t: number) => number = (t: number) => {
  if (!scaleHighlightToSolid) {
    scaleHighlightToSolid = window.d3.interpolateNumber(0.3, 1);
  }
  return scaleHighlightToSolid(t);
};
const highlightToTransparent: (t: number) => number = (t: number) => {
  if (!scaleHighlightToTransparent) {
    scaleHighlightToTransparent = window.d3.interpolateNumber(0.3, 0);
  }
  return scaleHighlightToTransparent(t);
};
// const transparentToSolid: (t: number) => number = (t: number) => {
//   if (!scaleTransparentToSolid) {
//     scaleTransparentToSolid = window.d3.interpolateNumber(0, 1);
//   }
//   return scaleTransparentToSolid(t);
// };
// const solidToTransparent: (t: number) => number = (t: number) => {
//   if (!scaleSolidToTransparent) {
//     scaleSolidToTransparent = window.d3.interpolateNumber(1, 0);
//   }
//   return scaleSolidToTransparent(t);
// };
const solidToHighlight: (t: number) => number = (t: number) => {
  if (!scaleSolidToHighlight) {
    scaleSolidToHighlight = window.d3.interpolateNumber(1, 0.3);
  }
  return scaleSolidToHighlight(t);
};

const colorMap: AreaColor = {
  ebro: {
    color: "#666",
    pattern: Pattern.FILL,
  },
  rome: {
    color: "#f8bebd",
    pattern: Pattern.FILL,
  },
  rome_po: {
    color: "#f8bebd",
    pattern: Pattern.FILL,
  },
  rome_corsica: {
    color: "#f8bebd",
    pattern: Pattern.FILL,
  },
  rome_sardinia: {
    color: "#f8bebd",
    pattern: Pattern.FILL,
  },
  rome_macedon: {
    color: "#f8bebd",
    pattern: Pattern.FILL,
  },
  rome_sicily: {
    color: "#f8bebd",
    pattern: Pattern.FILL,
  },
  rome_ebro_upper: {
    color: "#f8bebd",
    pattern: Pattern.FILL,
  },
  rome_islands: {
    color: "#f8bebd",
    pattern: Pattern.FILL,
  },
  rome_mallorca: {
    color: "#f8bebd",
    pattern: Pattern.FILL,
  },
  rome_ebro_lower: {
    color: "#f8bebd",
    pattern: Pattern.FILL,
  },
  rome_iberia: {
    color: "#f8bebd",
    pattern: Pattern.FILL,
  },
  rome_malta: {
    color: "#f8bebd",
    pattern: Pattern.FILL,
  },
  carthage: {
    color: "#b9e9ce",
    pattern: Pattern.FILL,
  },
  carthage_malta: {
    color: "#b9e9ce",
    pattern: Pattern.FILL,
  },
  carthage_sicily: {
    color: "#b9e9ce",
    pattern: Pattern.FILL,
  },
  carthage_corsica: {
    color: "#b9e9ce",
    pattern: Pattern.FILL,
  },
  carthage_sardinia: {
    color: "#b9e9ce",
    pattern: Pattern.FILL,
  },
  carthage_africa: {
    color: "#b9e9ce",
    pattern: Pattern.FILL,
  },
  carthage_mallorca: {
    color: "#b9e9ce",
    pattern: Pattern.FILL,
  },
  carthage_iberia: {
    color: "#b9e9ce",
    pattern: Pattern.FILL,
  },
  carthage_egypt: {
    color: "#b9e9ce",
    pattern: Pattern.FILL,
  },
  carthage_ebro: {
    color: "#b9e9ce",
    pattern: Pattern.FILL,
  },
  syracuse: {
    color: "#749bec",
    pattern: Pattern.FILL,
  },
  messana: {
    color: "#d08fe4",
    pattern: Pattern.FILL,
  },
  hannibal_girona: {
    color: "#666",
    pattern: Pattern.DASHED,
  },
  hannibal_ticinus: {
    color: "#666",
    pattern: Pattern.DASHED,
  },
  carthage_in_rome: {
    color: ["#f8bebd", "#884949"],
    pattern: Pattern.STRIPES,
  },
};

function fillWithPattern(colors: string[], mapSide: MapSide) {
  const p = document.createElement("canvas");
  let patternWidth;
  let lineWidth;
  if (mapSide === MapSide.NONE) {
    patternWidth = 3;
    lineWidth = 1;
  } else {
    patternWidth = 8;
    lineWidth = 5;
  }
  p.width = patternWidth;
  p.height = patternWidth;
  const pctx = p.getContext("2d");
  if (!pctx) {
    return null;
  }
  pctx.lineWidth = lineWidth;
  pctx.fillStyle = colors[1];
  pctx.fillRect(0, 0, patternWidth, patternWidth);
  pctx.strokeStyle = colors[0];

  pctx.beginPath();
  pctx.moveTo(0, patternWidth / 2);
  pctx.lineTo(patternWidth, patternWidth / 2);
  pctx.stroke();

  return p;
}

function addToMap(
  context: CanvasRenderingContext2D,
  item: any[],
  mapSide: MapSide
) {
  const id = item[0];
  const colorItem = colorMap[id];
  if (colorItem) {
    if (colorItem.pattern === Pattern.STRIPES) {
      const pattern = fillWithPattern(colorItem.color as string[], mapSide);
      let fillPattern;
      if (pattern) {
        fillPattern =
          context.createPattern(pattern, "repeat") || colorItem.color[0];
      } else {
        fillPattern = colorItem.color[0];
      }
      context.fillStyle = fillPattern;
      context.fill(new Path2D(item[1] as string));
    } else if (colorItem.pattern === Pattern.PATH) {
      context.strokeStyle = colorItem.color as string;
      context.stroke(new Path2D(item[1] as string));
    } else if (colorItem.pattern === Pattern.DASHED) {
      if (mapSide === MapSide.NONE) {
        context.lineWidth = 3;
        context.setLineDash([6, 12]);
      } else {
        context.lineWidth = 7;
        context.setLineDash([12, 24]);
      }

      context.strokeStyle = colorItem.color as string;
      context.stroke(new Path2D(item[1] as string));
      context.setLineDash([]);
      context.lineWidth = 1;
    } else {
      context.fillStyle = colorItem.color as string;
      context.fill(new Path2D(item[1] as string));
    }
  } else {
    context.fillStyle = "black";
    context.fill(new Path2D(item[1] as string));
  }
}

// function getGradient(transformX: number, context: CanvasRenderingContext2D) {
//   const gradient = context.createLinearGradient(
//     transformX,
//     0,
//     transformX + 100,
//     0
//   );
//   gradient.addColorStop(0.1, "rgba(255,255,255,1)");
//   gradient.addColorStop(0.9, "rgba(255,255,255,0)");
//   // gradient.addColorStop(0, "rgba(0,0,0,1)");
//   // gradient.addColorStop(1, "rgba(0,0,0,0)");
//   return gradient;
// }

function getSeaGradient(context: CanvasRenderingContext2D, area: Area) {
  const gradient = context.createRadialGradient(
    area.w * 0.6,
    area.h * 0.6,
    area.w * 0.1,
    area.w * 0.6,
    area.h * 0.6,
    area.w * 0.5
  );
  // gradient.addColorStop(0.1, "rgba(225,238,255,1)");
  gradient.addColorStop(0.1, "#e1eeff");
  gradient.addColorStop(0.9, "#fff");
  return gradient;
}

function getTopoData(sea: any) {
  let topoData = mapCache.topoData;
  if (!topoData) {
    topoData = window.topojson.feature(sea, sea.objects.geo);
    mapCache.topoData = topoData;
  }
  return topoData;
}

function getArea(plot: MutableRefObject<HTMLDivElement | null>) {
  if (!plot.current) {
    return {
      w: 0,
      h: 0,
    };
  }
  const box = plot.current.getBoundingClientRect();
  const w = box.width * PRECISION;
  const h = box.height * PRECISION;
  return {
    w,
    h,
  };
}

function getPath(current: string, update: string) {
  const firstCurrentElem = current.split(",")[0];
  const firstUpdateElem = update.split(",")[0];

  if (firstCurrentElem === firstUpdateElem) {
    return update;
  }

  const coords = firstCurrentElem.substring(1);
  const index = update.indexOf(coords);
  if (index < 0) {
    return update;
  }

  const comparable = `M${update.substring(
    index,
    update.length - 1
  )}L${update.substring(1, index - 1)}Z`;

  return comparable === current ? current : update;
}

function getBounds(state: any, path: any, w: any, h: any) {
  const b = path.bounds(state);
  const s = 1 / Math.max((b[1][0] - b[0][0]) / w, (b[1][1] - b[0][1]) / h);
  const t = [
    (w - s * (b[1][0] + b[0][0])) / 2,
    (h - s * (b[1][1] + b[0][1])) / 2,
  ];
  return { s, t };
}

function drawSea(
  image: HTMLImageElement,
  projections: State,
  context: CanvasRenderingContext2D,
  mapSide: MapSide,
  topo: any,
  scale: number,
  transformX: number
) {
  context.globalAlpha = 0.2;
  context.drawImage(image, 0, 0, projections.area.w, projections.area.h);
  context.globalAlpha = 1;
  context.strokeStyle = "#999";
  context.fillStyle = getSeaGradient(context, projections.area);
  context.beginPath();
  projections.pathFunc(topo.features[0]);
  context.lineWidth = Math.min(mapSide === MapSide.RIGHT ? 3 : 1.5, 3 / scale);
  context.fill();
  context.stroke();
  context.closePath();

  if (mapSide === MapSide.RIGHT) {
    context.fillStyle = "#fff";
    context.beginPath();
    projections.pathFunc(topo.features[1]);
    context.fill();
    context.closePath();
  }
}

export const Map: React.FunctionComponent<Props> = ({
  sea,
  image,
  year,
  mapSide,
  territories,
  isActive,
  transforms = [
    {
      scale: 1,
      transformX: 1,
      transformY: 1,
    },
    {
      scale: 1,
      transformX: 1,
      transformY: 1,
    },
  ],
  highlights,
  boundaries,
}) => {
  const width = useWidth();
  const plot = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const paths = useRef<Paths>({});
  const bounds = useRef<Paths>({});
  const mapAreas = useRef<string[] | null>(null);
  const [isAnimating, setIsAnimating] = useState<boolean>(false);

  const territoriesData = useMemo(() => {
    return territories
      ? window.topojson.feature(territories, territories.objects.geo)
      : null;
  }, [territories]);

  const currentTransform = useRef<Transform | null>(null);
  const [projections, setProjections] = useState<State>({
    area: { w: 0, h: 0 },
    pathFunc: undefined,
    unbound: undefined,
    projection: () => ({ x: 0, y: 0 }),
  });

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    const context = canvas.getContext("2d");
    if (!context) {
      return;
    }

    if (!plot.current) {
      return;
    }

    const area = getArea(plot);
    // canvas.setAttribute(
    //   "height",
    //   (area.h * window.devicePixelRatio).toString()
    // );
    // canvas.setAttribute("width", (area.w * window.devicePixelRatio).toString());

    // canvas.style.width = `${area.w * window.devicePixelRatio}px`;
    // canvas.style.height = `${area.h * window.devicePixelRatio}px`;

    const topo = getTopoData(sea);
    const projection = window.d3.geoMercator().scale(1).translate([0, 0]);
    let pathFunc = window.d3.geoPath().projection(projection).context(context);
    const { s, t } = getBounds(
      (topo as any).features[0],
      pathFunc,
      area.w,
      area.h
    );
    projection.scale(s).translate(t);
    pathFunc = window.d3.geoPath().projection(projection).context(context);
    const unbound = window.d3.geoPath().projection(projection);

    setProjections({
      area,
      pathFunc,
      unbound,
      projection,
    });
  }, [width, sea]);

  useEffect(() => {
    setIsAnimating(true);
  }, [year]);

  const drawHighlights = useCallback(
    (
      context,
      projections,
      topo,
      image,
      mapSide,
      transformX,
      transformY,
      scale,
      mapAreas,
      paths,
      opacity
    ) => {
      context.save();
      context.clearRect(0, 0, projections.area.w, projections.area.h);
      context.translate(transformX, transformY);
      context.scale(scale, scale);
      context.lineCap = "round";

      Object.entries(paths).forEach((item) => {
        const id = item[0];
        if (mapAreas && mapAreas.indexOf(id) > -1) {
          context.globalAlpha = 1;
        } else {
          context.globalAlpha = opacity;
        }
        addToMap(context, item, mapSide);
      });

      drawSea(image, projections, context, mapSide, topo, scale, transformX);
      context.restore();
    },
    []
  );

  useEffect(() => {
    if (isAnimating) {
      return;
    }

    let interpolator: (t: number) => number;
    if (!territoriesData) {
      return;
    }

    if (!projections.pathFunc) {
      return;
    }

    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }

    const context = canvas.getContext("2d");
    if (!context) {
      return;
    }

    const topo = getTopoData(sea);

    if (isActive) {
      if (isActive.type === InterestType.ALL) {
        mapAreas.current = highlights?.all.mapAreas ?? [];
      } else {
        mapAreas.current = isActive.mapAreas;
      }

      interpolator = solidToHighlight;
    } else if (mapAreas.current) {
      interpolator = highlightToSolid;
    } else {
      return;
    }

    const mergedUpdate = { ...paths.current, ...bounds.current };

    window.d3
      .transition()
      .duration(300)
      .tween("fade-in-areas", () => {
        return function (t: number) {
          drawHighlights(
            context,
            projections,
            topo,
            image,
            mapSide,
            currentTransform.current?.transformX,
            currentTransform.current?.transformY,
            currentTransform.current?.scale,
            mapAreas.current,
            mergedUpdate,
            interpolator(t)
          );
        };
      })
      .on("end", () => {
        if (!isActive) {
          mapAreas.current = null;
        }
      });
  }, [
    drawHighlights,
    isActive,
    highlights,
    isAnimating,
    mapSide,
    projections,
    sea,
    territoriesData,
    image,
  ]);

  const drawMap = useCallback(
    (
      context,
      projections,
      topo,
      image,
      mapSide,
      transformX,
      transformY,
      scale,
      enterSet,
      updateSet,
      leaveSet,
      time,
      mapAreas?,
      fromHighlightToSolid = 1 - time,
      fromHighlightToTransparent = time
    ) => {
      context.save();
      context.clearRect(0, 0, projections.area.w, projections.area.h);
      context.translate(transformX, transformY);
      context.scale(scale, scale);
      context.lineCap = "round";

      Object.entries(updateSet).forEach((item) => {
        if (mapAreas) {
          if (mapAreas.indexOf(item[0]) > -1) {
            context.globalAlpha = 1;
          } else {
            context.globalAlpha = fromHighlightToSolid;
          }
        } else {
          context.globalAlpha = 1;
        }
        addToMap(context, item, mapSide);
      });

      context.globalAlpha = time;
      Object.entries(enterSet).forEach((item) => {
        addToMap(context, item, mapSide);
      });

      Object.entries(leaveSet).forEach((item) => {
        if (mapAreas) {
          if (mapAreas.indexOf(item[0]) > -1) {
            context.globalAlpha = 1 - time;
          } else {
            context.globalAlpha = fromHighlightToTransparent;
          }
        } else {
          context.globalAlpha = 1 - time;
        }
        addToMap(context, item, mapSide);
      });

      drawSea(image, projections, context, mapSide, topo, scale, transformX);
      context.restore();
    },
    []
  );

  useEffect(() => {
    let scale: (t: number) => number;
    let transformX: (t: number) => number;
    let transformY: (t: number) => number;

    if (!territoriesData) {
      return;
    }

    if (!projections.pathFunc) {
      return;
    }

    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }

    const context = canvas.getContext("2d");
    if (!context) {
      return;
    }

    const { w, h } = projections.area;
    const topo = getTopoData(sea);
    let key;
    if (mapSide === MapSide.RIGHT) {
      key = transformKeys.ON_RIGHT;
    } else {
      key = transformKeys.ON_TOP;
    }

    const enterSet: Paths = {};
    const leaveSet: Paths = {};
    const updateSet: Paths = {};
    const boundsEnterSet: Paths = {};
    const boundsLeaveSet: Paths = {};
    const boundsUpdateSet: Paths = {};

    const activeKeys: { [key: string]: boolean } = {};
    const activeBoundsKeys: { [key: string]: boolean } = {};
    const interpolateAreas: { [key: string]: (t: number) => string } = {};

    territoriesData.features.forEach((item: any) => {
      const id = item.properties.id;
      if (paths.current[id]) {
        activeKeys[id] = true;
        updateSet[id] = paths.current[id];
        const nextPath = getPath(paths.current[id], projections.unbound(item));
        if (nextPath !== paths.current[id]) {
          interpolateAreas[id] = interpolatePath(paths.current[id], nextPath);
        } else {
          interpolateAreas[id] = () => paths.current[id];
        }
      } else {
        enterSet[item.properties.id] = projections.unbound(item);
      }
    });

    boundaries?.forEach((boundary) => {
      const currentBound = bounds.current[boundary.name];
      if (currentBound) {
        activeBoundsKeys[boundary.name] = true;
        // boundsUpdateSet[boundary.name] = currentBound; // TODO
        const bounds = getBoundary(boundary, projections.projection);
        if (bounds) {
          boundsUpdateSet[boundary.name] = bounds;
        }
      } else {
        const bounds = getBoundary(boundary, projections.projection);
        if (bounds) {
          boundsEnterSet[boundary.name] = bounds;
        }
      }
    });

    Object.keys(paths.current).forEach((key: string) => {
      if (!activeKeys[key]) {
        leaveSet[key] = paths.current[key];
      }
    });

    Object.keys(bounds.current).forEach((key: string) => {
      if (!activeBoundsKeys[key]) {
        boundsLeaveSet[key] = bounds.current[key];
      }
    });

    const mergedEnter = { ...enterSet, ...boundsEnterSet };
    const mergedLeave = { ...leaveSet, ...boundsLeaveSet };
    const mergedUpdate = { ...updateSet, ...boundsUpdateSet };

    if (!currentTransform.current) {
      paths.current = { ...enterSet };
      bounds.current = { ...boundsEnterSet };
      currentTransform.current = {
        scale: transforms[key].scale,
        transformX: transforms[key].transformX * w,
        transformY: transforms[key].transformY * h,
      };

      drawMap(
        context,
        projections,
        topo,
        image,
        mapSide,
        transforms[key].transformX * w,
        transforms[key].transformY * h,
        transforms[key].scale,
        mergedEnter,
        {},
        {},
        1
      );
      setIsAnimating(false);
      return;
    }

    scale = window.d3.interpolate(
      currentTransform.current.scale,
      transforms[key].scale
    );

    transformX = window.d3.interpolate(
      currentTransform.current.transformX,
      transforms[key].transformX * w
    );

    transformY = window.d3.interpolate(
      currentTransform.current.transformY,
      transforms[key].transformY * h
    );

    currentTransform.current = {
      scale: transforms[key].scale,
      transformX: transformX(1),
      transformY: transformY(1),
    };

    window.d3
      .transition()
      .duration(1000)
      .tween("animate-zoom", () => {
        return function (t: number) {
          drawMap(
            context,
            projections,
            topo,
            image,
            mapSide,
            transformX(t),
            transformY(t),
            scale(t),
            {},
            mergedUpdate,
            mergedLeave,
            t,
            mapAreas.current,
            highlightToSolid(t),
            highlightToTransparent(t)
          );
        };
      })
      .on("end", () => {
        let time = 1000;
        if (Object.keys(updateSet).length === 0) {
          time = 10;
        }

        window.d3
          .transition()
          .duration(time)
          .tween("animate-paths", () => {
            return function (t: number) {
              const animatedAreas: any = {};
              Object.keys(updateSet).forEach((key) => {
                animatedAreas[key] = interpolateAreas[key](t);
              });

              drawMap(
                context,
                projections,
                topo,
                image,
                mapSide,
                currentTransform.current?.transformX,
                currentTransform.current?.transformY,
                currentTransform.current?.scale,
                {},
                { ...animatedAreas, ...boundsUpdateSet },
                {},
                t
              );
            };
          })
          .on("end", () => {
            window.d3
              .transition()
              .duration(300)
              .tween("fade-in-areas", () => {
                return function (t: number) {
                  const animatedAreas: any = {};
                  Object.keys(updateSet).forEach((key) => {
                    animatedAreas[key] = interpolateAreas[key](1);
                  });

                  drawMap(
                    context,
                    projections,
                    topo,
                    image,
                    mapSide,
                    currentTransform.current?.transformX,
                    currentTransform.current?.transformY,
                    currentTransform.current?.scale,
                    mergedEnter,
                    { ...animatedAreas, ...boundsUpdateSet },
                    {},
                    t
                  );
                };
              })
              .on("end", () => {
                const set: any = {};
                Object.keys(updateSet).forEach((key) => {
                  if (interpolateAreas[key]) {
                    set[key] = interpolateAreas[key](1);
                  } else {
                    set[key] = updateSet[key];
                  }
                });
                mapAreas.current = null; // TODO: ok?
                paths.current = { ...enterSet, ...set };
                bounds.current = { ...boundsEnterSet, ...boundsUpdateSet };
                setIsAnimating(false);
              });
          });
      });
  }, [
    projections,
    sea,
    image,
    mapSide,
    drawMap,
    territoriesData,
    transforms,
    boundaries,
  ]);

  let tooltipsTransformX;
  if (currentTransform.current?.transformX) {
    tooltipsTransformX = currentTransform.current?.transformX / PRECISION;
  } else {
    tooltipsTransformX = 0;
  }

  let tooltipsTransformY;
  if (currentTransform.current?.transformX) {
    tooltipsTransformY = currentTransform.current?.transformY / PRECISION;
  } else {
    tooltipsTransformY = 0;
  }

  let tooltipScale: number;
  if (currentTransform.current?.scale) {
    tooltipScale = currentTransform.current?.scale / PRECISION;
  } else {
    tooltipScale = 1;
  }

  return (
    <div
      className={cx("the-punic-wars-map", `year-${year}`, {
        "is-animating": isAnimating,
      })}
      ref={plot}
    >
      <MapInfoToggler highlights={highlights} />

      <div
        className="map-highlights"
        style={{
          transform: `translate3d(${tooltipsTransformX}px, ${tooltipsTransformY}px, 0)`,
        }}
      >
        {highlights &&
          Object.values(highlights).map((item) => (
            <MapTooltip
              scale={tooltipScale}
              isActive={!isAnimating && isActive ? isActive : null}
              key={item.id}
              pointOfInterest={item}
              projection={projections.projection}
            />
          ))}
      </div>

      <canvas
        ref={canvasRef}
        width={projections.area.w}
        height={projections.area.h}
      />
    </div>
  );
};
