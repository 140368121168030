import alexander from "./questions/alexander.json";
import caesar from "./questions/caesar.json";
import mythology from "./questions/mythology.json";
import philosophers from "./questions/philosophers.json";
import hannibal from "./questions/hannibal.json";
import athens from "./questions/athens.json";
import attila from "./questions/attila.json";
import gods from "./questions/gods.json";
import sparta from "./questions/sparta.json";
import aurelius from "./questions/aurelius.json";
import wonders from "./questions/wonders.json";
import diadochis from "./questions/diadochis.json";
import constantine from "./questions/constantine.json";
import crisis from "./questions/crisis.json";
import persia from "./questions/persia.json";
import { Batch } from "./Batch";
import { Filter, Level, Question } from "./Question";

function toBatch(raw: any[], filter: Filter) {
  const data = raw.map((item) => new Question(item));
  return new Batch({ questions: data, filter });
}

export class Questions {
  batches: Batch[];
  filter: Filter;

  constructor(filter: Filter) {
    this.batches = [
      toBatch(alexander, filter),
      toBatch(athens, filter),
      toBatch(attila, filter),
      toBatch(aurelius, filter),
      toBatch(caesar, filter),
      toBatch(constantine, filter),
      toBatch(crisis, filter),
      toBatch(diadochis, filter),
      toBatch(gods, filter),
      toBatch(hannibal, filter),
      toBatch(mythology, filter),
      toBatch(persia, filter),
      toBatch(philosophers, filter),
      toBatch(sparta, filter),
      toBatch(wonders, filter),
    ];

    this.batches.sort(() => (Math.random() > 0.5 ? 1 : -1));
    this.filter = filter;
  }

  getMaxLevel(): Level {
    if (this.filter.levels.includes(Level.HARD)) {
      return Level.HARD;
    }

    if (this.filter.levels.includes(Level.MEDIUM)) {
      return Level.MEDIUM;
    }

    return Level.EASY;
  }

  getQuestions(): Batch[] {
    return this.batches;
  }

  getNumberOfQuestions(): number {
    return this.batches.reduce(
      (accumulator, batch) => accumulator + batch.getNumberOfQuestions(),
      0
    );
  }

  getNumberOfCorrectQuestions(): number {
    return this.batches.reduce(
      (accumulator, batch) => accumulator + batch.getNumberOfCorrect(),
      0
    );
  }
}
