import React from "react";

interface Props {
  topoData: any;
  pathFunc: any;
}

const SeaInner: React.FunctionComponent<Props> = ({ topoData, pathFunc }) => {
  if (!pathFunc) {
    return null;
  }

  if (!topoData) {
    return null;
  }

  return (
    <>
      <path d={pathFunc((topoData as any).features[1])} className="sea" />
      <path d={pathFunc((topoData as any).features[0])} className="border" />
    </>
  );
};

export const Sea = React.memo(SeaInner);
