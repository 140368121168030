import React from "react";
import { TimelineListItem } from "../TimelineListItem";
import { Anchor } from "./Anchor";

interface Props {
  onHover?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  offHover?: () => void;
}

export const Year1942TimelineJan: React.FunctionComponent<Props> = ({
  onHover = () => {},
  offHover = () => {},
}) => {
  return (
    <div className="timeline timeline-januari">
      <div className="center">1942 January</div>

      <div className="relative">
        <div
          className="timeline-date"
          style={{
            top: "-3px",
          }}
        >
          Jan. 01
        </div>

        <div
          className="timeline-date"
          style={{
            top: "67px",
          }}
        >
          Jan. 08
        </div>

        <div
          className="timeline-date"
          style={{
            top: "137px",
          }}
        >
          Jan. 15
        </div>

        <div
          className="timeline-date"
          style={{
            top: "207px",
          }}
        >
          Jan. 22
        </div>

        <div
          className="timeline-date"
          style={{
            top: "277px",
          }}
        >
          Jan. 29
        </div>
      </div>
      <div className="year-1942-timeline-wrapper">
        <svg viewBox="0 0 90 330">
          <linearGradient
            id="vertical-timeline"
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            <stop offset="0%" stopColor="#fafafa" stopOpacity="0" />
            <stop offset="45%" stopColor="#fafafa" stopOpacity="1" />
            <stop offset="55%" stopColor="#fafafa" stopOpacity="1" />
            <stop offset="100%" stopColor="#fafafa" stopOpacity="0" />
          </linearGradient>

          <line x1="54.5" x2="54.5" y1="-10" y2="330" stroke="#999" />

          {/** Bataan */}
          <Anchor timelineY={70} anchorY={14} />

          {/** Celebes */}
          <Anchor timelineY={110} anchorY={40} />

          {/** New Guniea */}
          <Anchor timelineY={230} anchorY={66} />

          <rect x="53" width="3" y="-20" height="40" />
          <rect x="53" width="3" y="310" height="40" />
        </svg>
        <div className="timeline-links">
          <ul>
            <TimelineListItem
              name="Japan attacks Bataan"
              number="10"
              id="bataan"
              onHover={onHover}
              offHover={offHover}
              link="https://en.wikipedia.org/wiki/Battle_of_Bataan"
            />
            <TimelineListItem
              name="Invasion of Dutch East Indies"
              id="celebes"
              onHover={onHover}
              offHover={offHover}
              number="11"
              link="https://en.wikipedia.org/wiki/Battle_of_Manado"
            />
            <TimelineListItem
              name="New Guinea campaign"
              id="rabaul"
              onHover={onHover}
              offHover={offHover}
              number="12"
              link="https://en.wikipedia.org/wiki/New_Guinea_campaign"
            />
          </ul>
        </div>
      </div>
    </div>
  );
};
