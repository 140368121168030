import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import cx from "classnames";
import { useWidth } from "../../hooks/useWidth";
import {
  Additional,
  AreasOfInterest,
  InterestType,
  PointOfInterest,
} from "../../data/dataMaps";
import { interpolatePath } from "d3-interpolate-path";
import { Sea } from "./Sea";
import "./Map.scss";
import { Additional as AdditionalComponent } from "../../components/Additional/Additional";
import { MapTooltip } from "../../components/Map/MapTooltip";
import { MapInfoToggler } from "../../components/Map/MapInfoToggler";

interface Props {
  year: string;
  land: any;
  imageUrl: string;
  territories: any;
  additional?: Additional[];
  highlights?: {
    [key: string]: PointOfInterest | AreasOfInterest;
  };
  isActive: null | PointOfInterest | AreasOfInterest;
  scale?: number;
}

interface Area {
  w: number;
  h: number;
}

interface Paths {
  [key: string]: string;
}

interface State {
  area: Area;
  projection: any;
  pathFunc: any;
  rasterOffsets: number[];
}

const mapCache = {
  topoData: undefined,
  meshData: undefined,
};

function getTopoData(land: any) {
  let topoData = mapCache.topoData;
  if (!topoData) {
    topoData = window.topojson.feature(land, land.objects.geo);
    // topoData = JSON.parse(land);
    mapCache.topoData = topoData;
  }
  return topoData;
}

function getBounds(state: any, path: any, w: any, h: any) {
  const b = path.bounds(state);
  const s = 1 / Math.max((b[1][0] - b[0][0]) / w, (b[1][1] - b[0][1]) / h);
  const t = [
    (w - s * (b[1][0] + b[0][0])) / 2,
    (h - s * (b[1][1] + b[0][1])) / 2,
  ];
  const rasterWidth = (b[1][0] - b[0][0]) * s;
  const rasterHeight = (b[1][1] - b[0][1]) * s;
  const rtranslateX = (w - rasterWidth) / 2;
  const rtranslateY = (h - rasterHeight) / 2;
  const raster = [rasterWidth, rasterHeight, rtranslateX, rtranslateY];
  return { s, t, raster };
}

function getArea(plot: MutableRefObject<HTMLDivElement | null>) {
  if (!plot.current) {
    return {
      w: 0,
      h: 0,
    };
  }
  const box = plot.current.getBoundingClientRect();
  const w = box.width;
  const h = box.height;
  return {
    w,
    h,
  };
}

function getPath(current: string, update: string) {
  const firstCurrentElem = current.split(",")[0];
  const firstUpdateElem = update.split(",")[0];

  if (firstCurrentElem === firstUpdateElem) {
    return update;
  }

  const coords = firstCurrentElem.substring(1);
  const index = update.indexOf(coords);
  if (index < 0) {
    return update;
  }

  const comparable = `M${update.substring(
    index,
    update.length - 1
  )}L${update.substring(1, index - 1)}Z`;

  return comparable === current ? current : update;
}

export const Map: React.FunctionComponent<Props> = ({
  land,
  // rivers,
  territories,
  imageUrl,
  additional = [],
  highlights = {},
  isActive,
  scale = 1,
  year,
}) => {
  const plot = useRef<HTMLDivElement>(null);
  const width = useWidth();
  // const riversData = window.topojson.feature(rivers, rivers.objects.geo);
  const topoData = getTopoData(land);
  const [paths, setPaths] = useState<Paths>({});
  const [isAnimating, setIsAnimating] = useState<boolean>(false);
  const enter = useRef<Paths>({});
  const leave = useRef<Paths>({});
  const update = useRef<Paths>({});

  useEffect(() => {
    setIsAnimating(true);
  }, [year]);

  const territoriesData = useMemo(() => {
    return territories
      ? window.topojson.feature(territories, territories.objects.geo)
      : { features: [] };
  }, [territories]);

  const [projections, setProjections] = useState<State>({
    area: { w: 0, h: 0 },
    projection: undefined,
    pathFunc: undefined,
    rasterOffsets: [0, 0, 0, 0],
  });

  useEffect(() => {
    const area = getArea(plot);
    const topo = window.topojson.feature(land, land.objects.geo);
    const projection = window.d3.geoMercator().scale(1).translate([0, 0]);
    const pathFunc = window.d3.geoPath().projection(projection);
    const { s, t, raster } = getBounds(topo, pathFunc, area.w, area.h);
    projection.scale(s).translate(t);
    setProjections({
      area,
      pathFunc,
      projection: projection,
      rasterOffsets: raster,
    });
  }, [width, land]);

  const activeMap: { [key: string]: boolean } = {};
  if (!isAnimating) {
    if (isActive) {
      if (isActive.type === InterestType.ALL) {
        highlights.all.mapAreas.forEach((id) => {
          activeMap[id] = true;
        });
      } else {
        isActive.mapAreas.forEach((id) => {
          activeMap[id] = true;
        });
      }
    }
  }

  const onTransitionEnd = useCallback(
    (ev: TransitionEvent) => {
      if (!(ev.target as HTMLElement).classList.contains("map-canvas")) {
        return;
      }

      if (ev.propertyName !== "transform") {
        return;
      }

      setIsAnimating(false);

      const interpolateAreas: { [key: string]: (t: number) => string } = {};
      const pathsDelta: Paths = {
        ...leave.current,
        ...enter.current,
        ...update.current,
      };

      territoriesData.features.forEach((item: any) => {
        const key = item.properties.id;
        if (update.current[key]) {
          const path = getPath(update.current[key], projections.pathFunc(item));
          if (path !== pathsDelta[key]) {
            interpolateAreas[key] = interpolatePath(update.current[key], path);
          }
        }
      });

      window.d3
        .selection()
        .transition()
        .duration(1000)
        .tween("animate-path", () => {
          return (t: number) => {
            Object.keys(interpolateAreas).forEach((key) => {
              pathsDelta[key] = interpolateAreas[key](t);
            });
            setPaths({ ...pathsDelta });
          };
        })
        .on("end", () => {
          const pathsEnd: Paths = {
            ...enter.current,
          };
          Object.keys(update.current).forEach((key) => {
            pathsEnd[key] = interpolateAreas[key]
              ? interpolateAreas[key](1)
              : pathsDelta[key];
          });
          enter.current = {};
          setPaths(pathsEnd);
        });
    },
    [territoriesData, projections] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (!plot.current) {
      return;
    }
    const ref = plot.current;
    ref.addEventListener("transitionend", onTransitionEnd);

    return () => {
      ref.removeEventListener("transitionend", onTransitionEnd);
    };
  }, [onTransitionEnd]);

  useEffect(() => {
    if (!projections.pathFunc) {
      setIsAnimating(false);
      return;
    }

    const isMount = !Object.keys(paths).length;
    if (isMount) {
      const pathsDelta: { [key: string]: string } = {};
      territoriesData.features.forEach((item: any) => {
        pathsDelta[item.properties.id] = projections.pathFunc(item);
      });
      setPaths(pathsDelta);
      setIsAnimating(false);
      return;
    }

    let enterSet: Paths = {};
    let leaveSet: Paths = {};
    let updateSet: Paths = {};
    const activeKeys: { [key: string]: boolean } = {};

    territoriesData.features.forEach((item: any) => {
      if (paths[item.properties.id]) {
        activeKeys[item.properties.id] = true;
        updateSet[item.properties.id] = paths[item.properties.id];
      } else {
        enterSet[item.properties.id] = projections.pathFunc(item);
      }
    });

    Object.keys(paths).forEach((key: string) => {
      if (!activeKeys[key]) {
        leaveSet[key] = paths[key];
      }
    });

    enter.current = enterSet;
    leave.current = leaveSet;
    update.current = updateSet;
  }, [territoriesData, projections]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="supernova-in-the-east-map">
      <MapInfoToggler highlights={highlights} />

      <div
        className={cx("map", `year-${year}`, {
          "is-active": isActive && isActive.type === InterestType.ALL,
          "has-item-hovered": isActive && isActive.type !== InterestType.ALL,
          "is-animating": isAnimating,
        })}
        ref={plot}
      >
        <img
          src={imageUrl}
          style={{
            width: `${projections.rasterOffsets[0]}px`,
            height: `${projections.rasterOffsets[1]}px`,
            left: `${projections.rasterOffsets[2]}px`,
            top: `${projections.rasterOffsets[3]}px`,
          }}
          alt="Shaded releif map of Imperial Japan 1870 - 1945"
        />

        <div className="map-highlights">
          {highlights &&
            Object.values(highlights).map((item) => (
              <MapTooltip
                scale={scale}
                isActive={isActive}
                key={item.id}
                pointOfInterest={item}
                projection={projections.projection}
              />
            ))}
        </div>

        <svg
          viewBox={`0 0 ${projections.area.w} ${projections.area.h}`}
          className="map-canvas"
        >
          <defs>
            <pattern
              id="pattern_japanese_expansion"
              width="2"
              height="2"
              patternTransform="rotate(45 0 0)"
              patternUnits="userSpaceOnUse"
            >
              <rect x="0" y="0" width="2" height="2" />
              <line x1="0" y1="0" x2="2" y2="0"></line>
            </pattern>

            <pattern
              id="pattern_japanese_ally"
              width="4"
              height="4"
              patternUnits="userSpaceOnUse"
            >
              <line
                x1="0"
                y1="2"
                x2="4"
                y2="2"
                stroke="#cd1f2c"
                strokeWidth="1"
              />
            </pattern>

            <pattern
              viewBox="0 0 100 100"
              id="pattern_japan"
              width="50%"
              height="50%"
              patternUnits="userSpaceOnUse"
            >
              <g transform="translate(-35, 6)">
                <circle cx="50" cy="50" r="4" />
                <path d="M50 50 L55 0 L45 0 L55 100 L45 100 Z" />
                <path d="M50 50 L77 0 L65 0 L35 100 L23 100 Z" />
                <path d="M50 50 L111 0 L91 0 L9 100 L-11 100 Z" />
                <path d="M50 50 L100 23 L100 35 L0 65 L0 77 Z" />
                <path d="M50 50 L100 45 L100 55 L0 45 L0 55 Z" />
                <path d="M50 50 L100 65 L100 77 L0 23 L0 35 Z" />
                <path d="M50 50 L100 111 L100 91 L0 9 L0 -11 Z" />
                <path d="M50 50 L23 0 L35 0 L65 100 L77 100 Z" />
              </g>
            </pattern>

            <radialGradient id="sea-bg">
              <stop offset="10%" stopColor="#e1eeff" />
              <stop offset="75%" stopColor="#fff" />
            </radialGradient>
          </defs>

          {Object.keys(paths).map((key: string) => {
            const path = paths[key];
            return (
              <path
                key={key}
                className={cx("territory", key, {
                  "is-active": activeMap[key],
                  "is-entering": enter.current[key],
                  "is-leaving": leave.current[key],
                })}
                d={path}
              />
            );
          })}

          <Sea topoData={topoData} pathFunc={projections.pathFunc} />

          {additional.map((item, idx) => (
            <AdditionalComponent
              key={`additional-${idx}`}
              additional={item}
              projection={projections.projection}
            />
          ))}
        </svg>
      </div>
    </div>
  );
};
