import React from "react";
import { Link } from "react-router-dom";
import { Image } from "../../components/Images/Image";
import { Page } from "../Page";
import { ROUTE as HomeRoute } from "../../pages/Home";
import "./Acknowledgements.scss";

export const ROUTE = "/acknowledgements";

export function Acknowledgements() {
  return (
    <Page title="Acknowledgements" className="acknowledgements">
      <section>
        <article>
          <div className="page-breadcrumbs">
            <Link to={HomeRoute}>Home</Link>
            <span>&rsaquo;</span>
            Acknowledgements
          </div>

          <h1>Acknowledgements</h1>

          <div className="quoute">
            <figure>
              <blockquote>
                &ldquo;No battle plan ever survives contact with the
                enemy&rdquo;
              </blockquote>
              <figcaption>
                <cite>- Helmuth von Moltke the Elder</cite>
              </figcaption>
            </figure>
          </div>

          <div className="flex">
            <div className="acknowledgements-preinfo">
              <p>
                I've listed the tools used when building this site. They are
                free to use for everyone. Most of the time I take it for granted
                but the generosity, innovation and cooperation of the open
                source community is worth acknowleding ❤️
                <br />
                Some of the tools/frameworks listed are backed by large
                corporations, but that doesn't reduce the value they provide
                <sup>*</sup>.
              </p>
              <ul className="footnote">
                <li>
                  <sup>*</sup> I disagree with e.g.{" "}
                  <a
                    href="https://clarity.kleydints.com/a-post-mortem-in-5-acts-of-how-microsoft-privatized-open-source-killing-javascript-in-the-process-62ee5fc77d9e"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Alex Kleydints
                  </a>{" "}
                  but admit it's an observation to keep in mind moving forward.
                </li>
              </ul>
            </div>
            <div className="acknowledgements-tools">
              <div>
                <h5>Frameworks / Tools</h5>
                <ul>
                  <li>
                    <a
                      href="https://mozilla.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="logo-wrapper">
                        <Image
                          src="/logos/zilla.svg"
                          alt="Mozilla logo"
                          ratio={1}
                        />
                      </div>
                      Mozilla
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://d3js.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="logo-wrapper">
                        <Image src="/logos/d3.svg" alt="D3.js logo" ratio={1} />
                      </div>
                      D3
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://reactjs.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="logo-wrapper">
                        <Image
                          src="/logos/react.svg"
                          alt="React logo"
                          ratio={1}
                        />
                      </div>
                      React
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://nodejs.org/en/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="logo-wrapper">
                        <Image
                          src="/logos/nodejs.svg"
                          alt="Node JS logo"
                          ratio={256 / 289}
                        />
                      </div>
                      Node JS
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.typescriptlang.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="logo-wrapper">
                        <Image
                          src="/logos/typescript.svg"
                          alt="Typescript logo"
                          ratio={1}
                        />
                      </div>
                      Typescript
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://webpack.js.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="logo-wrapper">
                        <Image
                          src="/logos/webpack.svg"
                          alt="Webpack logo"
                          ratio={1}
                        />
                      </div>
                      Webpack
                    </a>
                  </li>
                  <li className="babel">
                    <a
                      href="https://babeljs.io/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="logo-wrapper">
                        <Image
                          src="/logos/babel.svg"
                          alt="Babel logo"
                          ratio={1638 / 744}
                        />
                      </div>
                      Babel
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://eslint.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="logo-wrapper">
                        <Image
                          src="/logos/eslint.svg"
                          alt="ESLint logo"
                          ratio={1}
                        />
                      </div>
                      ESLint
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://prettier.io/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="logo-wrapper">
                        <Image
                          src="/logos/prettier.svg"
                          alt="Prettier logo"
                          ratio={1}
                        />
                      </div>
                      Prettier
                    </a>
                  </li>
                  <li className="sass">
                    <a
                      href="https://sass-lang.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="logo-wrapper">
                        <Image
                          src="/logos/sass.svg"
                          alt="Sass logo"
                          ratio={512 / 384}
                        />
                      </div>
                      Sass
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h5>Hosting / Repositories</h5>
                <ul>
                  <li>
                    <a
                      href="https://www.cloudflare.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="logo-wrapper cloudflare">
                        <Image
                          src="/logos/cloudflare.svg"
                          alt="Cloudflare logo"
                          ratio={256 / 116}
                        />
                      </div>
                      Cloudflare
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.netlify.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="logo-wrapper">
                        <Image
                          src="/logos/netlify.svg"
                          alt="Netlify logo"
                          ratio={1}
                        />
                      </div>
                      Netlify
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a
                      href="https://github.com/Github"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="logo-wrapper">
                        <Image
                          src="/logos/github.svg"
                          alt="Github logo"
                          ratio={1}
                        />
                      </div>
                      Github
                    </a>
                  </li>
                </ul>

                <h5>Editors</h5>
                <ul className="editors">
                  <li>
                    <a
                      href="https://code.visualstudio.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="logo-wrapper">
                        <Image
                          src="/logos/visual_studio_code.svg"
                          alt="Visual Studio Code logo"
                          ratio={1}
                        />
                      </div>
                      Visual Studio Code
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.qgis.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="logo-wrapper">
                        <Image
                          src="/logos/qgis.svg"
                          alt="QGIS logo"
                          ratio={1}
                        />
                      </div>
                      QGIS
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </article>
      </section>
    </Page>
  );
}
