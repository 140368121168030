import React, { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "../../components/Buttons/Button";
import { ROUTE } from "./Quiz";
import { v4 as uid } from "uuid";
import {
  Choice,
  ChoiceType,
} from "../../components/Interactions/Choice/Choice";
import { Level, NO_FILTER } from "../../components/Quiz/Question";
import { Questions } from "../../components/Quiz/Questions";

interface Props {
  setQuestions: (questions: Questions) => void;
}

export const QuizIntro: React.FunctionComponent<Props> = ({ setQuestions }) => {
  const history = useHistory();

  const [value, setValue] = useState<Level[]>([
    Level.EASY,
    Level.MEDIUM,
    Level.HARD,
  ]);

  const options = useMemo(() => {
    return [
      {
        value: Level.EASY,
        label: (
          <>
            <span>🙂</span>Easy
          </>
        ),
      },
      {
        value: Level.MEDIUM,
        label: (
          <>
            <span>🧐</span>Intermediary
          </>
        ),
      },
      {
        value: Level.HARD,
        label: (
          <>
            <span>🤯</span>Hard
          </>
        ),
      },
    ];
  }, []);

  const onChange = useCallback(
    (ev) => {
      if (value.includes(ev.value)) {
        const values = value.filter((item) => item !== ev.value);
        setValue(values);
      } else {
        setValue([...value, ev.value]);
      }
    },
    [value]
  );

  const onStart = useCallback(() => {
    const filter = { ...NO_FILTER, levels: value };
    const questions = new Questions(filter);
    setQuestions(questions);

    history.push(`${ROUTE}/1`);
  }, [history, setQuestions, value]);

  return (
    <div className="quiz-intro">
      <h1>Quiz</h1>
      <p>A quiz of the Ancient / Hellenistic / Roman era.</p>
      <div className="relative">
        <Choice
          options={options}
          id={uid()}
          type={ChoiceType.CHECKBOX}
          label="Include questions that are..."
          onChange={onChange}
          value={value}
        />
      </div>
      <Button block onClick={onStart}>
        {value.length === 0 ? (
          <span data-id="nok" className="disabled">
            Select questions
          </span>
        ) : (
          <span data-id="ok">Begin</span>
        )}
      </Button>
    </div>
  );
};
