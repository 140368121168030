import React from "react";
import { Image } from "../../../components/Images/Image";
import { BodyLink } from "../../HundredYearsWar/years/BodyLink";
import "../../../components/Overlay/Battles.scss";

export const Scipio: React.FunctionComponent = () => (
  <div className="reigns-trasimene battle-overlay">
    <Image
      src="/images/punic/scipio_795x450.jpg"
      ratio={795 / 450}
      alt="Scipio Africanus Freeing Massiva"
    />
    <div className="battle-image-attributes">
      <i>Scipio Africanus Freeing Massiva</i>
      <span>Giovanni Battista Tiepolo</span>
    </div>

    <div className="battle-body">
      <h5>
        <BodyLink link="https://en.wikipedia.org/wiki/Scipio_Africanus">
          Scipio Africanus
        </BodyLink>
      </h5>
      <i className="battle-date">236-235 BCE - 183 BCE (age 52 - 53)</i>
      <p>
        Scipio came from a powerful{" "}
        <BodyLink link="https://en.wikipedia.org/wiki/Patrician_(ancient_Rome)">
          patrician
        </BodyLink>{" "}
        Roman family. At the start of the Second Punic War he joined the army
        serving under his father{" "}
        <BodyLink link="https://en.wikipedia.org/wiki/Publius_Cornelius_Scipio_(consul_218_BC)">
          Publius Cornelius
        </BodyLink>
        . At the{" "}
        <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_Ticinus">
          battle of Ticinus
        </BodyLink>{" "}
        a 16-year-old Scipio reportedly saved his fathers life. He was present
        at the disaster at{" "}
        <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_Cannae">
          Cannae
        </BodyLink>
        .
      </p>
      <p>
        Only 25 years old at the time, Scipio was appointed{" "}
        <BodyLink link="https://en.wikipedia.org/wiki/Proconsul">
          proconsul
        </BodyLink>{" "}
        of the Iberian peninsula. As a commander he proved himself immediately.
        He defeated the great{" "}
        <BodyLink link="https://en.wikipedia.org/wiki/Hannibal">
          Hannibal
        </BodyLink>{" "}
        at{" "}
        <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_Zama">
          Zama
        </BodyLink>{" "}
        which ended the Second Punic War. He would later join his younger{" "}
        <BodyLink link="https://en.wikipedia.org/wiki/Lucius_Cornelius_Scipio_Asiaticus">
          brother
        </BodyLink>{" "}
        in the war against{" "}
        <BodyLink link="https://en.wikipedia.org/wiki/Antiochus_III_the_Great">
          Antiochus III the Great
        </BodyLink>{" "}
        in 190 BCE.
      </p>
      <p>
        Immensely popular with the people, he was, however, attacked and taken
        to court by members of the senate, most notably{" "}
        <BodyLink link="https://en.wikipedia.org/wiki/Cato_the_Elder">
          Cato the Elder
        </BodyLink>
        . Although the charges was eventually dropped, he felt betrayed. Scipio
        left Rome and spent the remainder of his life in Campania.
      </p>

      <figure>
        <blockquote>
          &ldquo;Ungrateful fatherland, you will not even have my bones&rdquo;
        </blockquote>
        <figcaption>
          <cite>- Alleged inscription on Scipio's tomb</cite>
        </figcaption>
      </figure>

      <hr />

      <p>
        Scipio never lost a battle. By some{" "}
        <BodyLink link="https://en.wikipedia.org/wiki/B._H._Liddell_Hart">
          historians
        </BodyLink>{" "}
        he his regarded as one of the finest generals of all time. The siege of{" "}
        <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_Cartagena_(209_BC)">
          Carthago Nova
        </BodyLink>
        , the uphill assault at{" "}
        <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_Baecula">
          Baecula
        </BodyLink>{" "}
        and the battle at{" "}
        <BodyLink link="https://en.wikipedia.org/wiki/Battle_of_Ilipa">
          Ilipa
        </BodyLink>
        , all required different tactics. Scipio proved he mastered them all.
      </p>

      <p>
        Scipio was always faithful to the Republic, but the first indications of
        an army more loyal to their general than the Republic, and later, the
        Empire, could be noticed. Down the road, this would be common praxis.
      </p>

      <p>
        Scipio openly admired Greek culture and philopsophy. Parts of the
        senate, however, felt that Greek influence corrupted Roman youth. As a
        politician, therefore, he sometimes met strong opposition among the
        conservatives.
      </p>
    </div>
  </div>
);
