import React, { ReactNode } from "react";
import { IconLink } from "./IconLink";

interface Props {
  node: ReactNode;
  children: ReactNode;
}

export const InfoLink: React.FunctionComponent<Props> = ({
  node,
  children,
}) => (
  <IconLink
    node={node}
    icon={
      <i>
        <b>i</b>
      </i>
    }
  >
    {children}
  </IconLink>
);
