import React from "react";
import { Additional as AdditionalInterface } from "../../data/dataMaps";

interface Props {
  additional?: AdditionalInterface;
  projection?: any;
}

export const Additional: React.FunctionComponent<Props> = React.memo(
  ({ additional, projection }) => {
    if (!projection) {
      return null;
    }

    if (!additional) {
      return null;
    }

    let item = "M0 0";
    let points: string[] = [];

    additional.coords.forEach((additionalCoords, idx) => {
      const coords = projection([additionalCoords[1], additionalCoords[0]]);
      const [x, y] = coords;
      if (idx === 0) {
        points.push(`M${x} ${y}`);
      } else {
        points.push(`L${x} ${y}`);
      }
    });
    item = points.join(" ");

    return <path d={item} className={additional.className} />;
  }
);
