import React, { ReactNode, useCallback, useEffect, useState } from "react";
import cx from "classnames";
import { Action } from "../Buttons/Action";
import { Slider } from "../Interactions/Slider/Slider";
import { ChevronLeft } from "../Icons/ChevronLeft";
import { ChevronRight } from "../Icons/ChevronRight";
import { SimpleStory, Story } from "../../data/dataMaps";
import { useHistory } from "react-router-dom";
import "./YearSlider.scss";
import {
  getDynamicLink,
  getFormattedTextFromYear,
  getUrlFromYear,
} from "../../data/routeUtils";

interface Props {
  className?: string | ReactNode;
  year?: string;
  story: Story | SimpleStory;
  route: string;
}

export const YearSlider: React.FunctionComponent<Props> = ({
  className,
  story,
  year,
  route,
}) => {
  const [values, setValues] = useState<{
    min: number;
    max: number;
    index: number;
  }>({
    min: 0,
    max: 0,
    index: 0,
  });

  const history = useHistory();

  const go = useCallback(
    (index) => {
      const yearItem = Object.values(story.years)[index];
      const dynamicYear = getUrlFromYear(yearItem.year);
      if (!dynamicYear) {
        return;
      }

      const url = getDynamicLink(route, {
        year: dynamicYear,
      });
      history.push(url);
    },
    [story, history, route]
  );

  const onDecrement = useCallback(() => {
    go(values.index - 1);
  }, [values.index, go]);

  const onIncrement = useCallback(() => {
    go(values.index + 1);
  }, [values.index, go]);

  const onChange = useCallback(
    (index) => {
      go(index);
    },
    [go]
  );

  useEffect(() => {
    const index = Math.max(
      0,
      Object.keys(story.years).findIndex((item) => item === year)
    );
    const max = Object.keys(story.years).length - 1;
    setValues({
      min: 0,
      max,
      index,
    });
  }, [year, story]);

  const indicators = [];
  for (let i = values.min; i <= values.max; i++) {
    indicators.push(
      <li
        key={`indicator-${i}`}
        className={i === values.index ? "active" : ""}
      />
    );
  }

  return (
    <div className={cx("year-slider-wrapper", className)}>
      <div className="year-slider-header">
        Year <b>{getFormattedTextFromYear(story.years[year || ""]?.year)}</b>
      </div>
      <div className="year-slider">
        <Action
          className="on-decrement"
          onClick={onDecrement}
          attrs={{
            "aria-label": "Show map of previous year",
          }}
        >
          {!values.index ? (
            <div className="disabled" data-id="year-slider-disabled">
              <ChevronLeft />
            </div>
          ) : (
            <div data-id="year-slider-decrement">
              <ChevronLeft />
            </div>
          )}
        </Action>

        <div className="year-slider-inner">
          <Slider
            onChange={onChange}
            value={values.index}
            min={values.min}
            max={values.max}
          />
        </div>

        <Action
          className="on-increment"
          onClick={onIncrement}
          attrs={{
            "aria-label": "Show map of next year",
          }}
        >
          {values.index === values.max ? (
            <div className="disabled" data-id="year-slider-disabled">
              <ChevronRight />
            </div>
          ) : (
            <div data-id="year-slider-increment">
              <ChevronRight />
            </div>
          )}
        </Action>
      </div>

      <ul className="year-slider-indicator">{indicators}</ul>
    </div>
  );
};
