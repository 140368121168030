import React from "react";

interface Props {
  retry: () => void;
}

export function Error({ retry }: Props) {
  return (
    <div className="lazy-error">
      <p>
        <b>Uugh!</b> Something went wrong.
      </p>
      <button onClick={retry}>Try again</button>
    </div>
  );
}
